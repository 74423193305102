import styled from '@emotion/styled';
import { palette, sizes, bp, layer } from 'entity/createTheme';

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
  height: ${sizes.headerHeightLg};
  background-color: ${palette.whitePrimary};

  @media ${bp.md} {
    padding: 0 16px;
  }

  .toggle-sidebar {
    display: none;
  }

  .header_info {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  @media ${bp.sm} {
    height: ${sizes.headerHeightSm};
    .toggle-sidebar {
      display: inline-block;
      padding-right: 32px;
    }
  }
`;

export const UserInfoWrapper = styled('div')`
  display: flex;
  padding: 10px;
  min-width: 185px;
  align-items: center;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid ${palette.whitePrimary};
  border-bottom: none;

  & > svg {
    transform: rotate(0);
    transition: transform 0.3s ease;
  }

  .user-avatar {
    width: 32px;
    height: 32ppx;
    border-radius: 50%;
    margin-right: 15px;
  }

  .user-name,
  .organization-name {
    font-weight: bold;
    margin-right: 5px;
  }

  .dropdown-menu {
    position: absolute;
    width: calc(100% + 2px);
    display: none;
    color: ${palette.darkMedium1};
    top: 100%;
    left: -1px;
    padding: 10px 5px 5px 10px;
    z-index: ${layer.bottomAbove1};
    border: 1px solid ${palette.grayPrimary};
    border-radius: 0 0 6px 6px;
    background-color: ${palette.whitePrimary};
    max-height: 80vh;
    overflow-y: auto;

    &__item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      text-decoration: none;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }
  }

  &.open {
    border-radius: 6px 6px 0 0;
    border-color: ${palette.grayPrimary};
    .dropdown-menu {
      display: block;
    }

    & > svg {
      transform: rotate(180deg);
    }
  }
`;
