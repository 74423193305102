export const defaultAppointment = {
  id: 0,
  company_name: '',
  company_pic_name: '',
  updated_at: '',
  datetime: '',
  memo: '',
  hearing_sets: [],
  product_videos: [],
  activeHearingIndex: 0,
};

export const defaultAppointmentMeta = {
  currentPage: 1,
  total: undefined,
};
