import React, { FC, MouseEvent } from 'react';
import { css } from '@emotion/core';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import DownloadButtonURL from 'assets/images/download_button.svg';
import DownloadDoneURL from 'assets/images/download_done.svg';

const DownloadStatus = {
  Hidden: 'hidden',
  Ready: 'ready',
  Downloading: 'downloading',
  Downloaded: 'downloaded',
} as const;
export type DownloadStatus = typeof DownloadStatus[keyof typeof DownloadStatus];

interface PropsTypes {
  status: DownloadStatus;
  progress: number;
  onClick: () => void;
  top?: string;
  right?: string;
  whiteBackground?: boolean;
}

const DownloadButton: FC<PropsTypes> = ({
  progress,
  onClick,
  status,
  top,
  right,
  whiteBackground,
}) => {
  function handleOnClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (status === 'ready') onClick();
  }

  function progressString(progress: number) {
    return Math.round(progress * 100).toString() + '%';
  }

  const style = css`
    width: 32px;
    height: 32px;
    position: absolute;

    img {
      position: absolute;
      margin: auto;
      left: 10px;
      top: 4px;
      width: 12px;
      height: 13px;
    }

    img.download-button {
      cursor: pointer;
    }

    .CircularProgressbar .CircularProgressbar-trail {
      stroke: #000000;
      stroke-linecap: round;
      stroke-width: 0;
    }

    .CircularProgressbar .CircularProgressbar-path {
      stroke: ${whiteBackground ? '#ffffff' : '#ffffffbb'};
      stroke-linecap: round;
      transition: stroke-dashoffset 0s ease 0s;
    }

    .download-button-downloading .CircularProgressbar-path {
      stroke: #2dcc70;
    }

    .CircularProgressbar .CircularProgressbar-background {
      fill: ${whiteBackground ? '#00000033' : '#00000066'};
    }

    p {
      position: absolute;
      top: 5px;
      font-size: 10.5px !important;
      font-weight: 500 !important;
      color: white !important;
      dominant-baseline: middle;
      text-anchor: middle;
    }
  `;

  return (
    <div
      onClick={handleOnClick}
      css={style}
      style={{
        display: status === 'hidden' ? 'none' : 'block',
        top: top || '10px',
        right: right || '10px',
      }}
    >
      <CircularProgressbarWithChildren
        className={`download-button-${status}`}
        value={status === 'downloading' ? progress * 100 : 100}
        background
        strokeWidth={3}
        styles={buildStyles({})}
      >
        {status === 'ready' && (
          <img src={DownloadButtonURL} alt="download button" className="download-button" />
        )}
        {status === 'downloaded' && (
          <img src={DownloadDoneURL} alt="download done" className="download-done" />
        )}
        {status === 'downloading' && <p>{progressString(progress)}</p>}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default DownloadButton;
