interface SortOrder {
  id: number;
  label: string;
  sort: string;
  order: string;
}

export const sortOrders: SortOrder[] = [
  {
    id: 0,
    label: 'common.sortLatest',
    sort: 'desc',
    order: 'updated_at',
  },
  {
    id: 1,
    label: 'common.sortOldest',
    sort: 'asc',
    order: 'updated_at',
  },
  {
    id: 3,
    label: 'common.sortDESC',
    sort: 'desc',
    order: 'title',
  },
  {
    id: 4,
    label: 'common.sortASC',
    sort: 'asc',
    order: 'title',
  },
];
