import React, { FC } from 'react';
import SVGIcon from 'views/components/primitive/SVGIcon';
import { Button } from 'views/components/primitive';
import NextIcon from 'assets/images/ic_right.svg';
import BackIcon from 'assets/images/ic_left_active.svg';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  goNext(): void;
  goBack(): void;
  disableNext: boolean;
  disableBack: boolean;
  activeIndex?: number;
  total?: number;
  className?: string;
};

const NextBackButtons: FC<Props> = ({
  goNext,
  goBack,
  disableBack,
  disableNext,
  activeIndex,
  total,
  className,
}) => {
  return (
    <Root className={className}>
      <ArrowButton
        id="pdf-viewer__control-bar__back-button"
        label={<SVGIcon src={BackIcon} id="pdf-viewer__control-bar__back-button__icon" />}
        onClick={goBack}
        disabled={disableBack}
      />
      {activeIndex && total && (
        <Index id="pdf-viewer__control-bar__index">
          {activeIndex} / {total}
        </Index>
      )}
      <ArrowButton
        id="pdf-viewer__control-bar__next-button"
        label={<SVGIcon src={NextIcon} id="pdf-viewer__control-bar__next-button__icon" />}
        onClick={goNext}
        disabled={disableNext}
      />
    </Root>
  );
};

export default NextBackButtons;

const Root = styled('div')({
  display: 'flex',
});

const ArrowButton = styled(Button)({
  background: 'transparent',
  width: 'fit-content',
  '&:disabled': {
    background: 'transparent !important',
    opacity: '0.7',
  },
});

const Index = styled('span')({
  color: palette.whitePrimary,
  margin: '0 10px',
  fontSize: 14,
});
