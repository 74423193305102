import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import React, { FC } from 'react';

type Props = {
  className?: string;
};

const HeaderWrapper: FC<Props> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};

export default HeaderWrapper;

const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${bp.md} {
    margin-bottom: 16px;
  }

  .header-title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    overflow: hidden;
    h2 {
      font-size: 24px;
      color: ${palette.darkMedium3};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      white-space: nowrap;
      @media ${bp.md} {
        font-size: 22px;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
  .header-actions {
    display: flex;
    align-items: center;
    button:first-of-type {
      margin-right: 10px;
    }
  }
`;
