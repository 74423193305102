import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneWithFileBrowse, InputReplaceFile } from 'views/components/compound';
import styled from '@emotion/styled';
import { acceptImage, acceptImageMimeTypes } from 'utility/constants';
import { extractFileExtentionFromMimeType } from 'utility/helpers';
import { palette, bp, layer } from 'entity/createTheme';
import { UploadImageMeta, ContentMeta } from 'types/App';

type Props = {
  editable: boolean;
  meta: UploadImageMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
};

const UploadImage: FC<Props> = ({ editable, meta, handleChangeContentMeta }) => {
  const { t } = useTranslation();

  const handleChange = (newFile: any) => {
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (acceptImageMimeTypes.includes(extension)) {
      handleChangeContentMeta({
        ...meta,
        file: newFile,
        filename: newFile.name,
        src: URL.createObjectURL(newFile),
        error: '',
      });
    }
  };

  return (
    <Root>
      {meta.filename !== '' ? (
        <>
          <Partition />
          <SelectedFileWrapper>
            <ChangeUploadedContentInputWrapper>
              <img src={meta.src} alt="thumbnail_image" />
              {editable && (
                <InputReplaceFile
                  id="replace_image"
                  accept={acceptImage.join(', ')}
                  className="image-replace"
                  handleChange={handleChange}
                />
              )}
            </ChangeUploadedContentInputWrapper>
            <ImageInfo>
              <FileNameLabel>{t('admin.common.filename')}</FileNameLabel>
              <FileName>{meta.filename}</FileName>
            </ImageInfo>
          </SelectedFileWrapper>
        </>
      ) : (
        <DropzoneWrapper>
          <DropzoneWithFileBrowse name={meta.filename} onChange={handleChange} type="image" />
        </DropzoneWrapper>
      )}
      {meta.error !== '' && <Error className="error">{meta.error}</Error>}
    </Root>
  );
};

const Root = styled('div')({});

const DropzoneWrapper = styled('div')({
  marginTop: 15,
});

const ChangeUploadedContentInputWrapper = styled('div')({
  maxWidth: '40%',
  minWidth: 356,
  height: 200,
  marginRight: 20,
  borderRadius: 6,
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  background: palette.blackPrimary,

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    '&.not-ready': {
      objectFit: 'cover',
    },
  },

  '.image-replace': {
    position: 'absolute',
    top: '10px',
    right: '10px',
    // Might not need to specify z-index
    zIndex: layer.bottomAbove1,
  },
});

const ImageInfo = styled('div')({
  overflow: 'hidden',
});

const FileNameLabel = styled('div')({
  height: 18,
  color: palette.grayMedium4,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
});

const FileName = styled('div')({
  marginTop: 10,
  color: palette.darkMedium8,
  fontSize: 14,
  letterSpacing: 0,
  width: '100%',
  lineHeight: 1.5,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 8,
  wordBreak: 'break-all',
});

const SelectedFileWrapper = styled('div')({
  display: 'flex',
});

const Partition = styled('div')({
  margin: `15px 0`,
  width: '100%',
  height: 1,
  background: palette.grayPrimary,
});

const Error = styled('p')({
  color: palette.redPrimary,
  marginTop: 20,
  fontSize: 12,
});

export default UploadImage;
