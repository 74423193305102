import {
  UploadVideoMeta,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  NoContentMeta,
} from 'types/App';
import { ListMeta } from 'types/common';
import { Question } from 'types/Question';

const defaultVideoMeta: UploadVideoMeta = {
  type: 'video',
  url: '',
  file: '',
  fileThumbnail: '',
  fileThumbnailConst: '',
  videoSrcConvert: '',
  thumbnailSrcConvert: '',
  firstFrameVideo: '',
  filename: '',
  error: '',
  errorFile: '',
};

const defaultExtrenalVideoURLMeta: ExternalVideoURLMeta = {
  type: 'link',
  platform: 'vimeo',
  url: '',
  thumbnailFile: undefined,
  thumbnailSrc: '',
  thumbnailURL: '',
  error: '',
};

const defaultImageMeta: UploadImageMeta = {
  type: 'image',
  file: '',
  filename: '',
  src: '',
  error: '',
};

const defaultPDFMeta: UploadPDFMeta = {
  type: 'pdf',
  file: '',
  thumbnailFile: '',
  filename: '',
  thumbnailSrc: '',
  error: '',
};

const defaultNoContentMeta: NoContentMeta = {
  type: 'none_data',
};

const defaultQuestionDetail: Question = {
  id: 0,
  content: '',
  is_multiple_choice: true,
  is_required: true,
  data_file: {
    type_data: 'none_data',
  },
  answers: [
    {
      content: '',
      feedback: '',
      can_delete: true,
    },
  ],
  has_other: true,
};

const defaultListMeta: ListMeta = {
  sort: '',
  order: '',
  page: 1,
  total: undefined,
};

export {
  defaultVideoMeta,
  defaultExtrenalVideoURLMeta,
  defaultImageMeta,
  defaultPDFMeta,
  defaultNoContentMeta,
  defaultQuestionDetail,
  defaultListMeta,
};
