import React, { FC } from 'react';
import {
  UploadVideoMeta,
  UploadImageMeta,
  ExternalVideoURLMeta,
  UploadPDFMeta,
  ContentType,
  ContentMeta,
} from 'types/App';
import UploadVideo from './UploadVideo';
import UploadImage from './UploadImage';
import UploadPDF from './UploadPDF';
import NoContent from './NoContent';
import ExternalVideoURLInput from './ExternalVideoURLInput';

type Props = {
  editable: boolean;
  contentType: ContentType;
  questionVideoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  uploadStatus?: string;
};

const ContentUploader: FC<Props> = ({
  editable,
  contentType,
  questionVideoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentMeta,
  uploadStatus,
}) => {
  switch (contentType) {
    case 'video':
      return (
        <UploadVideo
          editable={editable}
          handleChangeMeta={handleChangeContentMeta}
          meta={questionVideoMeta}
          status={uploadStatus || ''}
        />
      );
    case 'link':
      return (
        <ExternalVideoURLInput
          meta={externalVideoURLMeta}
          handleChangeContentMeta={handleChangeContentMeta}
          editable={editable}
        />
      );
    case 'image':
      return (
        <UploadImage
          meta={imageMeta}
          handleChangeContentMeta={handleChangeContentMeta}
          editable={editable}
        />
      );
    case 'pdf':
      return (
        <UploadPDF
          meta={pdfMeta}
          handleChangeContentMeta={handleChangeContentMeta}
          editable={editable}
        />
      );
    case 'none_data':
      return <NoContent />;
    default:
      return null;
  }
};

export default ContentUploader;
