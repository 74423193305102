import { Question } from 'types/Question';
import { HearingItem } from 'types/HearingSet';
import { trimSpace } from 'utility/helpers';
import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';

class HearingAdminApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getAvailableQuestions = async () => {
    const res = await this.httpClient.request<{ questions: Question[] }>({
      method: 'GET',
      url: 'admin/hearing_sets/list_questions',
    });
    return res;
  };

  public getHearingById = async ({ id }: { id: string }) => {
    const res = await this.httpClient.request<HearingItem>({
      method: 'GET',
      url: `admin/hearing_sets/${id}`,
    });
    return res;
  };

  public addHearingSet = async ({
    title,
    status,
    questionIdList,
  }: {
    title: string;
    status: string | undefined;
    questionIdList: number[];
  }) => {
    const res = await this.httpClient.request<HearingItem>({
      method: 'POST',
      url: 'admin/hearing_sets',
      data: {
        title: trimSpace(title),
        status,
        question_ids: questionIdList || [],
      },
    });
    return res;
  };

  public updateHearingSet = async ({
    id,
    title,
    status,
    question_ids,
    remove_question_ids,
  }: {
    id: string;
    title: string;
    status?: string;
    question_ids: number[];
    remove_question_ids?: number[];
  }) => {
    const res = await this.httpClient.request({
      method: 'PUT',
      url: `admin/hearing_sets/${id}`,
      data: {
        status,
        title: trimSpace(title),
        question_ids: question_ids,
        ...(remove_question_ids && { remove_question_ids }),
      },
    });

    return res;
  };

  public getAdminHearingList = async ({ page, sort, order }: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{ hearing_sets: HearingItem[]; total?: number }>({
      method: 'GET',
      url: 'admin/hearing_sets',
      params: {
        page,
        sort,
        order,
        per_page: 8,
      },
    });
    return res;
  };

  public changeHearingStatus = async ({
    status,
    confirm,
    idList,
  }: {
    status?: string;
    confirm?: boolean;
    idList: number[];
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/hearing_sets/update_status',
      data: {
        ids: idList,
        status,
        confirm,
      },
    });
  };
}

export default HearingAdminApi;
