import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { bp, layer, sizes } from 'entity/createTheme';
import { Question } from 'types/Question';
import {
  ContentType,
  UploadVideoMeta,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  ContentMeta,
} from 'types/App';
import { ConfirmModal, LoadingIndicator, QuestionContentModal } from 'views/components/compound';
import { QuestionHeader, QuestionContent, UploadContent } from '../QuestionForm';

type Props = {
  questionDetail: Question;
  handleChangeDetail(data: object): void;
  resetMetaQuestion(): void;
  submitUpdatedQuestion(questionId: string): void;

  // content type and payload observables for upload new content
  contentType: ContentType;
  questionVideoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  disableSubmit: boolean;
  disableUpdate: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;

  isEditQuestionModalOpen: boolean;
  toggleEditQuestionModal: () => void;
  resetHearingSectionErrors(): void;
};

export const EditQuestionModal: FC<Props> = ({
  questionDetail,
  handleChangeDetail,
  resetMetaQuestion,
  submitUpdatedQuestion,
  contentType,
  questionVideoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
  disableSubmit,
  disableUpdate,
  submitting,
  setSubmitting,
  isEditQuestionModalOpen,
  toggleEditQuestionModal,
  resetHearingSectionErrors,
}) => {
  const questionId = useMemo(() => questionDetail.id, [questionDetail]);
  const [isModal, setIsModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { firstFrameVideo, file, error, fileThumbnail } = useMemo(() => questionVideoMeta, [
    questionVideoMeta,
  ]);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);

  useEffect(() => {
    if (!file) {
      setIsLoadingFrame(false);
      return;
    }
    if (error) {
      setIsLoadingFrame(false);
    } else {
      setIsLoadingFrame(true);
    }
  }, [file, error]);

  useEffect(() => {
    if (firstFrameVideo || fileThumbnail) {
      setIsLoadingFrame(false);
    }
  }, [firstFrameVideo, fileThumbnail]);

  useEffect(() => {
    return () => {
      resetMetaQuestion();
    };
  }, []);

  function toggleModal() {
    setIsModal(!isModal);
  }

  function handleCancelEdit() {
    toggleEditQuestionModal();
    resetMetaQuestion();
  }

  function handleSetError(hasError: boolean) {
    setHasError(hasError);
  }

  function handleUpdateQuesiton() {
    toggleEditQuestionModal();

    switch (contentType) {
      case 'video':
        if (questionVideoMeta.fileThumbnail || questionVideoMeta.firstFrameVideo) {
          submitUpdatedQuestion(questionId.toString());
          setHasError(false);
        }
        break;
      case 'link':
      case 'image':
      case 'pdf':
      case 'none_data':
        submitUpdatedQuestion(questionId.toString());
        setHasError(false);
        break;
      default:
    }
  }

  function handleConfirmModal() {
    handleCancelEdit();
  }

  return (
    <QuestionContentModal isOpen={isEditQuestionModalOpen}>
      {isLoadingFrame && (
        <LoadingFrame>
          <LoadingIndicator />
        </LoadingFrame>
      )}
      <QuestionHeader
        showDetail
        editable
        title={questionDetail.content}
        isDelete={!!questionDetail.can_delete}
        toggleModal={toggleModal}
        submitCallback={handleUpdateQuesiton}
        disableSubmit={disableSubmit}
        disableUpdate={disableUpdate}
        questionDetail={questionDetail}
        handleSetError={handleSetError}
        submitting={submitting}
        setSubmitting={setSubmitting}
        resetHearingSectionErrors={resetHearingSectionErrors}
      />
      <UploadContent
        editable
        questionDetail={questionDetail}
        contentType={contentType}
        questionVideoMeta={questionVideoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentType={handleChangeContentType}
        handleChangeContentMeta={handleChangeContentMeta}
      />
      <QuestionContent
        questionDetail={questionDetail}
        handleChangeDetail={handleChangeDetail}
        disableEdit={false}
        hasError={hasError}
      />
      <ConfirmModal
        isModal={isModal}
        isDelete={false}
        toggleModal={toggleModal}
        handleConfirmModal={handleConfirmModal}
      />
    </QuestionContentModal>
  );
};

const LoadingFrame = styled('div')({
  position: 'absolute',
  width: `calc(100% - ${sizes.sizebarWidth})`,
  height: '100%',
  left: sizes.sizebarWidth,
  top: 0,
  background: `rgba(0, 0, 0, 0.3)`,
  zIndex: layer.bottomAbove2,
  [`@media ${bp.sm}`]: {
    width: '100%',
    left: 0,
  },
});

export default EditQuestionModal;
