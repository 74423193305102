import React, { FC, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import SVGIcon from 'views/components/primitive/SVGIcon';
import IconReplace from 'assets/images/ic_reload.svg';

const InputFileWrapper = styled('label')`
  height: 43px;
  width: 43px;
  background: ${palette.whitePrimary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  input {
    display: none;
  }
`;

interface PropTypes {
  id: string;
  className?: string;
  handleChange: (file: any) => void;
  accept: string;
}

const InputReplaceFile: FC<PropTypes> = ({ className, handleChange, accept, id }) => {
  function handleChangeFile(e: ChangeEvent<HTMLInputElement>) {
    handleChange(!!e.target.files && e.target.files[0]);
  }

  return (
    <InputFileWrapper className={className} htmlFor={id}>
      <SVGIcon src={IconReplace} />
      <input accept={accept} onChange={handleChangeFile} id={id} type="file" />
    </InputFileWrapper>
  );
};

export default InputReplaceFile;
