import React, { FC, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'utility/constants';
import { FlashMessages } from 'views/components/compound';
const AuthenticatedApp = lazy(() => import('./views/Root'));
const InsideSalePlay = lazy(() => import('./views/connected/InsideSaleProcess'));

const App: FC = () => {
  return (
    <div className="container">
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path={`${routes.clientStartHearingLink}/:token`}
            component={InsideSalePlay}
          />
          <Route path="/" component={AuthenticatedApp} />
        </Switch>
      </Suspense>
      <FlashMessages />
    </div>
  );
};

export default App;
