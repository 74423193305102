import { observable, action } from 'mobx';
import i18n from 'i18n';
import history from 'utility/history';
import ProductAdminApi from 'services/Admin/ProductAdminApi';
import { ProductItem, ProductDetailMeta } from 'types/Product';
import { UploadCompletedProps } from 'types/common';
import { RootStore } from '../index';
import { MessageProps } from 'types/App';
import { dataURLtoFile, trimSpace } from 'utility/helpers';
import { ListMeta } from 'types/common';
import { routes } from 'utility/constants';

const defaultListMeta: ListMeta = {
  total: undefined,
  page: 1,
  sort: 'desc',
  order: 'updated_at',
};

export const defaultProductDetail: ProductDetailMeta = {
  uploadVideoMeta: {
    fileVideo: '',
    fileThumbnail: '',
    fileThumbnailConst: '',
    videoSrcConvert: '',
    firstFrameVideo: '',
    thumbnailSrcConvert: '',
    filename: '',
    error: '',
    errorFile: '',
  },
  title: '',
  description: '',
  status: 'draft',
  upload_status: '',
};

class ProductAdminStore {
  // Product List
  private readonly productAdminApi: ProductAdminApi;
  @observable public targetVideo: { id: number; status: string } = { id: 0, status: '' };
  @observable public hasHearingLink = false;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public errors = {};
  @observable public isSelectProduct = false;
  @observable public listProduct: ProductItem[] = [];
  @observable public productSelected: number[] = [];
  @observable public listMeta: ListMeta = { ...defaultListMeta };
  @observable public productDetailMeta: ProductDetailMeta = { ...defaultProductDetail };
  @observable public percentUploaded: UploadCompletedProps = { filename: '', completed: 0 };

  constructor({
    rootStore,
    productAdminApi,
  }: {
    rootStore: RootStore;
    productAdminApi: ProductAdminApi;
  }) {
    this.rootStore = rootStore;
    this.productAdminApi = productAdminApi;
  }

  @action.bound
  public changeTargetVideo = (id: number, status: string) => {
    this.targetVideo = { ...this.targetVideo, id, status };
  };

  // PUSH FLASH MESSAGES
  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectProduct() {
    if (this.isSelectProduct) {
      this.productSelected = [];
    }
    this.isSelectProduct = !this.isSelectProduct;
  }

  @action.bound
  public selectProductItem(id: number) {
    const productSelected = this.productSelected;
    if (productSelected.includes(id)) {
      this.productSelected = productSelected.filter(it => it !== id);
    } else {
      this.productSelected = [...productSelected, id];
    }
  }

  // GET LIST PRODUCT ADMIN
  @action.bound
  public async getListProductAdmin(page = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;

    try {
      const { data } = await this.productAdminApi.getAdminProductList({ page, sort, order });
      this.listProduct = data.product_videos;
      this.listMeta = {
        ...this.listMeta,
        sort,
        order,
        page,
        total: data.total,
      };
    } catch (error) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  // CHANGE STATUS OF PRODUCT ITEM
  @action.bound
  public async handleChangeStatus(id: number, status: string, confirm?: boolean) {
    try {
      await this.productAdminApi.changeProductStatus({ id, status, confirm });
      this.isLoading = true;
      this.listProduct = this.listProduct.map(it => (it.id === id ? { ...it, status } : { ...it }));
      this.pushFlashMessages({
        content: i18n.t('admin.productVideo.messages.changeStatusSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.errors = error;
      if (error.status === 403) this.hasHearingLink = true;
    } finally {
      this.isLoading = false;
      this.hasHearingLink = false;
    }
  }

  // CHANGE META PRODUCT DETAIL
  @action.bound
  public changeProductDetailMeta(data: object) {
    this.productDetailMeta = { ...this.productDetailMeta, ...data };
  }

  @action.bound
  public resetProductDetailMeta() {
    this.productDetailMeta = { ...defaultProductDetail };
    this.errors = {};
  }

  // GET PRODUCT DETAIL
  @action.bound
  public async getProductDetail(id: number) {
    try {
      this.isLoading = true;
      this.productDetailMeta = { ...defaultProductDetail };
      const { data: product } = await this.productAdminApi.getProductById({ id });
      this.productDetailMeta = {
        ...defaultProductDetail,
        uploadVideoMeta: {
          ...defaultProductDetail.uploadVideoMeta,
          filename: product.file_name,
          firstFrameVideo: product.thumbnail,
        },
        title: product.title,
        description: product.description,
        status: product.status,
        upload_status: product.upload_status,
      };
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.productVideo.messages.notFoundProduct'),
        status: 'error',
      });
      history.push(routes.admin.productVideo);
    } finally {
      this.isLoading = false;
    }
  }

  // ARCHIVE PRODUCT
  @action.bound
  public async archiveProductVideo(id?: number, isDetail?: boolean) {
    try {
      // Call Api archive product
      const listId: number[] = id ? [id] : this.productSelected.slice();
      this.isLoading = true;
      await this.productAdminApi.archiveProduct({ listId });
      this.listProduct = this.listProduct.filter(it => !listId.includes(it.id));
      // Clear data
      this.isSelectProduct = false;
      this.productSelected = [];
      if (!isDetail) {
        const { page, sort, order } = this.listMeta;
        await this.getListProductAdmin(page, sort, order);
      } else {
        history.push({ pathname: routes.admin.archive, search: '?panel=products' });
      }
      // ** //
      this.pushFlashMessages({
        content: i18n.t('admin.common.messages.archiveSuccess', {
          item: i18n.t('common.actionTarget.products'),
        }),
        status: 'success',
      });
    } catch (error) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async upLoadProductVideo() {
    try {
      const { uploadVideoMeta, title, description, status } = this.productDetailMeta;
      const thumbnail =
        typeof uploadVideoMeta.fileThumbnail === 'string'
          ? dataURLtoFile(uploadVideoMeta.fileThumbnail)
          : uploadVideoMeta.fileThumbnail;

      const dataInfo: { [key: string]: any } = {
        title: trimSpace(title),
        description: trimSpace(description),
        status,
        video_file: uploadVideoMeta.fileVideo,
      };

      const data = new FormData();
      Object.keys(dataInfo).map((key: string) => data.append(key, dataInfo[key]));
      data.append('thumbnail_file', thumbnail, 'thumbnail.png');

      const onUploadProgress = (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        this.percentUploaded = {
          filename: uploadVideoMeta.filename,
          completed: percent,
        };
      };

      const transformResponse = [
        (data: any) => {
          const errors = JSON.parse(data)?.errors ?? null;
          if (errors) {
            const messErrors = errors.join('; ');
            this.pushFlashMessages({
              content: `${i18n.t('admin.common.uploadFailed')} ${messErrors}`,
              status: 'error',
            });
            this.percentUploaded = { filename: '', completed: 0 };
          }
        },
      ];

      this.productAdminApi
        .addProduct({ data, onUploadProgress, transformResponse })
        .then(res => {
          this.pushFlashMessages({
            content: i18n.t('admin.productVideo.messages.createSuccess'),
            status: 'success',
          });
          this.getListProductAdmin();
          this.percentUploaded = { filename: '', completed: 0 };
        })
        .catch(error => {
          return;
        });
      history.push(routes.admin.productVideo);
    } catch (error) {
      this.errors = error.data;
    }
  }

  @action.bound
  public async updateProductVideo(id: any) {
    try {
      const {
        uploadVideoMeta: { fileThumbnail, fileVideo, filename },
        title,
        description,
        status,
      } = this.productDetailMeta;

      const thumbnail =
        typeof fileThumbnail === 'string' ? dataURLtoFile(fileThumbnail) : fileThumbnail;

      const dataInfo: { [key: string]: any } = {
        title: trimSpace(title),
        description: trimSpace(description),
        status,
        ...(fileVideo && { video_file: fileVideo }),
      };

      const data = new FormData();
      Object.keys(dataInfo).map((key: string) => data.append(key, dataInfo[key]));
      if (fileThumbnail) {
        data.append('thumbnail_file', thumbnail, 'thumbnail.png');
      }
      if (!fileVideo) {
        this.isLoading = true;
      }

      const onUploadProgress = (progressEvent: any) => {
        if (fileVideo) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.percentUploaded = {
            filename: filename,
            completed: percent,
          };
        }
      };

      const transformResponse = [
        (data: any) => {
          const errors = JSON.parse(data)?.errors ?? null;
          if (errors) {
            const messErrors = errors.join('; ');
            this.pushFlashMessages({
              content: `${i18n.t('admin.common.uploadFailed')} ${messErrors}`,
              status: 'error',
            });
            this.percentUploaded = { filename: '', completed: 0 };
          }
        },
      ];

      this.productAdminApi
        .updateProduct({ id, data, onUploadProgress, transformResponse })
        .then(res => {
          if (fileThumbnail || fileVideo) {
            history.push(routes.admin.productVideo);
            this.productDetailMeta = defaultProductDetail;
          } else {
            this.getProductDetail(id);
          }
          if (fileVideo) {
            this.percentUploaded = { filename: '', completed: 0 };
          }
          this.pushFlashMessages({
            content: i18n.t('admin.productVideo.messages.updateProductSuccess'),
            status: 'success',
          });
        })
        .catch(error => {
          return;
        });
    } catch (error) {
      this.errors = error;
      this.isLoading = false;
    }
  }

  @action.bound
  public handleErrorProduct(error: object) {
    this.errors = { ...error };
  }
}

export default ProductAdminStore;
