import { HearingItem } from 'types/HearingSet';
import { ProductItem } from 'types/Product';
import HttpClient from 'adapter/HTTPClient';

type ArchiveListResponse = {
  hearing_sets: HearingItem[];
  hearing_sets_page: number;
  total_hearing_sets: number;
  product_videos: ProductItem[];
  product_videos_page: number;
  total_product_vides: number; //DO NOT fix this key name, it is misspelled in API response as well
};

class ArchiveAdminApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getArchiveList = async ({
    panel,
    sort,
    order,
    page,
  }: {
    panel: string;
    sort: string;
    order: string;
    page: number;
  }) => {
    const res = await this.httpClient.request<ArchiveListResponse>({
      method: 'GET',
      url: `admin/archives`,
      params: {
        sort,
        order,
        ...(panel === 'products' ? { product_videos_page: page } : { hearing_sets_page: page }),
        hearing_sets_per_page: 8,
        product_videos_per_page: 12,
      },
    });
    return res;
  };

  public restoreData = async ({ field, listId }: { field: string; listId: number[] }) => {
    await this.httpClient.request({
      method: 'PUT',
      url: 'admin/archives/restore',
      data: {
        [field]: listId,
      },
    });
  };
}

export default ArchiveAdminApi;
