import { observable, action, computed } from 'mobx';
import { fromJS } from 'immutable';
import { formatWithJPTimeZone, trimSpace } from 'utility/helpers';
import i18n from 'i18n';
import {
  HearingPlayMeta,
  CurrentActivePlay,
  HearingMemo,
  AnswerResult,
  HearingItem,
} from 'types/HearingSet';
import { PaginationMeta } from 'types/common';
import history from 'utility/history';
import { routes, otherAnswerFixedId } from 'utility/constants';
import qs from 'qs';
import { RootStore } from '../index';
import { MessageProps } from 'types/App';
import { defaultHearingMemo, defaultHearingPlayMeta } from './constant';
import HearingSetApi from 'services/HearingSetApi';
import AppointmentApi from 'services/AppointmentApi';

class HearingStore {
  private readonly hearingSetApi: HearingSetApi;
  private readonly appointmentApi: AppointmentApi;
  @observable public rootStore: RootStore;
  @observable public isSelectHearing = false;
  @observable public loading = false;
  @observable public hearingSortType = '';
  @observable public isMemoModal = false;
  @observable public isConfirmModal = false;
  @observable public isAddHearingModal = false;
  @observable public hearingList: HearingItem[] = [];
  @observable public allHearingList: HearingItem[] = [];
  @observable public hearingSelected: number[] = [];
  @observable public hearingMeta: PaginationMeta = { currentPage: 1, totalItem: 0 };
  @observable public hearingPlay: HearingItem[] = [];
  @observable public hearingPlayMeta: HearingPlayMeta = { ...defaultHearingPlayMeta };
  @observable public hearingMemo: HearingMemo = { ...defaultHearingMemo };
  @observable public moreHearingSelect: number[] = [];
  @observable public productVideos: Array<number | string> = [];
  @observable public productVisitCount = 0;

  constructor({
    rootStore,
    hearingSetApi,
    appointmentApi,
  }: {
    rootStore: RootStore;
    hearingSetApi: HearingSetApi;
    appointmentApi: AppointmentApi;
  }) {
    this.rootStore = rootStore;
    this.hearingSetApi = hearingSetApi;
    this.appointmentApi = appointmentApi;
  }

  @computed get currentActivePlay(): CurrentActivePlay {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    const currentHearing = this.hearingPlay[activeHearingIndex];
    const currentContent = currentHearing
      ? currentHearing.data_files[activeContentIndex]
      : undefined;
    return { currentHearing, currentContent };
  }

  @computed get getMoreHearingSelectedList(): HearingItem[] {
    return this.allHearingList.filter(hearing => this.moreHearingSelect.includes(hearing.id));
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectHearing(): void {
    if (this.isSelectHearing) {
      this.hearingSelected = [];
    }
    this.isSelectHearing = !this.isSelectHearing;
  }

  @action.bound
  public toggleSelectHearingItem(val: number): void {
    if (this.hearingSelected.includes(val)) {
      this.hearingSelected = this.hearingSelected.filter((id: number) => id !== val);
    } else {
      this.hearingSelected = [...this.hearingSelected, val];
    }
  }

  @action.bound
  public toggleMemoModal(): void {
    this.isMemoModal = !this.isMemoModal;
  }

  @action.bound
  public toggleConfirmModal(): void {
    this.isConfirmModal = !this.isConfirmModal;
  }

  @action.bound
  public toggleAddHearingModal(): void {
    const current = this.isAddHearingModal;
    if (current) {
      this.moreHearingSelect = [];
    }
    this.isAddHearingModal = !current;
  }

  @action.bound
  public async getHearingList(page = 1, sort = 'latest', order = 'updated_at') {
    this.loading = true;
    this.hearingSortType = sort;
    this.hearingMeta = { ...this.hearingMeta, currentPage: page };
    try {
      const { data } = await this.hearingSetApi.getHearingSets({
        order,
        page,
        sort,
        per_page: 8,
      });

      this.hearingList = data.hearing_sets;
      this.hearingMeta = { ...this.hearingMeta, totalItem: data.total };
    } catch (error) {
      this.putFlashMessages({ content: error.message, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getAllHearings() {
    try {
      const { data } = await this.hearingSetApi.getHearingSets({
        page: 1,
        per_page: 50,
      });

      this.allHearingList = data.hearing_sets;
    } catch (error) {
      this.putFlashMessages({ content: error.message, status: 'error' });
    }
  }

  @action.bound
  public async startHearing(hearingId?: number) {
    if (hearingId && this.hearingPlay.length > 0) {
      this.moreHearingSelect = [hearingId];
      await this.handleAddmoreHearing();
    } else if (hearingId || this.hearingSelected.length) {
      const listID = hearingId ? [hearingId] : this.hearingSelected;
      history.push(
        `${routes.hearingsPlay}?${qs.stringify(
          { hearingIds: listID },
          { encode: false, indices: false }
        )}`
      );
    }
  }

  @action.bound
  public async getHearingPlayData(hearingIds: string[]) {
    this.loading = true;
    try {
      const { data } = await this.hearingSetApi.getOtherHearingSetDetails(hearingIds);
      if (data.length !== hearingIds.length) {
        history.push(routes.hearings);
        this.putFlashMessages({
          content: i18n.t('admin.hearingSet.messages.notFoundHearing'),
          status: 'error',
        });
      }

      this.hearingPlay = hearingIds.map(id =>
        data.find((it: any) => it.id === parseInt(id))
      ) as HearingItem[]; // FIX ME: Do not use `as` clause
    } catch {
      this.cancelHearingPlay();
      history.push(`${routes.productVideo}`);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public setHearingPlayMeta(name: string, value: string | number) {
    this.hearingPlayMeta = { ...this.hearingPlayMeta, [name]: value };
  }

  @action.bound
  public setAnswer(answerId: number) {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    const currentHearingPlayJS = fromJS(this.hearingPlay);
    const currHearing = this.hearingPlay[activeHearingIndex];
    const currQuestion = currHearing.data_files[activeContentIndex].question;
    const currAnswers = currQuestion.clientAnswers || [];
    const currentQuestionAnswer: AnswerResult[] = currQuestion?.question_answer?.answers ?? [];
    const otherAnswer: string = currAnswers.includes(otherAnswerFixedId)
      ? currQuestion?.otherAnswerContent ?? ''
      : '';
    let newAnswer: number[] = [];
    let newQuestionAnswer: AnswerResult[] = [];

    if (currAnswers.includes(answerId)) {
      if (currQuestion.is_multiple_choice) {
        newAnswer = currAnswers.filter(currAnswerId => currAnswerId !== answerId);
        newQuestionAnswer = currentQuestionAnswer.filter(
          answerResult => answerResult.id !== answerId
        );
      } else {
        newAnswer = [];
        newQuestionAnswer = [];
      }
    } else {
      const getAnswer = currQuestion.answers.find(item => item.id === answerId);
      const getQuestionAnswer: AnswerResult = {
        id: answerId,
        content: getAnswer?.content ?? '',
        feedback: getAnswer?.feedback ?? '',
      };
      if (currQuestion.is_multiple_choice) {
        newAnswer = [...currAnswers, answerId];
        newQuestionAnswer = [...currentQuestionAnswer, getQuestionAnswer];
      } else {
        newAnswer = [answerId];
        newQuestionAnswer = [getQuestionAnswer];
      }
    }
    this.hearingPlay = currentHearingPlayJS
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'clientAnswers'],
        newAnswer
      )
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'otherAnswerContent'],
        otherAnswer
      )
      .setIn(
        [
          activeHearingIndex,
          'data_files',
          activeContentIndex,
          'question',
          'question_answer',
          'answers',
        ],
        newQuestionAnswer
      )
      .toJS();
  }

  @action.bound
  public handleChangeNote(value: string, index: number): void {
    const { activeHearingIndex } = this.hearingPlayMeta;
    this.hearingPlay = fromJS(this.hearingPlay)
      .setIn(
        [activeHearingIndex, 'data_files', index, 'question', 'question_answer', 'note'],
        value
      )
      .toJS();
  }

  @action.bound
  public onChangeHearingMemo(name: string, value: string): void {
    this.hearingMemo = { ...this.hearingMemo, [name]: value };
  }

  @action.bound
  public toggleSelectMoreHearing(id: number): void {
    if (this.moreHearingSelect.includes(id)) {
      this.moreHearingSelect = this.moreHearingSelect.filter(item => item !== id);
    } else {
      this.moreHearingSelect = [...this.moreHearingSelect, id];
    }
  }

  @action.bound
  public async handleAddmoreHearing() {
    try {
      this.hearingPlayMeta = {
        ...this.hearingPlayMeta,
        activeHearingIndex: this.hearingPlay.length,
        activeContentIndex: 0,
      };
      const moreHearingIds = this.getMoreHearingSelectedList.map(hearing => hearing.id);
      const { data } = await this.hearingSetApi.getOtherHearingSetDetails(moreHearingIds);
      const newHearingPlays: HearingItem[] = [...this.hearingPlay, ...data];
      const listIds = newHearingPlays.map(hearing => hearing.id);
      this.hearingPlay = newHearingPlays;
      history.push(
        `${routes.hearingsPlay}?${qs.stringify(
          { hearingIds: listIds },
          { encode: false, indices: false }
        )}`
      );
    } catch (error) {
      this.putFlashMessages({ content: error.message, status: 'error' });
    } finally {
      this.isAddHearingModal = false;
    }
  }

  @action.bound
  public addProductVideoId(id: string | number) {
    this.productVideos = [...this.productVideos.filter(i => i !== id), id];
  }

  @action.bound
  public async handleEndHearing() {
    const { company_name, company_pic_name, memo } = this.hearingMemo;
    const data: any = {
      company_name: trimSpace(company_name),
      company_pic_name: trimSpace(company_pic_name),
      memo: trimSpace(memo),
    };
    data.product_video_ids = this.productVideos.slice();
    data.datetime = formatWithJPTimeZone(this.hearingPlayMeta.hearingTime, 'yyyy-MM-dd HH:mm:ss');
    data.hearing_sets = this.hearingPlay.map(item => ({
      id: item.id,
      questions: item.data_files.map(content => {
        const clientAnswers = content?.question?.clientAnswers ?? [].slice();
        const allAnswerIds = content.question.answers.map(answer => answer.id);
        // re-order client answer_ids as initial question's answers order.
        const answer_ids: Array<number | string> = allAnswerIds.filter(id =>
          clientAnswers.includes(id)
        );
        if (clientAnswers.includes(otherAnswerFixedId)) {
          answer_ids.push(content.question.otherAnswerContent || '');
        }
        return {
          id: content.question.id,
          answer_ids,
          note: trimSpace(content?.question?.question_answer?.note ?? ''),
        };
      }),
    }));
    try {
      this.toggleMemoModal();
      this.loading = true;
      await this.appointmentApi.createQuickHearing(data);
      this.putFlashMessages({
        content: i18n.t('hearingPlay.messages.createQuickHearingSuccess'),
        status: 'success',
      });
      history.push(routes.quickHearing);
    } catch (error) {
      const content = error?.errors ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public handleChangeOtherAnswer(val: string): void {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    this.hearingPlay = fromJS(this.hearingPlay)
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'otherAnswerContent'],
        val
      )
      .toJS();
  }

  @action.bound
  public cancelHearingPlay() {
    this.hearingPlay = [];
    this.productVideos = [];
    this.hearingPlayMeta = { ...defaultHearingPlayMeta };
    this.hearingMemo = { ...defaultHearingMemo };
  }

  @action.bound
  public resetMoreHearingSelect() {
    this.moreHearingSelect = [];
  }

  @action.bound
  public resetProductVisitCount() {
    this.productVisitCount = 0;
  }

  @action.bound
  public addProductVisitCount() {
    this.productVisitCount = this.productVisitCount + 1;
  }
}

export default HearingStore;
