import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { Modal } from 'views/components/compound';

const Wrapper = styled('div')`
  padding: 10px 32px;
  p,
  h4 {
    font-size: 16px;
    text-align: center;
  }

  p {
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 30px;
    color: ${palette.orangePrimary};
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    button:last-of-type {
      margin-left: 10px;
      background: ${palette.orangePrimary};
      &:hover {
        background: ${palette.orangeSecondary};
      }
    }
    button.danger {
      background: ${palette.redPrimary};
      &:hover {
        background: ${palette.redSecondary};
      }
    }
  }
`;

interface PropsTypes {
  className?: string;
  isArchive?: boolean;
  isDelete?: boolean;
  isModal: boolean;
  toggleModal: () => void;
  item?: string;
  handleConfirmModal: () => void;
}

const ConfirmModal: FC<PropsTypes> = ({
  isModal,
  toggleModal,
  handleConfirmModal,
  isArchive,
  isDelete,
  item,
  className,
}) => {
  const { t } = useTranslation();

  function confirmModal() {
    toggleModal();
    handleConfirmModal();
  }

  return (
    <Modal
      isModal={isModal}
      handleToggle={toggleModal}
      title={
        isArchive
          ? t('admin.common.archive')
          : isDelete
          ? t('admin.common.delete')
          : t('common.cancel')
      }
    >
      <Wrapper className={className}>
        <p>
          {isArchive && !isDelete && t('admin.common.messageConfirmArchive', { item: item })}
          {!isArchive && isDelete && t('admin.common.messageConfirmDelete')}
          {!isDelete && !isArchive && t('common.confirmCancel')}
        </p>
        {(!isArchive || isDelete) && <h4>{t('common.warningUndone')}</h4>}
        <div className="modal-actions">
          <Button
            label={isArchive || isDelete ? t('admin.common.cancelArchive') : t('common.noText')}
            size="md"
            type="default"
            onClick={toggleModal}
          />
          <Button
            label={
              isArchive
                ? t('admin.common.confirmArchive')
                : isDelete
                ? t('admin.common.confirmDelete')
                : t('common.yesText')
            }
            className={isArchive || isDelete ? 'danger' : ''}
            size="md"
            type="primary"
            onClick={confirmModal}
          />
        </div>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
