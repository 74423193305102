import React, { FC, useState, useMemo, useEffect } from 'react';

import { UploadContent } from './styles';
import UploadThumbnail from './UploadThumbnail';
import { InputReplaceFile } from 'views/components/compound';
import { UploadVideoMeta } from 'types/Product';
import { acceptVideo } from 'utility/constants';
import { getImgWithStatus } from 'utility/helpers';
import { MessageProps } from 'types/App';

interface PropTypes {
  firstFrameVideo: string;
  onChangeFileVideo: (file: object) => void;
  handleChangeMeta: (data: object) => void;
  isDetail: boolean;
  isEdit: boolean;
  dataDetail: UploadVideoMeta;
  componentBottom?: React.ReactElement;
  setActiveThumbnail: (data: string) => void;
  activeSrc: string;
  status: string;
  hasError: boolean;
  handleFlashMessage(data: MessageProps): void;
}

const UploadMeta: FC<PropTypes> = ({
  firstFrameVideo,
  isDetail,
  onChangeFileVideo,
  dataDetail,
  handleChangeMeta,
  isEdit,
  setActiveThumbnail,
  activeSrc,
  status,
  hasError,
  handleFlashMessage,
}) => {
  const { thumbnailSrcConvert, fileThumbnail, fileVideo } = useMemo(() => dataDetail, [dataDetail]);
  const isNotUploaded = useMemo(() => status === 'not_uploaded', [status]);
  const isNotReady = isNotUploaded;
  const [isDropzone, setIsDropzone] = useState(true);

  useEffect(() => {
    if (!isEdit) {
      setIsDropzone(true);
    }
  }, [isEdit]);

  function handleChangeShowImage(data: string) {
    if ((isDetail && isEdit) || !isDetail) {
      setActiveThumbnail(data);
      const { fileThumbnailConst } = dataDetail;
      const isBlob = data.split(':')[0] === 'blob';
      if (isBlob && fileThumbnailConst) {
        handleChangeMeta({ fileThumbnail: fileThumbnailConst });
      } else {
        if (isDetail) {
          handleChangeMeta({ fileThumbnail: '' });
        } else {
          handleChangeMeta({ fileThumbnail: data });
        }
      }
    }
  }

  function getSrcImageThumbnail() {
    const listImages = [firstFrameVideo, thumbnailSrcConvert];
    return listImages.find(it => it === activeSrc) || listImages[0];
  }

  function handleReplaceVideo(file: any) {
    setActiveThumbnail(firstFrameVideo);
    onChangeFileVideo(file);
    setIsDropzone(true);
  }

  return (
    <UploadContent className="thumbnail_content">
      <div className="thumbnail_video">
        <img
          src={getImgWithStatus(status, getSrcImageThumbnail())}
          alt="thumbnail_video"
          className={isNotReady || !fileThumbnail || status === 'upload_failed' ? 'not-ready' : ''}
        />
        {(isEdit || !isDetail) && !isNotReady && (
          <InputReplaceFile
            id="replace_video"
            accept={acceptVideo.join(', ')}
            className="video-replace"
            handleChange={handleReplaceVideo}
          />
        )}
      </div>
      <div className="thumnail_upload">
        <UploadThumbnail
          isDetail={isDetail}
          isEdit={isEdit}
          activeSrc={activeSrc}
          handleChangeShowImg={handleChangeShowImage}
          handleChangeMeta={handleChangeMeta}
          setIsDropzone={setIsDropzone}
          isDropzone={isDropzone}
          setActiveThumbnail={setActiveThumbnail}
          isNotUploaded={isNotUploaded}
          firstFrameVideo={firstFrameVideo}
          thumbnailSrcConvert={thumbnailSrcConvert}
          status={status}
          hasError={hasError}
          onChangeFileVideo={onChangeFileVideo}
          fileVideo={fileVideo}
          handleFlashMessage={handleFlashMessage}
        />
      </div>
    </UploadContent>
  );
};

export default UploadMeta;
