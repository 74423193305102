import React, { FC } from 'react';
import { Select } from 'views/components/primitive';
import { optionsStatus } from 'utility/constants';

interface PropTypes {
  value: string;
  size: 'md' | 'sm';
  onChangeStatus: (val: string) => void;
}

const SelectStatus: FC<PropTypes> = ({ value, onChangeStatus }) => {
  return (
    <div className="switch-status">
      <Select onChange={onChangeStatus} size="sm" value={value} options={optionsStatus} />
    </div>
  );
};

export default SelectStatus;
