import styled from '@emotion/styled';
import { palette, bp, layer } from 'entity/createTheme';

export const UploadContent = styled('div')`
  display: flex;
  margin-bottom: 20px;
  .thumbnail_video {
    margin-right: 20px;
    width: 700px;
    height: 394px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    user-select: none;
    background: ${palette.blackPrimary};
    @media ${bp.xmd} {
      width: 650px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.not-ready {
        object-fit: cover;
      }
    }
    .video-replace {
      position: absolute;
      top: 10px;
      right: 10px;

      // Might not need to specify z-index
      z-index: ${layer.bottomAbove1};
    }

    @media ${bp.md} {
      margin-bottom: 16px;
      width: 100%;
    }
  }
  .thumnail_upload {
    width: calc(100% - 720px);
    @media ${bp.xmd} {
      width: calc(100% - 670px);
    }
    .file-name {
      label {
        display: block;
        font-size: 14px;
        color: ${palette.grayMedium4};
        margin-bottom: 10px;
        @media ${bp.md} {
          margin-bottom: 8px;
        }
      }
      p {
        font-size: 14px;
        color: ${palette.darkMedium8};
        overflow: hidden;
        margin-right: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @media ${bp.md} {
      width: 100%;
    }
  }
  @media ${bp.md} {
    width: 100%;
    display: block;
    margin-bottom: 24px;
  }
`;

export const UploadThumbnailWrapper = styled('div')`
  .thumbnail {
    &-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      @media ${bp.md} {
        margin-bottom: 4px;
      }
    }
    &-title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    &-item {
      height: 95px;
      width: 170px;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      border: 2.2px solid ${palette.whitePrimary};
      &.thumbnail {
        background: ${palette.blackPrimary};
      }
      @media ${bp.xmd} {
        width: 156px;
      }
      &:nth-of-type(odd) {
        margin-right: 20px;
      }
      &.active {
        border-color: ${palette.darkMedium2};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.not-ready {
          object-fit: cover;
        }
      }
      .thumbnail-replace {
        position: absolute;
        top: 5px;
        right: 5px;

        // Might not need to specify z-index
        z-index: ${layer.bottomAbove1};
        width: 32px;
        height: 32px;
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.dropzone-img {
        padding: 16px 10px;
        svg {
          flex-shrink: 0;
        }
        p {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      @media ${bp.md} {
        height: 84px;
        width: 150px;

        &:nth-of-type(odd) {
          margin-right: 16px;
        }
      }
    }
  }
`;

export const UploadVideoWrapper = styled('div')`
  video,
  canvas {
    display: none;
  }
  & > p.error {
    color: ${palette.redPrimary};
    margin-top: 20px;
    font-size: 12px;
  }
`;
