import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import React, { FC } from 'react';

type Props = {
  className?: string;
};

const HearingWrapper: FC<Props> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};

export default HearingWrapper;

const Root = styled('div')({
  padding: 30,
  height: '100%',
  [`@media ${bp.md}`]: {
    padding: 16,
  },
});
