import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { palette, bp } from 'entity/createTheme';

interface HearingItemWrapperProps {
  isSelected: boolean;
  clickable?: boolean;
}

const hoverStyle = css`
  border-radius: 6px;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const HearingItemWrapper = styled('div')<HearingItemWrapperProps>`
  width: calc(50% - 10px);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${palette.whitePrimary};
  border: 1px solid ${({ isSelected }) => (isSelected ? palette.darkMedium2 : palette.whitePrimary)};

  /* hover interaction code*/
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};
  position: relative;

  &::before {
    ${({ clickable }) => clickable && hoverStyle}
  }

  &:hover {
    background: ${({ clickable }) => (clickable ? palette.grayMedium5 : palette.whitePrimary)};
    border: 1px solid
      ${({ isSelected, clickable }) =>
        isSelected ? palette.darkMedium2 : clickable ? palette.grayMedium5 : palette.whitePrimary};

    &::before {
      background: ${({ clickable }) => (clickable ? palette.grayBlur : 'none')};
    }
  }
  /* -------------------------- */

  &:nth-of-type(odd) {
    margin-right: 20px;
  }

  .hearing-info {
    display: flex;
    flex: 1;
    margin-bottom: 10px;

    &--thumbnail {
      height: 88px;
      min-width: 88px;
      background-color: ${palette.blackPrimary};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 6px;
      overflow: hidden;

      img {
        height: 100%;
        width: 88px;
        object-fit: contain;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin-bottom: 10px;
    }

    &__title {
      font-weight: bold;
      cursor: pointer;

      @supports not (overflow-wrap: anywhere) {
        word-break: break-word;
      }

      @supports (overflow-wrap: anywhere) {
        overflow-wrap: anywhere;
      }

      &.disabled {
        cursor: auto;
      }
    }

    &__counts,
    &__title {
      @media ${bp.md} {
        font-size: 16px !important;
      }
    }

    &__updated {
      color: ${palette.grayMedium4};
    }

    @media ${bp.md} {
      .hearing-info--thumbnail {
        min-width: 64px;
        height: 64px;

        img {
          width: 64px;
        }
      }

      p {
        margin-bottom: 8px;
      }
    }
  }

  .hearing-action {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    align-items: flex-end;

    button:nth-of-type(1) {
      margin-right: 10px;
    }
  }

  @media ${bp.md} {
    padding: 8px;
    margin-bottom: 16px;
    width: calc(50% - 8px);

    &:nth-of-type(odd) {
      margin-right: 16px;
    }

    .hearing-action {
      margin-top: 0;
    }
  }

  @media ${bp.xs} {
    width: 100%;
    &:nth-of-type(odd) {
      margin-right: 0;
    }
  }
`;
