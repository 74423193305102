import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import { Question } from 'views/components/compound/QuestionForm';
import { Question as QuestionType } from 'types/Question';

interface PropTypes {
  questionDetail: QuestionType;
  handleChangeDetail(data: object): void;
  disableEdit?: boolean;
  hasError: boolean;
}

const QuestionContent: FC<PropTypes> = ({
  questionDetail,
  handleChangeDetail,
  disableEdit = false,
  hasError,
}) => {
  const { t } = useTranslation();
  const { answers, content, is_multiple_choice, has_other } = useMemo(() => questionDetail, [
    questionDetail,
  ]);

  return (
    <QuestionContentWrapper>
      <Headline>{t('admin.questions.question')}</Headline>
      <Question
        disableEdit={disableEdit}
        answers={answers || []}
        question={content}
        hasError={hasError}
        status={is_multiple_choice ? '1' : '0'}
        handleChange={handleChangeDetail}
        hasOther={has_other}
      />
    </QuestionContentWrapper>
  );
};

const Headline = styled('h4')({
  marginBottom: 15,
  fontSize: 16,
  color: palette.darkMedium9,
});

const QuestionContentWrapper = styled('div')({
  padding: 20,
  background: palette.whitePrimary,
  borderRadius: 6,
});

export default QuestionContent;
