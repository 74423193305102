import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import { optionsStatus } from 'utility/constants';

interface SwitchStatusTypes {
  isDetail?: boolean;
}

const SwitchWrapper = styled('div')<SwitchStatusTypes>`
  width: ${({ isDetail }) => (isDetail ? 'auto' : '200px')};
  border: 0.8px solid ${palette.grayPrimary};
  border-radius: 6px;
  display: ${({ isDetail }) => (isDetail ? 'inline-block' : 'flex')};
  user-select: none;
  .status-item {
    width: 100px;
    height: 32px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    cursor: ${({ isDetail }) => (isDetail ? 'auto' : 'pointer')};
    &.active {
      background: ${palette.darkMedium2};
      color: ${palette.whitePrimary};
      border-radius: 6px;
    }
  }
`;

interface PropTypes {
  status: string;
  onChangeStatus: (val: string) => void;
  isDetail?: boolean;
}

const SwitchStatus: FC<PropTypes> = ({ status, onChangeStatus, isDetail }) => {
  const { t } = useTranslation();

  function formatOptions() {
    let data = [...optionsStatus];
    if (isDetail) {
      data = data.filter(it => it.value === status);
    }
    return data.map(it => ({ ...it, label: t(it.label) }));
  }

  function renderStatusItem() {
    const data = formatOptions();
    return data.map(it => {
      const active = status === it.value && 'active';
      return (
        <span
          key={it.value}
          className={`status-item ${active}`}
          onClick={() => onChangeStatus(it.value)}
        >
          {it.label}
        </span>
      );
    });
  }

  return (
    <SwitchWrapper isDetail={isDetail} className="switch-status">
      {renderStatusItem()}
    </SwitchWrapper>
  );
};

export default SwitchStatus;
