import React, { ChangeEvent, MouseEvent } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { palette } from 'entity/createTheme';

interface PropTypes {
  checked: boolean;
  disabled?: boolean;
  handleChange: (isChecked: boolean) => void;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 48,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(20px)',
        color: palette.whitePrimary,
        '& + $track': {
          backgroundColor: palette.greenPrimary,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: palette.whitePrimary,
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: palette.whitePrimary,
    },
    track: {
      borderRadius: 28 / 2,
      backgroundColor: palette.grayMedium1,
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple={true}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CustomizedSwitches: React.FC<PropTypes> = ({ checked, handleChange, disabled }) => {
  function onSwitchChange(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    handleChange(!checked);
  }

  function onClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
  }

  return (
    <IOSSwitch disabled={disabled} checked={checked} onChange={onSwitchChange} onClick={onClick} />
  );
};

export default CustomizedSwitches;
