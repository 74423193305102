import { css } from '@emotion/core';
import { palette } from 'entity/createTheme';

export const style = css`
  ul {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    li {
      display: inline-block;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        height: 32px;
        width: 32px;
        margin: 0 6px;
        background: ${palette.whitePrimary};
        font-size: 0.875rem;
        line-height: 1.125rem;
        cursor: pointer;
      }

      &.break a {
        cursor: default;
      }

      &.inactive a {
        cursor: not-allowed;
      }

      &.active a,
      &.page a:hover {
        font-weight: bold;
        color: ${palette.whitePrimary};
        background: ${palette.darkMedium1};
      }
    }
  }
`;
