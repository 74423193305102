import { Languages } from './models';

const jp: Languages = {
  common: {
    notFound: '404 ページが見つかりませんでした',
    sort: '並び替え',
    select: '複数選択',
    add: '追加',
    save: '保存',
    saving: '保存中',
    edit: '編集する',
    cancel: 'キャンセル',
    ok: 'OK',
    sortLatest: '新着順',
    sortOldest: '投稿順',
    sortASC: 'A - Z',
    sortDESC: 'Z - A',
    createAppointment: '商談を作成',
    startHearing: 'ヒアリング開始',
    infomation: '情報',
    companyName: '企業名',
    externalClientId: '企業ID',
    customerName: 'お客さま名',
    timeTitle: '日時',
    customerTitle: '顧客企業',
    byTitle: '担当営業',
    memoTitle: 'メモ',
    loginTitle: 'Kaizen IDでログイン',
    logoutTitle: 'ログアウト',
    openAccountConsole: 'アカウント管理画面を開く',
    leaveConfirmMessage: '入力した内容は保存されません。画面を閉じてよろしいでしょうか?',
    yesText: 'はい',
    noText: 'いいえ',
    confirmCancel: '本当に中止してよろしいでしょうか？',
    confirmLeave: '本当にこの画面から離れたいでしょうか？',
    warningUndone: '内容は保存されません',
    appointment: '商談履歴',
    emptyMessages: 'アイテムがまだありません',
    emptySearchResult: '検索結果はありません',
    accountSetting: 'アカウント設定',
    send: '送信',
    link: 'リンク',
    videoNotavailable: '動画は処理されています。まもなく動画が観覧できます。',
    videoUploadFailed: '動画のアップロードができていないようです。管理者に確認してください。',
    comment: 'コメント',
    csvDownload: 'CSVをダウンロード',
    csvDownloadFailed: 'ダウンロードに失敗しました',
    csvDownloadOption: {
      utf8: 'UTF-8',
      shiftjis: 'SHIFT-JIS',
    },
    reload: 'Reload',
    actionTarget: {
      hearings: 'ヒアリング',
      products: '動画',
      links: '共有用リンク',
    },
    noOrganizationMessage:
      '組織に所属していないため、アクセスできません。組織管理者に招待してもらってください。',
  },
  sidebar: {
    productVideo: '紹介動画',
    hearings: 'ヒアリング',
    quickHearing: 'ヒアリング結果',
    managementTitle: '管理者用メニュー',
    hearingLink: '共有用リンク',
    setting: '設定',
  },
  hearingSet: {
    searchPlaceholder: 'ヒアリング、商談などを検索',
    hearingSetTitle: 'ヒアリング',
    hearingItem: {
      numOfVideo: '{{count}} 質問',
      lastUpdated: '更新日',
    },
  },
  quickHearing: {
    quickHearingTitle: 'ヒアリング結果',
    addAppointment: '商談情報を登録',
    viewResult: '結果を見る',
    notFoundQuickHearings: 'ヒアリング結果はありません',
    clientIdToolTip:
      'SFAなど外部の営業管理ツール等の企業IDを入力します。csvにも出力されるため、外部ツールへのデータ取り込みを効率化できます',
  },
  hearingPlay: {
    previousVideo: '前の動画',
    nextVideo: '次の動画',
    previousQuestion: '前の質問',
    nextQuestion: '次の質問',
    endHearing: 'ヒアリング終了',
    endWatching: '視聴を終了',
    endAppointment: '商談を終了',
    end: '終了',
    cancelHearing: 'ヒアリング中止',
    otherAnswerPlaceHolder: 'あなたのコメント',
    result: {
      title: 'ヒアリング結果',
      watchTitle: '視聴結果',
      answerLabel: '回答',
      feedbackLabel: '回答に対するフィードバック',
      noteLabel: '備考',
      addHearingSet: 'ヒアリングを追加',
      addProduct: '紹介動画を追加',
      companyLabel: '企業名 - 任意',
      customerNameLabel: 'お客さま名 - 任意',
      createMemo: 'メモ入力',
      memoLabel: 'メモ',
      namePlaceholder: '名前を入力してください',
      memoPlaceholder: 'メモを入力してください',
      buttonSave: '保存して終了',
      searchHearingPlaceholder: 'ヒアリング検索',
      endHearing: 'ヒアリング終了',
      otherAnswerLabel: 'その他(自由入力)',
    },
    messages: {
      createQuickHearingSuccess: '商談情報が作成されました',
    },
    questionTypeLabel: {
      video: '動画',
      link: '動画',
      pdf: 'PDF',
      image: '画像',
      none_data: 'なし',
    },
    noneDataMessage: 'この質問にはコンテンツが添付されていません。',
  },
  productVideo: {
    title: '紹介動画',
    startAppointment: '動画をみる',
    createDate: '更新日',
    ortherVideo: 'その他の動画',
    searchVideoPlaceholder: '動画の検索',
    showMoreVideos: '全ての動画をみる',
  },
  createAppointment: {
    appointmentListTitle: '商談履歴',
    messages: {
      createAppointmentSuccess: '商談情報が作成されました',
    },
    errors: {
      companyName: '企業名は必須項目です',
    },
  },
  appointmentDetail: {
    title: '商談詳細',
    deleteSuccess: '商談情報が削除されました',
    updateSuccess: '商談情報が更新されました',
  },
  hearingLink: {
    creator: '作成者',
    createHearingTitle: 'リンク生成',
    linkInfoTitle: 'リンク詳細',
    accountLabel: '顧客企業',
    noSpecifiedAccount: '顧客企業なし',
    settingLabel: '設定',
    mailMainLabel: 'メールアドレスを求める',
    mailSubLabel: 'チェックをつけると、リンクを開く時にメールアドレス入力が必須になります',
    mainSkipResultsLabel: '結果画面をスキップする',
    subSkipResultsLabel: 'チェックをつけると、ヒアリング結果画面が非表示になります',
    mainHideCreatorLabel: '作成者を非表示にする',
    subHideCreatorLabel: 'チェックをつけると、リンク上で作成者が非表示になります',
    mainRedirectLabel: 'リダイレクトURL',
    subRedirectLabel: 'ヒアリング終了後にリダイレクトするURLを設定できます',
    redirectURLSetting: {
      urlSetting: 'URL設定',
      url: 'URL',
      urlPlaceholder: 'URLを入力',
      description: 'URLの説明文',
      descriptionPlaceholder: '例) お打ち合わせをご希望の方は、こちらから日程調整をお願いします',
      autoRedirect: '自動でリダイレクトする',
      urlNotSet: 'まだURLが設定されていません',
      invalidUrl: '無効なURLです',
      requireUrl: 'URLを設定してください',
      exceedLimitURL: 'URLは200文字以内で入力してください',
      exceedLimitDescription: '概要は200文字以内で入力してください',
    },
    createNewOption: '新規追加',
    switchLink: 'リンクをON/OFFする',
    createdByLabel: '作成者',
    clientCommentTitle: 'コメント、ご要望など',
    clientCommentPlaceHolder: '例) サービスについて詳しく知りたいので、打ち合わせを希望したい',
    inputEmailTitle: 'メールアドレスを入力してください',
    nameLabel: '名前',
    accessTimeLabel: 'アクセス時間',
    createdUserEmail: 'さんがあなたにヒアリングを共有しました',
    enterEmail: '回答するために、メールアドレスを入力してください。',
    confirmEndHearingTitle: '結果の確認',
    confirmEndHearingDescription: 'この結果で回答を送信してよろしいでしょうか？',
    confirmEndHearingAgree: '送信する',
    confirmEndHearingDisagree: 'キャンセル',
    confirmEndWatchingTitle: '結果の確認',
    confirmEndWatchingDescription: 'この結果で回答を送信してよろしいでしょうか？',
    confirmEndWatchingAgree: '送信する',
    hearingCompleteTitle: 'ヒアリング完了',
    watchingCompleteTitle: '視聴完了',
    hearingCompleteDescription: 'ご回答ありがとうございました',
    continueBtn: '次へ',
    sendResultBtn: '結果を送信する',
    linkType: 'リンク種別',
    messages: {
      createHearingLinkFail: '共有用リンクの作成が失敗しました',
      createHearingLinkSuccess: '新しい共有用リンクが作成されました',
      updateHearingLinkSuccess: '共有用リンクが更新されました',
      updateHearingLinkFail: '共有用リンクの更新に失敗しました',
      notFoundHearingLink: '存在しない共有用リンクです',
      checkHearingTokenFailed: 'ヒアリングを取得できません',
      getLinkInfomationFailed: '情報を取得できませんでした',
      emailNotValid: '有効なメールアドレスを入力してください',
      createAppointmentFailed: 'スケジュールの作成に失敗しました',
    },
    productVideoWatchedTitle: '視聴した動画',
    noPublicHearing: '公開されているヒアリングがありません。公開してから、ONにしてください',
    noPublicProductVideo: '公開されている動画がありません。公開してから、ONにしてください',
    urlParameters: 'URLパラメータ',
    noteRedirection: 'リンクを開くにはボタンをクリックしてください',
    noteRedirectionInFiveSeconds: {
      firstHalf: '',
      latterHalf:
        '秒後にリダイレクトします。リダイレクトされない場合はボタンをクリックしてください',
    },
    redirectButton: 'リンクを開く',
  },
  admin: {
    common: {
      filename: 'ファイル名',
      uploadVideo: '動画をアップロード',
      uploadThumbnail: 'サムネイルをアップロード',
      draft: '下書き',
      publish: '公開',
      archive: 'アーカイブ',
      setting: '設定',
      restore: '元に戻す',
      dragAndDrop: 'ドラッグ＆ドロップでアップロード',
      browse: 'ファイルを選択',
      thumbnail: 'サムネイル',
      title: 'タイトル',
      checkbox: 'チェックボックス',
      radio: 'ラジオボタン',
      delete: '削除',
      placeholderVideoInput: '動画のタイトルを入力してください',
      description: '概要',
      placeholderVideoTextarea: '紹介文を記入してください',
      confirmArchive: 'アーカイブ',
      confirmDelete: '削除する',
      messageConfirmArchive: '{{ item }}をアーカイブしますか？',
      messageConfirmDelete: 'データを削除しますか？',
      cancelArchive: 'いいえ',
      messages: {
        archiveSuccess: '{{ item }}がアーカイブされました',
        restoreSuccess: '{{ item }}が元に戻されました',
        deleteSuccess: 'データが削除されました',
      },
      uploadingProgress: 'ファイルをアップロードしています...',
      uploadFailed: 'アップロードが失敗しました:',
      notLoadThumbnail:
        '{{filename}}のサムネイルはロードできません。画像をアップロードして、サムネイルを設定してください',
      notViewDetail: '削除されましたファイルは開けません',
      notFileVideo: '{{filename}}: 動画のファイル形式はサポートされません',
      notFileImage: 'サムネイルのファイル形式はサポートされません',
      isDraftOrArchived: 'ステータスが下書きまたはアーカイブとなっています',
      itemInUse: '共有用リンクに紐付いたコンテンツ',
    },
    productVideo: {
      titleHeader: '紹介動画の管理',
      upload: 'アップロード',
      emptyProduct: '動画は0件です',
      messages: {
        changeStatusSuccess: 'ステータスが更新されました',
        createSuccess: '紹介動画が作成されました',
        updateProductSuccess: '動画を更新する',
        notFoundProduct: '商品紹介動画は存在しません!',
      },
      titleBlank: 'タイトルは必須項目です',
      descriptionBlank: '紹介文は必須項目です',
      videoInUse:
        'この動画は共有用リンクに紐付いています。共有用リンクで見れなくなりますが、よろしいですか？',
    },
    hearingSet: {
      titleHeader: 'ヒアリング管理',
      addHearing: 'ヒアリング作成',
      placeholderTitleHearing: 'ヒアリングのタイトルを入力してください',
      addQuestions: '質問を追加する',
      noTitle: 'タイトル未設定',
      messages: {
        createHearingSuccess: '新しいヒアリングセットは作成されました！',
        createHearingError: 'ヒアリングセット作成する時、エラーが発生しました!',
        updateHearingSuccess: 'ヒアリングセットは更新されました!',
        updateHearingFail: 'ヒアリングセットを更新する時、エラーが発生しました!',
        titleBlank: 'ヒアリングセットのタイトルは必項です',
        questionBlank: '質問を追加して下さい。',
        noResults: '検索結果はありません',
        notFoundHearing: 'ヒアリングセットは存在しません!',
        statusValidationError: 'タイトルもしくは質問の存在しないヒアリングセットは公開できません',
      },
      hearingSetInUse:
        'このヒアリングは共有用リンクに紐付いています。共有用リンクで見れなくなりますが、よろしいですか？',
      questionIndex: 'No.{{ index }}',
      saveQuestionOrder: '質問の順序を保存',
      editQuestionOrder: '質問の順序を変更する',
      editQuestionOrderHint: 'ドラッグ＆ドロップで質問の順序を変更できます',
    },
    questions: {
      addQuestion: '質問を作成',
      submittingAddQuestion: '質問を作成中',
      savingUpdateQuestion: '保存中',
      question: '質問',
      content: 'コンテンツ',
      placeholderQuestion: '質問を入力してください',
      placeholderFeedback: '回答に対するフィードバック',
      placeholderAnswer: '回答 {{index}}',
      addAnswer: '回答を追加',
      countAnswers: '{{count}} 回答',
      createSuccess: '質問が追加されました',
      contentFormLabel: {
        video: '動画をアップロード',
        link: '動画リンク',
        image: '画像',
        pdf: 'PDF',
        none_data: 'なし',
      },
      embedURL: '動画URL',
      embedVideoLink: '動画リンクを入力',
      embedModalLabel: 'YouTube・Vimeo の動画URLを入力してください',
      videoURL: '動画URL',
      changeLink: '動画リンクを変更',
      addOther: '「その他」を選択肢に追加する',
      errors: {
        invalidVideoURL: 'YouTubeもしくはVimeoの動画URLを入力してください',
        notFound: '入力されたURLの動画が見つかりませんでした',
      },
      messages: {
        questionBlank: '質問タイトルは必項です',
        answerBlank: '回答は必項です',
        createQuestionSuccess: '質問が作成されました',
        createQuestionError: '質問が作成できませんでした',
        updateQuestionSuccess: '質問が更新されました',
        updatedQuestionError: '質問が更新できませんでした',
        deleteQuestionSuccess: '質問が削除されました',
        deleteQuestionError: '質問が削除できませんでした',
      },
    },
    setting: {
      changeLogo: 'ロゴを変更する',
      currentLogo: '現在のロゴ',
      newLogo: '新しいロゴ',
      uploadLogoAcceptedFormats: '受け付けるフォーマット: jpeg, png, gif',
      uploadLogoMaxSize: '最大1MB',
      preview: 'プレビュー',
      messages: {
        changeLogoSuccess: 'ロゴが変更されました',
        changeLogoError: 'ロゴが変更できませんでした',
      },
    },
  },
};

export default jp;
