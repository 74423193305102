import Cookies from 'js-cookie';
const KAIZEN_ID_DOMAIN = process.env.REACT_APP_KAIZEN_DOMAIN;
const ORGANIZATION_ID = 'organization_id';

class CookieStorage {
  setOrganizationId(id: string): void {
    Cookies.set(ORGANIZATION_ID, id, { path: '/', expires: 7, domain: KAIZEN_ID_DOMAIN });
  }

  getOrganizationId(): string {
    return Cookies.get(ORGANIZATION_ID) || '';
  }
}

const cookieStorage = new CookieStorage();

export { cookieStorage };
