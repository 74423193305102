import { routes } from 'utility/constants';

import hearingSetIcon from 'assets/images/ic_hearing_set.svg';
import quickHearingIcon from 'assets/images/ic_light_bolt.svg';
import productVideoIcon from 'assets/images/ic_video.svg';
import adminProductVideoIcon from 'assets/images/ic_video_admin.svg';
import adminHearingSetIcon from 'assets/images/ic_admin_hearing_set.svg';
import archiveIcon from 'assets/images/ic_archive_sidebar.svg';
import HearingLinkIcon from 'assets/images/ic_hearing_link.svg';
import settingIcon from 'assets/images/ic_setting.svg';

export interface NavLinkProps {
  id: number;
  activeUrl: string;
  iconUrl: string;
  label: string;
}

export const navLinksSales: NavLinkProps[] = [
  {
    id: 1,
    activeUrl: routes.productVideo,
    iconUrl: productVideoIcon,
    label: 'sidebar.productVideo',
  },
  {
    id: 2,
    activeUrl: routes.hearings,
    iconUrl: hearingSetIcon,
    label: 'sidebar.hearings',
  },
  {
    id: 3,
    activeUrl: routes.quickHearing,
    iconUrl: quickHearingIcon,
    label: 'sidebar.quickHearing',
  },
  {
    id: 4,
    activeUrl: routes.hearingLink,
    iconUrl: HearingLinkIcon,
    label: 'sidebar.hearingLink',
  },
];

export const navLinksSalesWithRoleAdmin = [
  ...navLinksSales,
  {
    id: 5,
    activeUrl: routes.hearingLinkArchive,
    iconUrl: archiveIcon,
    label: 'admin.common.archive',
  },
];

export const navLinksAdmin: NavLinkProps[] = [
  {
    id: 1,
    activeUrl: routes.admin.productVideo,
    iconUrl: adminProductVideoIcon,
    label: 'sidebar.productVideo',
  },
  {
    id: 2,
    activeUrl: routes.admin.hearingSet,
    iconUrl: adminHearingSetIcon,
    label: 'sidebar.hearings',
  },
  {
    id: 4,
    activeUrl: routes.admin.archive,
    iconUrl: archiveIcon,
    label: 'admin.common.archive',
  },
  {
    id: 5,
    activeUrl: routes.admin.setting,
    iconUrl: settingIcon,
    label: 'sidebar.setting',
  },
];
