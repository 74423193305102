import { action, computed, observable } from 'mobx';
import { RootStore } from 'stores';
import settingApi from 'services/Admin/SettingApi';
import { MessageProps } from 'types/App';
import i18n from 'i18n';

class SettingLogoStore {
  private readonly settingApi: settingApi;
  @observable rootStore: RootStore;
  @observable public loading = false;
  @observable public userChosenLogoImage: any;
  @observable public newLogoSelected = false;
  @observable public previewMode = false;
  @observable public previewLogoImage = '';
  @observable public needsRefetchLogo = false;

  constructor({ rootStore, settingApi }: { rootStore: RootStore; settingApi: settingApi }) {
    this.rootStore = rootStore;
    this.settingApi = settingApi;
  }

  @computed get userChosenLogoImageUrl() {
    const logoSrc = this.userChosenLogoImage ? URL.createObjectURL(this.userChosenLogoImage) : '';
    return logoSrc;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public togglePreviewMode() {
    if (this.newLogoSelected) {
      this.previewMode = true;
      this.previewLogoImage = this.userChosenLogoImageUrl;
    } else {
      this.previewMode = false;
      this.previewLogoImage = '';
    }
  }

  @action.bound
  public changeUserChosenLogoImage(imageFile: any) {
    this.userChosenLogoImage = imageFile;
    this.previewMode = false; //Everytime user uploads a new image, clear previewMode of previously uploaded one. We can clear previewMode after api request succeeded, but that means sidebar image and current logo image will not be updated at the same time which might cause bad user experience.
  }

  @action.bound
  public toggleNewLogoSelected(isNewLogoSelected: boolean) {
    this.newLogoSelected = isNewLogoSelected;
  }

  @action.bound
  public changeNeedsRefetchLogo(value: boolean) {
    this.needsRefetchLogo = value;
  }

  @action.bound
  public async updateLogo() {
    this.loading = true;
    const data = new FormData();
    data.append('logo', this.userChosenLogoImage);
    try {
      await this.settingApi.updateOrganizationLogo(data);
      this.pushFlashMessages({
        content: i18n.t('admin.setting.messages.changeLogoSuccess'),
        status: 'success',
      });
      this.needsRefetchLogo = true;
    } catch (error) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.messages.changeLogoError')} ${errorMessage}`,
        status: 'error',
      });
      //if failing to save currently previewed image, clear previewMode of it, because image in sidebar is no longer depends on previewMode, we need to clear previewLogoImage as well
      this.previewMode = false;
      this.previewLogoImage = '';
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetUploadedLogoImage() {
    this.userChosenLogoImage = null;
    this.newLogoSelected = false;
    this.previewMode = false;
    this.previewLogoImage = ''; //because image in sidebar is no longer depends on previewMode, we need to clear previewLogoImage as well
  }
}

export default SettingLogoStore;
