import { css } from '@emotion/core';
import { palette } from 'entity/createTheme';

export const style = css`
  width: 170px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.md {
    height: 42px;
    font-size: 0.875rem;
  }

  &.sm {
    width: 150px;
    height: 32px;
    font-size: 0.75rem;
  }

  &.xs {
    width: 120px;
    height: 32px;
    font-size: 0.75rem;
  }

  &.default {
    background-color: ${palette.whitePrimary};
    border: 1px solid ${palette.grayMedium1};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.grayMedium9};
        border-color: ${palette.grayPrimary};
        color: ${palette.darkMedium3};
        svg > g > g {
          fill: ${palette.grayMedium4};
        }
      }
    }
  }

  &.primary {
    color: ${palette.whitePrimary};
    background-color: ${palette.greenPrimary};
    @media (hover: hover) {
      &:hover {
        color: ${palette.whitePrimary};
        background-color: ${palette.greenSecondary};
      }
    }
  }

  &.secondary {
    color: ${palette.whitePrimary};
    background-color: ${palette.bluePrimary};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.blueSecondary};
      }
    }
  }

  &.outline {
    color: ${palette.darkMedium2};
    background-color: ${palette.whitePrimary};
    border: 1px solid ${palette.darkMedium2};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.grayMedium9};
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border: none;
    color: ${palette.whitePrimary};
    background-color: ${palette.grayPrimary} !important;
    cursor: not-allowed;
    @media (hover: hover) {
      &:hover {
        color: ${palette.whitePrimary};
        background-color: ${palette.grayPrimary};
      }
    }
  }
`;
