import { HearingItem } from 'types/HearingSet';
import { ListMeta } from 'types/common';

export const defaulHearingDetail: HearingItem = {
  id: 0,
  status: 'draft',
  title: '',
  organization_id: '',
  data_files: [],
};

export const defaultListMeta: ListMeta = {
  page: 1,
  total: undefined,
  order: 'updated_at',
  sort: 'latest',
};
