import React, { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentCLick } from 'hooks';
import SortItem from './SortItem';

import SVGIcon from 'views/components/primitive/SVGIcon';
import SortIcon from 'assets/images/ic_sort.svg';
import { sortOrders } from './constants';
import { SortButtonWrapper } from './style';
import { ListMeta } from 'types/common';

interface PropTypes {
  notSortTitle?: boolean;
  sortType?: string;
  handleSort?(params: Partial<ListMeta>): void;
}

const SortButton: FC<PropTypes> = ({ notSortTitle, sortType, handleSort }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  useDocumentCLick(handleClick);

  function handleClick(event: MouseEvent | any): void {
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpen();
      }
    }
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function getSortList() {
    if (notSortTitle) {
      return sortOrders.filter(it => it.id < 3);
    }
    if (sortType) {
      return sortOrders.map(it => {
        if (it.order === 'title') return { ...it, order: sortType };
        return it;
      });
    }
    return sortOrders;
  }

  return (
    <SortButtonWrapper ref={container} onClick={toggleOpen} className="sort-button" isOpen={isOpen}>
      {t('common.sort')}
      <SVGIcon src={SortIcon} />
      <div className="sort-button-dropdown">
        {getSortList().map(item => (
          <SortItem
            key={item.id}
            label={t(item.label)}
            sortType={item.sort}
            orderType={item.order}
            handleSort={handleSort}
          />
        ))}
      </div>
    </SortButtonWrapper>
  );
};

export default SortButton;
