import { observable, action } from 'mobx';
import i18n from 'i18n';
import { fromJS } from 'immutable';
import { Appointment, AppointmentMeta, CreateQuickHearingData } from 'types/Appointment';
import { QuestionAnswer } from 'types/HearingSet';
import { ErrorTypes } from 'types/common';
import { MessageProps } from 'types/App';
import { QuickHearingItem } from 'types/QuickHearing';
import { RootStore } from '../index';
import history from 'utility/history';
import { routes } from 'utility/constants';
import { trimSpace } from 'utility/helpers';
import { defaultAppointment, defaultAppointmentMeta } from './constants';

import AppointmentApi from 'services/AppointmentApi';
import HearingSetApi from 'services/HearingSetApi';

class AppointmentStore {
  private readonly appointmentApi: AppointmentApi;
  private readonly hearingSetApi: HearingSetApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public listQuickHearingId: string[] = [];
  @observable public errors: ErrorTypes = {};
  @observable public isConfirmModal = false;
  @observable public appointmentList: Appointment[] = [];
  @observable public appointmentMeta: AppointmentMeta = { ...defaultAppointmentMeta };
  @observable public selectedAppointment: number[] = [];
  @observable public quickHearingAdd: QuickHearingItem[] = [];
  @observable public appointmentDetail: Appointment = { ...defaultAppointment };

  constructor({
    rootStore,
    appointmentApi,
    hearingSetApi,
  }: {
    rootStore: RootStore;
    appointmentApi: AppointmentApi;
    hearingSetApi: HearingSetApi;
  }) {
    this.rootStore = rootStore;
    this.appointmentApi = appointmentApi;
    this.hearingSetApi = hearingSetApi;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleConfirmModal() {
    this.isConfirmModal = !this.isConfirmModal;
  }

  // GET LIST APPOINTMENT
  @action.bound
  public async getAppointmentList(currentPage = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;

    try {
      const {
        data: { appointments, total, page },
      } = await this.appointmentApi.getAppointments({ currentPage, sort, order });
      const {
        appStore: {
          userData: { id: userId },
        },
      } = this.rootStore;
      const appointmentList = appointments.map((it: Appointment) => {
        const isDelete = !!it.user_id && it.user_id.toString() === userId.toString();
        return { ...it, can_delete: isDelete };
      });
      this.appointmentList = appointmentList;
      this.appointmentMeta = {
        ...this.appointmentMeta,
        currentPage: page,
        total,
      };
      this.errors = {};
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // HANDLE CHANGE SELECTED APPOINTMENT
  @action.bound
  public handleSelectedAppointment(selected: number[]) {
    this.selectedAppointment = [...selected];
  }

  // DELETE APPOINTMENT
  @action.bound
  public async deleteAppointment(id?: number) {
    this.isLoading = true;
    try {
      let listIds: number[] = [];
      if (!id) {
        listIds = this.selectedAppointment;
      } else {
        listIds = [id];
      }
      await this.appointmentApi.deleteAppointments(listIds);
      this.selectedAppointment = [];
      this.appointmentList = this.appointmentList.filter(it => !listIds.includes(it.id));
      this.putFlashMessages({
        content: i18n.t('appointmentDetail.deleteSuccess'),
        status: 'success',
      });
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // GET APPOINTMENT FROM QUICK HEARINGS
  @action.bound
  public async getQuickHearingData(listId: string[]) {
    this.isLoading = true;
    try {
      const { data } = await this.hearingSetApi.getQuickHearingDetails(listId);
      const {
        product_videos,
        hearing_sets,
        company_names,
        company_pic_names,
        memos,
        datetimes,
      } = data;

      if (listId.length !== datetimes.length) {
        history.push(routes.quickHearing);
        this.putFlashMessages({
          content: i18n.t('quickHearing.notFoundQuickHearings'),
          status: 'error',
        });
      }

      this.appointmentDetail = {
        ...this.appointmentDetail,
        company_name: company_names[0] || '',
        company_pic_name: company_pic_names[0] || '',
        quick_hearing_times: datetimes,
        datetime: datetimes[0] || '',
        memo: memos.join('\n'),
        hearing_sets: hearing_sets,
        product_videos: product_videos,
        external_client_id: '',
      };
      this.errors = {};
      this.listQuickHearingId = listId;
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // HANDLE CHANGE ADD APPOINTMENT
  @action.bound
  public handleChangeMeta(data: object) {
    this.appointmentDetail = { ...this.appointmentDetail, ...data };
  }

  // ADD APPOINTMENT
  @action.bound
  public async dispatchAddAppointment() {
    this.isLoading = true;
    try {
      const {
        company_name,
        company_pic_name,
        datetime,
        memo,
        external_client_id,
      } = this.appointmentDetail;
      const data: CreateQuickHearingData = {
        company_pic_name: trimSpace(company_pic_name),
        company_name: trimSpace(company_name),
        datetime,
        quick_hearing_ids: this.listQuickHearingId.slice(),
        memo: trimSpace(memo),
        external_client_id: external_client_id || '',
      };
      await this.appointmentApi.createAppointment(data);
      history.push(routes.appointmentList);
      this.putFlashMessages({
        content: i18n.t('createAppointment.messages.createAppointmentSuccess'),
        status: 'success',
      });
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
      this.resetSelectedQuickHearings();
    }
  }

  // HANDLE ERROR APPOINTMENT
  @action.bound
  public handleErrorAppointment(errors: object) {
    this.errors = { ...errors };
  }

  // GET APPOINTMENT DETAIL
  @action.bound
  public async getAppointmentDetail(id: string) {
    this.isLoading = true;
    try {
      const { data } = await this.appointmentApi.getAppointmentById(id);
      const {
        appStore: {
          userData: { id: userId },
        },
      } = this.rootStore;
      const isDelete = !!data.user_id && data.user_id.toString() === userId.toString();
      this.appointmentDetail = { ...data, can_delete: isDelete };
    } catch (error) {
      history.push(routes.appointmentList);
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // UPDATE APPOINTMENT DETAIL
  @action.bound
  public async dispatchUpdateAppointment(id: string) {
    this.isLoading = true;
    try {
      await this.appointmentApi.updateAppointment({
        appointment: this.appointmentDetail,
        id,
      });
      this.getAppointmentDetail(id);
      this.putFlashMessages({
        content: i18n.t('appointmentDetail.updateSuccess'),
        status: 'success',
      });
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetAppointmentMeta() {
    this.appointmentDetail = { ...defaultAppointment };
    this.errors = {};
  }

  @action.bound
  public resetSelectedQuickHearings() {
    this.listQuickHearingId = [];
  }

  @action.bound
  public handleChangeNote(value: string, index: number): void {
    const hearingIndex = this.appointmentDetail.activeHearingIndex || 0;

    const detailJS = fromJS(this.appointmentDetail);
    this.appointmentDetail = detailJS
      .setIn(
        ['hearing_sets', hearingIndex, 'data_files', index, 'question', 'question_answer', 'note'],
        value
      )
      .setIn(['hearing_sets', hearingIndex, 'isEditing'], true)
      .toJS();
  }

  @action.bound
  public async updateQuestionAnswers(): Promise<void> {
    const hearingIndex = this.appointmentDetail.activeHearingIndex || 0;
    const questionAnswers = this.appointmentDetail.hearing_sets[hearingIndex].data_files
      .map(data_file => data_file.question.question_answer)
      .filter((q): q is QuestionAnswer => q !== null);
    try {
      await this.appointmentApi.updateQuestionAnswers(questionAnswers);

      const detailJS = fromJS(this.appointmentDetail);
      this.appointmentDetail = detailJS
        .setIn(['hearing_sets', hearingIndex, 'isEditing'], false)
        .toJS();

      this.putFlashMessages({
        content: i18n.t('appointmentDetail.updateSuccess'),
        status: 'success',
      });
    } catch (error) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    }
  }
}

export default AppointmentStore;
