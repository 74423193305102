import React from 'react';
import { usePushLocationSearch, useLocationSearch } from 'hooks';
import SVGIcon from 'views/components/primitive/SVGIcon';
import SwapIcon from 'assets/images/ic_swap.svg';
import { ListMeta } from 'types/common';

interface PropTypes {
  label: string;
  sortType: string;
  orderType: string;
  handleSort?(params: Partial<ListMeta>): void;
}

const SortItem: React.FC<PropTypes> = ({ label, sortType, orderType, handleSort }) => {
  const push = usePushLocationSearch();
  const { sort, order } = useLocationSearch();

  function handleClick() {
    push({ page: 1, sort: sortType, order: orderType });
    handleSort && handleSort({ sort: sortType, order: orderType });
  }

  const isActive = sort === sortType && order === orderType;

  return (
    <div onClick={handleClick} className={`sort-item ${isActive ? 'active' : ''}`}>
      <SVGIcon src={SwapIcon} />
      {label}
    </div>
  );
};

export default SortItem;
