import styled from '@emotion/styled';
import { palette, layer } from 'entity/createTheme';
import React, { FC, useRef, useState } from 'react';
import { SVGIcon } from 'views/components/primitive';
import DownloadSVGIcon from 'assets/images/ic_download.svg';
import { useTranslation } from 'react-i18next';
import { useDocumentCLick } from 'hooks';

interface Props {
  downloadUTF8(): void;
  downloadShiftJIS(): void;
  className?: string;
}

const CSVDownloadButton: FC<Props> = ({ downloadUTF8, downloadShiftJIS, className }) => {
  const [showOptions, setShowOptions] = useState(false);

  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);

  const handleClick = (event: MouseEvent): void => {
    if (container.current) {
      if (
        event.target instanceof Element &&
        !container.current.contains(event.target) &&
        showOptions
      ) {
        showDownloadOptions();
      }
    }
  };

  const showDownloadOptions = () => {
    setShowOptions(!showOptions);
  };

  useDocumentCLick(handleClick);

  return (
    <Root ref={container} onClick={showDownloadOptions} isOpen={showOptions} className={className}>
      <DownloadIcon src={DownloadSVGIcon} />
      {t('common.csvDownload')}
      <DownloadOption isOpen={showOptions}>
        <OptionItem onClick={downloadUTF8}>{t('common.csvDownloadOption.utf8')}</OptionItem>
        <OptionItem onClick={downloadShiftJIS}>{t('common.csvDownloadOption.shiftjis')}</OptionItem>
      </DownloadOption>
    </Root>
  );
};

export default CSVDownloadButton;

const DownloadIcon = styled(SVGIcon)({
  marginRight: 10,
});

const Root = styled('div')<{ isOpen: boolean }>(props => ({
  width: 'auto',
  padding: '0 20px',
  background: palette.whitePrimary,
  border: `1px solid ${palette.grayMedium1}`,
  borderRadius: props.isOpen ? '6px 6px 0 0' : 6,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  position: 'relative',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const DownloadOption = styled('div')<{ isOpen: boolean }>(props => ({
  display: props.isOpen ? 'block' : 'none',
  background: palette.whitePrimary,
  position: 'absolute',
  zIndex: layer.bottomAbove1,
  top: 40,
  width: 'calc(100% + 2px)',
  border: `1px solid ${palette.grayMedium1}`,
  borderRadius: '0 0 6px 6px',
  overflow: 'hidden',
}));

const OptionItem = styled('div')({
  paddingLeft: 15,
  height: 46,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'normal',
  '&:hover': {
    background: palette.grayMedium9,
  },
});
