import React, { FC } from 'react';
import { SVGIcon } from 'views/components/primitive';
import NoImage from 'assets/images/ic_no_image.svg';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  size?: number;
  svgSize?: number;
  backgroundColor?: string;
  className?: string;
};

type RootProps = {
  size?: number;
  svgSize?: number;
  backgroundColor?: string;
};

const NoImageIcon: FC<Props> = ({ size, svgSize, backgroundColor, className }) => {
  return (
    <Root size={size} svgSize={svgSize} className={className} backgroundColor={backgroundColor}>
      <SVGIcon src={NoImage} />
    </Root>
  );
};

const Root = styled('div')<RootProps>(({ size, svgSize, backgroundColor }) => ({
  display: 'flex',
  height: size ? size : 48,
  width: size ? size : 48,
  background: backgroundColor ? backgroundColor : palette.grayMedium6,
  justifyContent: 'center',
  alignItems: 'center',

  svg: {
    height: svgSize ? svgSize : 16,
    width: svgSize ? svgSize : 16,
  },
}));

export default NoImageIcon;
