import { observable, action } from 'mobx';
import { QuickHearingItem } from 'types/QuickHearing';
import { MessageProps } from 'types/App';
import { RootStore } from '../index';
import { PaginationMeta } from 'types/common';

import HearingSetApi from 'services/HearingSetApi';

class QuickHearingStore {
  private readonly hearingSetApi: HearingSetApi;
  @observable public rootStore: RootStore;
  @observable public loading = false;
  @observable public quickHearingSortType = '';
  @observable public quickHearingList: QuickHearingItem[] = [];
  @observable public quickHearingMeta: PaginationMeta = { currentPage: 1, totalItem: 0 };
  @observable public hearingSelected: number[] = [];

  constructor({
    rootStore,
    hearingSetApi,
  }: {
    rootStore: RootStore;
    hearingSetApi: HearingSetApi;
  }) {
    this.rootStore = rootStore;
    this.hearingSetApi = hearingSetApi;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectQuickHearingItem(data: number[]): void {
    this.hearingSelected = [...data];
  }

  @action.bound
  public async getQuickHearingList(page = 1, sort = 'desc', order = 'updated_at') {
    this.loading = true;
    this.quickHearingSortType = sort;
    this.quickHearingMeta = { ...this.quickHearingMeta, currentPage: page };
    try {
      const { data } = await this.hearingSetApi.getQuickHearings({
        page,
        sort,
        order,
        per_page: 20,
      });
      this.quickHearingList = data.quick_hearings;
      this.quickHearingMeta = { ...this.quickHearingMeta, totalItem: data.total };
    } catch (error) {
      this.putFlashMessages({ content: error.message, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetSelectedQuickHearings() {
    this.hearingSelected = [];
  }
}

export default QuickHearingStore;
