import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import SVGIcon from 'views/components/primitive/SVGIcon';
import { routes } from 'utility/constants';

interface PropsTypes {
  label: string;
  activeUrl: string;
  iconUrl: string;
  isDisable: boolean;
  handleClickNavLink: () => void;
  setRouteChanged(val: boolean): void;
}

const NavigationLink: FC<PropsTypes> = ({
  activeUrl,
  label,
  iconUrl,
  handleClickNavLink,
  isDisable,
  setRouteChanged,
}) => {
  function handleClick(): void {
    handleClickNavLink();
    setRouteChanged(true);
  }

  function handleActiveLink(match: any, location: any) {
    const { pathname } = location;
    const isProductActive = pathname.includes(routes.startAppointment);
    if (isProductActive) {
      return routes.productVideo.includes(activeUrl);
    }
    return pathname.includes(activeUrl) && pathname.split('/')[1] === activeUrl.split('/')[1];
  }

  return (
    <div onClick={handleClick}>
      <NavLink
        to={activeUrl}
        className={isDisable ? 'disabled-link' : ''}
        isActive={handleActiveLink}
      >
        <SVGIcon src={iconUrl} />
        {label}
      </NavLink>
    </div>
  );
};

export default NavigationLink;
