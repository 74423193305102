import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { palette } from 'entity/createTheme';
import { SVGIcon } from 'views/components/primitive';

type Props = {
  menuName: string;
  icon: string;
  settingPageRoute: string;
};

const SettingMenuItem: FC<Props> = ({ menuName, icon, settingPageRoute }) => {
  const history = useHistory();

  const goToSettingDetail = () => {
    history.push(settingPageRoute);
  };

  return (
    <Root onClick={goToSettingDetail}>
      <Content>
        <MenuIcon src={icon} />
        <MenuName>{menuName}</MenuName>
      </Content>
    </Root>
  );
};

export default SettingMenuItem;

const Root = styled('div')({
  padding: '45px 35px',
  background: palette.whitePrimary,
  width: 170,
  height: 170,
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  '&:hover': {
    background: palette.grayBlur,
  },
});

const Content = styled('div')({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
});

const MenuIcon = styled(SVGIcon)({});

const MenuName = styled('p')({
  color: palette.darkMedium6,
  fontWeight: 'bold',
  fontSize: 14,
});
