import { AxiosTransformer } from 'axios';
import { ProductItem } from '../../types/Product';
import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';
class ProductAdminApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getAdminProductList = async ({ page, sort, order }: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{ product_videos: ProductItem[]; total?: number }>({
      url: 'admin/product_videos',
      method: 'GET',
      params: {
        page,
        order,
        sort,
        per_page: 12,
      },
    });
    return res;
  };

  public changeProductStatus = async ({
    id,
    status,
    confirm,
  }: {
    id: number;
    status: string;
    confirm?: boolean;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/product_videos/update_status',
      data: {
        ids: [id],
        status,
        confirm,
      },
    });
  };

  public getProductById = async ({ id }: { id: number }) => {
    //FIX ME: add response type
    const res = await this.httpClient.request({
      url: `admin/product_videos/${id}`,
      method: 'GET',
    });

    return res;
  };

  public archiveProduct = async ({ listId }: { listId: number[] }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/product_videos/update_status',
      data: {
        ids: listId,
        status: 'archived',
      },
    });
  };

  public addProduct = async ({
    data,
    onUploadProgress,
    transformResponse,
  }: {
    data: FormData;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse: AxiosTransformer | AxiosTransformer[];
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/product_videos',
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onUploadProgress,
      transformResponse: transformResponse,
    });
  };

  public updateProduct = async ({
    id,
    data,
    onUploadProgress,
    transformResponse,
  }: {
    id: string;
    data: FormData;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse: AxiosTransformer | AxiosTransformer[];
  }) => {
    await this.httpClient.request({
      method: 'PUT',
      url: `admin/product_videos/${id}`,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onUploadProgress,
      transformResponse: transformResponse,
    });
  };
}

export default ProductAdminApi;
