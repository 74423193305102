import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

import UserInfo from './UserInfo';
import Organizations from './Organizations';
import SVGIcon from 'views/components/primitive/SVGIcon';

import { HeaderWrapper } from './styles';
import DefaultAvatar from 'assets/images/ic_default_avatar.svg';
import HumburgerIcon from 'assets/images/ic_humburger.svg';
interface PropTypes {
  isOpen: boolean;
  toggleOpen: () => void;
}

const HearingHeader: FC<PropTypes> = ({ toggleOpen }) => {
  const {
    appStore: { userData, handleLogout, handleChangeOrganization, organizationId },
  } = useStore();

  return (
    <HeaderWrapper className="header">
      <span className="toggle-sidebar">
        <SVGIcon onClick={toggleOpen} src={HumburgerIcon} />
      </span>
      <div className="header_info">
        <Organizations
          value={organizationId}
          handleChangeOrganization={handleChangeOrganization}
          organizations={userData.organizations}
        />
        <UserInfo
          userName={userData.username || 'Default'}
          handleLogout={handleLogout}
          avatarUrl={userData.profile_image_url || DefaultAvatar}
        />
      </div>
    </HeaderWrapper>
  );
};

export default observer(HearingHeader);
