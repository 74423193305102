import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useStore, useLocationSearch } from './index';

function usePushOrganizationId() {
  const {
    appStore: { organizationId },
  } = useStore();
  const History = useHistory();
  const locationSearch = useLocationSearch();

  useEffect(() => {
    if (locationSearch.organization !== organizationId) {
      History.replace({
        search: qs.stringify(
          { ...locationSearch, organization: organizationId },
          { encode: false, indices: false }
        ),
      });
    }
  });

  return null;
}

export default usePushOrganizationId;
