import { HearingLink } from 'types/HearingLink';
import { ListMeta } from 'types/common';

export const defaultHearingLink: HearingLink = {
  client_id: null,
  client_name: null,
  is_required_email: false,
  needs_feedback: true,
  cta_redirect: false,
  cta_redirect_to: null,
  created_at: '',
  updated_at: '',
  status: '',
  hearing_set: {
    id: 0,
    title: '',
    organization_id: '',
    data_files: [],
  },
  hearing_set_id: -1,
  product_videos: [],
  product_video_ids: [],
  results: [],
};

export const defaultListMeta: ListMeta = {
  total: undefined,
  page: 1,
  sort: 'desc',
  order: 'updated_at',
};
