import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import IconTrash from 'assets/images/ic_trash.svg';
import { Button, SVGIcon } from 'views/components/primitive';

const Buttons = styled(Button)`
  width: 50px;
  border: none !important;
  svg > g > g {
    fill: ${palette.redPrimary};
  }
  &.default {
    background: ${palette.redBlur};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.redMedium1};
        svg > g > g {
          fill: ${palette.redPrimary};
        }
      }
    }
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  &.not_bg {
    background-color: ${palette.whitePrimary};
    &:hover {
      background-color: ${palette.whitePrimary};
      svg > g > g {
        fill: ${palette.redPrimary};
      }
    }
  }
`;

interface PropTypes {
  onClick: () => void;
  isBackground?: boolean;
  disabled?: boolean;
  size: 'md' | 'sm';
}

const DeleteButton: FC<PropTypes> = props => {
  return (
    <Buttons
      type="default"
      className={`delete-btn ${props.isBackground && 'not_bg'}`}
      label={<SVGIcon src={IconTrash} />}
      {...props}
    />
  );
};

export default DeleteButton;
