import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

const SectionCard: FC = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled('div')({
  padding: 20,
  background: palette.whitePrimary,
});

export default SectionCard;
