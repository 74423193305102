import React, { MouseEventHandler, MouseEvent, FC } from 'react';
import { style } from './style';

interface PropTypes {
  onClick?: () => void;
  label: React.ReactNode;
  disabled?: boolean;
  size?: 'md' | 'sm' | 'xs';
  type?: 'default' | 'primary' | 'secondary' | 'outline';
  id?: string;
  className?: string;
}

const ButtonComponent: FC<PropTypes> = ({
  onClick,
  label,
  disabled,
  size,
  type,
  id,
  className,
}) => {
  const handleClick: MouseEventHandler = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick();
  };

  const classNames = `${className} ${size} ${type}`;

  return (
    <button className={classNames} css={style} disabled={disabled} onClick={handleClick} id={id}>
      {label}
    </button>
  );
};

export default ButtonComponent;
