import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { palette, bp, layer } from 'entity/createTheme';
import UploadThumbnail from './UploadThumbnail';
import { InputReplaceFile } from 'views/components/compound';
import { UploadVideoMeta } from 'types/App';
import { acceptVideo } from 'utility/constants';
import { getImgWithStatus } from 'utility/helpers';
import { MessageProps } from 'types/App';
import { useTranslation } from 'react-i18next';

interface PropTypes {
  meta: UploadVideoMeta;
  firstFrameVideo: string;
  onChangeFileVideo: (file: object) => void;
  handleChangeMeta: (data: UploadVideoMeta) => void;
  editable: boolean;
  componentBottom?: React.ReactElement;
  setActiveThumbnail: (data: string) => void;
  activeSrc: string;
  status: string;
  hasError: boolean;
  handleFlashMessage(data: MessageProps): void;
}

const UploadMeta: FC<PropTypes> = ({
  firstFrameVideo,
  onChangeFileVideo,
  handleChangeMeta,
  editable,
  setActiveThumbnail,
  activeSrc,
  status,
  hasError,
  handleFlashMessage,
  meta,
}) => {
  const { t } = useTranslation();
  const { thumbnailSrcConvert, fileThumbnail, file } = useMemo(() => meta, [meta]);
  const isNotUploaded = useMemo(() => status === 'not_uploaded', [status]);
  const isNotReady = isNotUploaded;
  const [isDropzone, setIsDropzone] = useState(true);

  useEffect(() => {
    if (!editable) {
      setIsDropzone(true);
    }
  }, [editable]);

  function handleChangeShowImage(data: string) {
    if (editable) {
      setActiveThumbnail(data);
      const { fileThumbnailConst } = meta;
      const isBlob = data.split(':')[0] === 'blob';
      if (isBlob && fileThumbnailConst) {
        handleChangeMeta({ ...meta, fileThumbnail: fileThumbnailConst });
      } else {
        if (!editable) {
          handleChangeMeta({ ...meta, fileThumbnail: '' });
        } else {
          handleChangeMeta({ ...meta, fileThumbnail: data });
        }
      }
    }
  }

  function getSrcImageThumbnail() {
    const listImages = [firstFrameVideo, thumbnailSrcConvert];
    return listImages.find(it => it === activeSrc) || listImages[0];
  }

  function handleReplaceVideo(file: any) {
    setActiveThumbnail(firstFrameVideo);
    onChangeFileVideo(file);
    setIsDropzone(true);
  }

  return (
    <UploadContent className="thumbnail_content">
      <ThumbnailContainer>
        <img
          src={getImgWithStatus(status, getSrcImageThumbnail())}
          alt="thumbnail_video"
          className={isNotReady || !fileThumbnail || status === 'upload_failed' ? 'not-ready' : ''}
        />
        {editable && !isNotReady && (
          <InputReplaceFile
            id="replace_video"
            accept={acceptVideo.join(', ')}
            className="video-replace"
            handleChange={handleReplaceVideo}
          />
        )}
      </ThumbnailContainer>
      <UploadedFileContainer>
        {meta.filename !== '' ? (
          <>
            <FileNameLabel>{t('admin.common.filename')}</FileNameLabel>
            <FileName>{meta.filename}</FileName>
          </>
        ) : (
          <EmptyFileName />
        )}

        <div>
          <UploadThumbnail
            editable={editable}
            activeSrc={activeSrc}
            handleChangeShowImg={handleChangeShowImage}
            handleChangeMeta={handleChangeMeta}
            setIsDropzone={setIsDropzone}
            isDropzone={isDropzone}
            setActiveThumbnail={setActiveThumbnail}
            isNotUploaded={isNotUploaded}
            firstFrameVideo={firstFrameVideo}
            thumbnailSrcConvert={thumbnailSrcConvert}
            status={status}
            hasError={hasError}
            onChangeFileVideo={onChangeFileVideo}
            fileVideo={file}
            handleFlashMessage={handleFlashMessage}
            meta={meta}
          />
        </div>
      </UploadedFileContainer>
    </UploadContent>
  );
};

const FileNameLabel = styled('div')({
  height: 18,
  color: palette.grayMedium4,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
});

const FileName = styled('div')({
  margin: '10px 0',
  height: 40,
  width: '100%',
  color: palette.darkMedium8,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: 1.5,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-all',
});

const EmptyFileName = styled('div')({
  height: 76,
});

const UploadedFileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const ThumbnailContainer = styled('div')({
  maxWidth: '40%',
  minWidth: 356,
  height: 200,
  marginRight: 20,
  borderRadius: 6,
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  background: palette.blackPrimary,

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    '&.not-ready': {
      objectFit: 'cover',
    },
  },

  '.video-replace': {
    position: 'absolute',
    top: 10,
    right: 10,

    // Might not need to specify z-index
    zIndex: layer.bottomAbove1,
  },
});

const UploadContent = styled('div')({
  display: 'flex',
});

export default UploadMeta;
