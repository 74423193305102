import HearingStore from './HearingStore';
import QuickHearingStore from './QuickHearingStore';
import AppointmentStore from './AppointmentStore';
import ProductStore from './ProductStore';
import AppStore from './AppStore';
import HearingLinkStore from './HearingLinkStore';
//Admin
import ProductAdminStore from './Admin/ProductAdminStore';
import ArchiveStore from './Admin/ArchiveStore';
import InsideSalePlayStore from './InsideSalePlayStore';
import SettingLogoStore from './Admin/SettingLogoStore';
import HearingListStore from './Admin/HearingListStore';
import HearingDetailStore from './Admin/HearingDetailStore';

// Services
import HttpClient from 'adapter/HTTPClient';
import AuthenticationApi from 'services/AuthenticationApi';
import UserApi from 'services/UserApi';
import OrganizationApi from 'services/OrganizationApi';
import AppointmentApi from 'services/AppointmentApi';
import HearingSetApi from 'services/HearingSetApi';
import ProductVideoApi from 'services/ProductVideoApi';
import HearingLinkApi from 'services/HearingLinkApi';

import QuestionApi from 'services/Admin/QuestionApi';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import ProductAdminApi from 'services/Admin/ProductAdminApi';
import ArchiveAdminApi from 'services/Admin/ArchiveAdminApi';
import SettingApi from 'services/Admin/SettingApi';

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const KAIZEN_API_URL: string | undefined = process.env.REACT_APP_KAIZEN_API_URL;

const authApiHttpClient = new HttpClient({ baseURL: KAIZEN_API_URL });

const httpClient = new HttpClient({ baseURL: API_URL });

const userApi = new UserApi({
  httpClient,
});
const organizationApi = new OrganizationApi({
  httpClient,
});
const authenticationApi = new AuthenticationApi({ httpClient: authApiHttpClient });

const appointmentApi = new AppointmentApi({
  httpClient,
});
const hearingSetApi = new HearingSetApi({
  httpClient,
});
const productVideoApi = new ProductVideoApi({
  httpClient,
});
const hearingLinkApi = new HearingLinkApi({
  httpClient,
});

const questionApi = new QuestionApi({ httpClient });
const hearingAdminApi = new HearingAdminApi({ httpClient });
const productAdminApi = new ProductAdminApi({ httpClient });
const archiveAdminApi = new ArchiveAdminApi({ httpClient });
const settingApi = new SettingApi({ httpClient });

export class RootStore {
  public hearingStore: HearingStore;
  public quickHearingStore: QuickHearingStore;
  public appointmentStore: AppointmentStore;
  public productStore: ProductStore;
  public appStore: AppStore;
  public hearingLinkStore: HearingLinkStore;
  //Admin
  public productAdminStore: ProductAdminStore;
  public archiveStore: ArchiveStore;
  public insideSalePlayStore: InsideSalePlayStore;
  public settingLogoStore: SettingLogoStore;

  public adminHearingListStore: HearingListStore;
  public hearingDetailStore: HearingDetailStore;

  constructor() {
    this.appStore = new AppStore({ rootStore: this, userApi, organizationApi, authenticationApi });
    this.hearingStore = new HearingStore({ rootStore: this, hearingSetApi, appointmentApi });
    this.quickHearingStore = new QuickHearingStore({
      rootStore: this,
      hearingSetApi,
    });
    this.appointmentStore = new AppointmentStore({
      rootStore: this,
      appointmentApi,
      hearingSetApi,
    });
    this.hearingLinkStore = new HearingLinkStore({
      rootStore: this,
      hearingSetApi,
      productVideoApi,
      hearingLinkApi,
    });
    this.insideSalePlayStore = new InsideSalePlayStore({ rootStore: this, hearingLinkApi });
    this.productStore = new ProductStore({ rootStore: this, productVideoApi });

    // Admin
    this.productAdminStore = new ProductAdminStore({ rootStore: this, productAdminApi });
    this.archiveStore = new ArchiveStore({ rootStore: this, archiveAdminApi, hearingLinkApi });
    this.settingLogoStore = new SettingLogoStore({ rootStore: this, settingApi });
    this.adminHearingListStore = new HearingListStore({ rootStore: this, hearingAdminApi });
    this.hearingDetailStore = new HearingDetailStore({
      rootStore: this,
      hearingAdminApi,
      questionApi,
    });
  }
}

const store: RootStore = new RootStore();

export default store;
