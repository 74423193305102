import { observable, action } from 'mobx';
import { RootStore } from '../index';
import ArchiveAdminApi from 'services/Admin/ArchiveAdminApi';
import HearingLinkApi from 'services/HearingLinkApi';
import { ProductItem } from 'types/Product';
import { HearingItem } from 'types/HearingSet';
import { ArchiveMeta } from 'types/Archive';
import { MessageProps } from 'types/App';
import { optionsArchive, TabOption } from 'utility/constants';
import { HearingLinkItem } from 'types/HearingLink';
import i18n from 'i18n';

class ArchiveStore {
  private readonly archiveAdminApi: ArchiveAdminApi;
  private readonly hearingLinkApi: HearingLinkApi;
  @observable public loading = false;
  @observable public errors = {};
  @observable public rootStore: RootStore;
  @observable public isSelectRestore = false;
  @observable public listHearingArchive: HearingItem[] = [];
  @observable public listProductArchive: ProductItem[] = [];
  @observable public listSelected: number[] = [];
  @observable public archiveMeta: ArchiveMeta = {
    panel: 'products',
    order: 'updated_at',
    sort: 'latest',
    pageHearing: 1,
    pageProduct: 1,
    pageLink: 1,
  };
  @observable public optionsArchive: TabOption[] = [...optionsArchive];
  @observable public listLinkArchive: HearingLinkItem[] = [];

  constructor({
    rootStore,
    archiveAdminApi,
    hearingLinkApi,
  }: {
    rootStore: RootStore;
    archiveAdminApi: ArchiveAdminApi;
    hearingLinkApi: HearingLinkApi;
  }) {
    this.rootStore = rootStore;
    this.archiveAdminApi = archiveAdminApi;
    this.hearingLinkApi = hearingLinkApi;
  }

  @action.bound
  public pushFlashMessage(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectRestore() {
    if (this.isSelectRestore) {
      this.listSelected = [];
    }
    this.isSelectRestore = !this.isSelectRestore;
  }

  @action.bound
  public async getListArchive(panel = 'products', sort = 'desc', order = 'updated_at', page = 1) {
    this.loading = true;
    try {
      const [data1, data2] = await Promise.all([
        this.archiveAdminApi.getArchiveList({ panel, sort, order, page }),
        this.hearingLinkApi.getArchivedHearingLinks({
          page,
          sort,
          order: 'updated_at',
          per_page: 20,
        }),
      ]);
      const {
        product_videos,
        hearing_sets,
        total_product_vides,
        total_hearing_sets,
        hearing_sets_page,
        product_videos_page,
      } = data1.data;
      const { hearing_links, page: pageLink, total: totalLink } = data2.data;
      this.listProductArchive = product_videos;
      this.listHearingArchive = hearing_sets;
      this.listLinkArchive = hearing_links;
      this.archiveMeta = {
        ...this.archiveMeta,
        panel,
        sort,
        order,
        pageHearing: hearing_sets_page,
        pageProduct: product_videos_page,
        pageLink: pageLink,
      };
      this.optionsArchive = [
        {
          ...this.optionsArchive[0],
          count: total_product_vides,
        },
        {
          ...this.optionsArchive[1],
          count: total_hearing_sets,
        },
        {
          ...this.optionsArchive[2],
          count: totalLink,
        },
      ];
    } catch (error) {
      this.errors = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public handleChangeSelected(listId: number[]) {
    this.listSelected = listId;
  }

  @action.bound
  public async handleRestoreData(id?: number) {
    try {
      const { panel, sort, order, pageHearing, pageProduct, pageLink } = this.archiveMeta;
      const listId = id ? [id] : [...this.listSelected];
      const field = panel === 'products' ? 'product_video_ids' : 'hearing_set_ids';
      this.loading = true;
      if (panel !== 'links') {
        await this.archiveAdminApi.restoreData({ field, listId });
        this.getListArchive(panel, sort, order, panel === 'products' ? pageProduct : pageHearing);
      } else {
        await this.hearingLinkApi.updateHearingLinkStatus({ ids: listId, status: 'inactive' });
        this.getListArchive(panel, sort, order, pageLink);
      }
      this.pushFlashMessage({
        content: i18n.t('admin.common.messages.restoreSuccess', {
          item: i18n.t(`common.actionTarget.${panel}`),
        }),
        status: 'success',
      });
      if (this.isSelectRestore) {
        this.toggleSelectRestore();
        this.listSelected = [];
      }
    } catch (error) {
      this.errors = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetSelectMeta() {
    this.isSelectRestore = false;
    this.listSelected = [];
  }
}

export default ArchiveStore;
