import styled from '@emotion/styled';
import { palette, sizes, bp, layer } from 'entity/createTheme';

const SidebarWrapper = styled.div`
  display: flex;
  z-index: ${layer.topUnder2};
  flex-direction: column;

  .sidebar_logo {
    height: ${sizes.headerHeightLg};
    background: ${palette.whitePrimary};
    box-shadow: 3px 0 3px ${palette.grayMedium1};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    @media ${bp.sm} {
      height: ${sizes.headerHeightSm};
      padding: 16px;
    }
  }

  .sidebar_menubar {
    position: relative;
    flex: 1;
    padding-top: 21px;
    background-color: ${palette.darkMedium1};
    a,
    h3,
    .disable-link {
      color: ${palette.grayMedium2};
      font-size: 1rem;
      line-height: 1.1875rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 9px 18px;
      margin-bottom: 13px;
      border-left: 2px solid rgba(0, 0, 0, 0);

      svg {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
    }

    a.disabled-link {
      pointer-events: none;
    }

    a:hover,
    a.active {
      color: ${palette.whitePrimary};
      svg {
        & > g {
          & > g {
            fill: ${palette.whitePrimary};
          }
        }
      }
    }

    a.active {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(131, 143, 165, 0) 100%);
      border-left-color: ${palette.greenSecondary};
    }

    &.disabled {
      a,
      .disable-link,
      h3 {
        opacity: 0.2;
      }
    }

    h3 {
      font-weight: normal;
      text-transform: uppercase;
      padding: 20px 18px 10px;
      margin-bottom: 0;
    }

    .language-toggle {
      position: absolute;
      left: 20px;
      bottom: 20px;

      .item {
        color: ${palette.grayMedium4};
        font-size: 0.875rem;
        cursor: pointer;
        &:nth-of-type(1) {
          margin-right: 5px;
        }
        &.active {
          color: ${palette.whitePrimary};
        }
      }
    }
  }

  .short_logo {
    display: none;
  }

  @media ${bp.sm} {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: -${sizes.sizebarWidth};
    transition: margin 0.3s ease;

    .short_logo {
      cursor: pointer;
      display: inline;
      position: fixed;
      bottom: 12px;
      left: 20px;
    }

    &.open {
      margin-left: 0;
    }
  }
`;

export { SidebarWrapper };
