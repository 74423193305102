import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

const WrapperTitle = styled('div')`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  p {
    font-size: 0.75rem;
    width: calc(100% / 3);
    color: ${palette.grayMedium4};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

interface PropTypes {
  isAdmin?: boolean;
}

const TitleList: FC<PropTypes> = ({ isAdmin }) => {
  const { t } = useTranslation();

  return (
    <WrapperTitle>
      <p>{t('common.customerTitle')}</p>
      <p>{t('common.timeTitle')}</p>
      {isAdmin && <p>{t('common.byTitle')}</p>}
    </WrapperTitle>
  );
};

export default TitleList;
