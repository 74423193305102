import { Routes } from './models';

const keyCodeESC = 27;

const routes: Routes = {
  home: '/',
  hearings: '/hearing-sets',
  hearingsPlay: '/hearing-set/play',
  startAppointment: '/hearing-set/play/intro',
  hearingsResult: '/hearing-set/play/result',
  appointmentList: '/appointments',
  appointmentDetail: '/appointments/detail',
  quickHearing: '/quick-hearing',
  addAppointment: '/quick-hearing/add',
  createAppointment: '/create-appointment',
  productVideo: '/product_videos',
  styleGuide: '/style-guide',
  hearingLink: '/hearing-links',
  hearingLinkArchive: '/archives',
  detailHearingLink: '/hearing-links/detail',
  createHearingLink: '/hearing-links/add',
  clientStartHearingLink: '/hearings',
  admin: {
    main: '/admin',
    productVideo: '/admin/product_videos',
    uploadProduct: '/admin/product_videos/upload',
    hearingSet: '/admin/hearing-sets',
    hearingDetail: '/admin/hearings/detail',
    detailProduct: '/admin/product_videos/detail',
    archive: '/admin/archives',
    setting: '/admin/settings',
    settingLogo: '/admin/settings/logo',
  },
};

interface Option {
  value: string;
  label: string;
}

export interface TabOption extends Option {
  count: number;
}

const optionsStatus: Option[] = [
  {
    value: 'draft',
    label: 'admin.common.draft',
  },
  {
    value: 'published',
    label: 'admin.common.publish',
  },
];

const optionsArchive: TabOption[] = [
  {
    value: 'products',
    label: 'sidebar.productVideo',
    count: 0,
  },
  {
    value: 'hearings',
    label: 'sidebar.hearings',
    count: 0,
  },
  {
    value: 'links',
    label: 'sidebar.hearingLink',
    count: 0,
  },
];

const optionsStatusQuestion: Option[] = [
  {
    value: '1',
    label: 'admin.common.checkbox',
  },
  {
    value: '0',
    label: 'admin.common.radio',
  },
];

const acceptVideo = ['.mp4', '.mpg', '.mpeg', '.mov', '.wmv', '.flv', '.avi'];
const videoAcceptShow = ['mp4', 'quicktime', 'qtff'];
const acceptThumbnail = ['.jpg', '.jpeg', '.png', '.gif'];
const acceptImage = [...acceptThumbnail, '.bmp'];
const acceptImageMimeTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
const acceptPDF = ['.pdf'];
const acceptPDFMimeTypes = ['pdf'];
const hearingLinkStatus = { active: 'active', inactive: 'inactive', archived: 'archived' };

const otherAnswerFixedId = 53199.5;

export {
  routes,
  keyCodeESC,
  optionsStatus,
  optionsArchive,
  optionsStatusQuestion,
  acceptVideo,
  acceptThumbnail,
  acceptImage,
  acceptImageMimeTypes,
  acceptPDF,
  acceptPDFMimeTypes,
  videoAcceptShow,
  hearingLinkStatus,
  otherAnswerFixedId,
};
