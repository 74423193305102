import { observable, action, toJS } from 'mobx';
import i18n from 'i18n';
import { MessageProps } from 'types/App';
import { ListMeta } from 'types/common';
import { RootStore } from '../../index';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import { HearingItem } from 'types/HearingSet';
import { routes } from 'utility/constants';
import history from 'utility/history';
import { defaultListMeta, defaulHearingDetail } from '../constants';

class HearingListStore {
  private readonly hearingAdminApi: HearingAdminApi;
  @observable rootStore: RootStore;
  @observable public isLoading = false;
  @observable public hasHearingLink = false;
  @observable public hearingListMeta: ListMeta = { ...defaultListMeta };
  @observable public listHearing: HearingItem[] = [];
  @observable public errors = {};
  @observable public isSelectHearing = false;
  @observable public listHearingSelected: number[] = [];
  @observable public targetHearing: { id: number; status: string } = { id: 0, status: '' };
  @observable public hearingDetail: HearingItem = { ...defaulHearingDetail };

  constructor({
    rootStore,
    hearingAdminApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public changeTargetHearing = (id: number, status: string) => {
    this.targetHearing = { ...this.targetHearing, id, status };
  };

  @action.bound
  public async getHearingList(page = 1, sort = 'latest', order = 'updated_at') {
    this.isLoading = true;

    try {
      const { data } = await this.hearingAdminApi.getAdminHearingList({ page, sort, order });
      this.hearingListMeta = { page, sort, order, total: data.total };
      this.listHearing = data.hearing_sets;
    } catch (error) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public toggleSelectHearing(): void {
    const current = toJS(this.isSelectHearing);
    if (current) {
      this.listHearingSelected = [];
    }
    this.isSelectHearing = !current;
  }

  @action.bound
  public handleSelectedHearing(listId: number[]): void {
    this.listHearingSelected = [...listId];
  }

  @action.bound
  public async createEmptyHearing() {
    this.isLoading = true;
    try {
      const { data } = await this.hearingAdminApi.addHearingSet({
        title: '',
        status: 'draft',
        questionIdList: [],
      });
      history.push(`${routes.admin.hearingDetail}/${data.id}`);
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingError'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async changeStatusHearing(status: string, id?: number, confirm?: boolean) {
    try {
      const idList = id ? [id] : this.listHearingSelected;
      await this.hearingAdminApi.changeHearingStatus({ status, idList, confirm });
      this.isLoading = true;
      this.listHearingSelected = [];
      if (status !== 'archived') {
        this.pushFlashMessages({
          content: i18n.t('admin.productVideo.messages.changeStatusSuccess'),
          status: 'success',
        });
        this.listHearing = this.listHearing.map(hearing => {
          if (idList.includes(hearing.id)) {
            return { ...hearing, status };
          }
          return hearing;
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.common.messages.archiveSuccess', {
            item: i18n.t('common.actionTarget.hearings'),
          }),
          status: 'success',
        });
        this.getHearingList();
      }
    } catch (error) {
      if (error.status === 403) this.hasHearingLink = true;
      if (error.status === 422) {
        this.pushFlashMessages({
          content: i18n.t('admin.hearingSet.messages.statusValidationError'),
          status: 'error',
        });
      }
    } finally {
      this.isLoading = false;
      this.hasHearingLink = false;
    }
  }
}

export default HearingListStore;
