import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ExternalVideoURLMeta, ContentMeta } from 'types/App';
import { Button } from 'views/components/primitive';
import ExternalVideoInputModal from './ExternalVideoInputModal';
import { validateVideoURL, getVimeoThumbnail, getYouTubeThumbnail } from 'utility/videoLinkHelpers';
import { palette, bp, layer } from 'entity/createTheme';
import { acceptThumbnail } from 'utility/constants';
import { DropzoneImage } from 'views/components/compound';
import InputReplaceFile from 'views/components/compound/InputReplaceFile';

type Props = {
  meta: ExternalVideoURLMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  editable: boolean;
};

const ExternalVideoURLInput: FC<Props> = ({ editable, meta, handleChangeContentMeta }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(meta.url);
  const [activeImage, setActiveImage] = useState<string | undefined>();

  const handleChange = (val: string) => {
    setURL(val);
  };

  const handleToggle = () => {
    setShowModal(prev => !prev);
  };

  const handleChangeURLMeta = (platform: 'vimeo' | 'youtube', thumbnailURL?: string) => {
    handleChangeContentMeta({
      ...meta,
      platform,
      thumbnailFile: undefined,
      url,
      error: '',
      ...(thumbnailURL && { thumbnailURL }),
    });
    setShowModal(false);
    setActiveImage(thumbnailURL);
  };

  const handleConfirm = async () => {
    const { isVimeo, isYouTube } = validateVideoURL(url);

    try {
      if (isVimeo) {
        const thumbnailURL = await getVimeoThumbnail(url);
        handleChangeURLMeta('vimeo', thumbnailURL);
        return;
      }

      if (isYouTube) {
        const thumbnailURL = getYouTubeThumbnail(url);
        handleChangeURLMeta('youtube', thumbnailURL);
        return;
      }
    } catch (e) {
      handleChangeContentMeta({
        ...meta,
        error: 'admin.questions.errors.notFound',
      });

      meta.url !== '' ? setURL(meta.url) : setURL('');
      setShowModal(false);
      return;
    }

    handleChangeContentMeta({
      ...meta,
      error: 'admin.questions.errors.invalidVideoURL',
    });

    meta.url !== '' ? setURL(meta.url) : setURL('');
    setShowModal(false);
  };

  const handleThumbnailChange = (file: any) => {
    const url = URL.createObjectURL(file);
    handleChangeContentMeta({
      ...meta,
      thumbnailSrc: url,
      thumbnailFile: file,
    });
    setActiveImage(url);
  };

  const selectActiveImage = (src: string) => {
    setActiveImage(src);
  };

  return (
    <Root>
      {meta.url !== '' ? (
        <>
          <Partition />
          <SelectedFileWrapper>
            <ChangeUploadedContentInputWrapper>
              <img src={activeImage ? activeImage : meta.thumbnailURL} alt="thumbnail_image" />
            </ChangeUploadedContentInputWrapper>
            <ThumbnailFormWrapper>
              <VideoURLLabel>{t('admin.questions.videoURL')}</VideoURLLabel>
              <VideoURLWrapper>
                <VideoURL target="_blank" rel="noopener noreferrer" href={meta.url}>
                  {meta.url}
                </VideoURL>
                <Button
                  size="sm"
                  type="default"
                  label={t('admin.questions.changeLink')}
                  onClick={handleToggle}
                  disabled={!editable}
                />
              </VideoURLWrapper>

              <UploadThumbnailWrapper>
                <h3 className="thumbnail-title">{t('admin.common.thumbnail')}</h3>
                <div className="thumbnail-content">
                  {meta.thumbnailSrc !== '' ? (
                    <div
                      className={`thumbnail-item thumbnail ${meta.thumbnailSrc === activeImage &&
                        'active'}`}
                      onClick={() => selectActiveImage(meta.thumbnailSrc)}
                    >
                      <img src={meta.thumbnailSrc} alt="thumbnail-item" />
                      {editable && (
                        <InputReplaceFile
                          id="replace_thumbnail"
                          className="thumbnail-replace"
                          handleChange={handleThumbnailChange}
                          accept={acceptThumbnail.join(', ')}
                        />
                      )}
                    </div>
                  ) : (
                    <DropzoneImage
                      className="thumbnail-item dropzone-img"
                      onChange={handleThumbnailChange}
                      disabled={!editable}
                    />
                  )}

                  <div
                    className={`thumbnail-item thumbnail ${meta.thumbnailURL === activeImage &&
                      'active'}`}
                    onClick={() => selectActiveImage(meta.thumbnailURL)}
                  >
                    <img src={meta.thumbnailURL} alt="thumbnail-item" />
                  </div>
                </div>
              </UploadThumbnailWrapper>
            </ThumbnailFormWrapper>
          </SelectedFileWrapper>
        </>
      ) : (
        <ButtonWrapper>
          <Button
            size="sm"
            type="primary"
            label={t('admin.questions.embedVideoLink')}
            onClick={handleToggle}
          />
        </ButtonWrapper>
      )}
      <ExternalVideoInputModal
        inputValue={url}
        showModal={showModal}
        handleToggle={handleToggle}
        handleChange={handleChange}
        handleConfirm={handleConfirm}
      />
      {meta.error !== '' && <p className="error">{t(meta.error)}</p>}
    </Root>
  );
};

const Root = styled('div')({
  '& > p.error': {
    color: palette.redPrimary,
    marginTop: 20,
    fontSize: 12,
  },
});

const VideoURLWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ThumbnailFormWrapper = styled('div')({
  width: 'calc(100% - 376px)',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '84px 0',
  borderRadius: '6px',
  userSelect: 'none',
  marginTop: 15,

  boxSizing: 'border-box',
  height: 200,
  width: '100%',
  border: '0.8px solid #D8D8D8',
});

const ChangeUploadedContentInputWrapper = styled('div')`
  width: 356px;
  height: 200px;
  margin-right: 20px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  user-select: none;
  background: ${palette.blackPrimary};

  @media ${bp.xmd} {
    width: 356px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    &.not-ready {
      object-fit: cover;
    }
  }
  .image-replace {
    position: absolute;
    top: 10px;
    right: 10px;

    // Might not need to specify z-index
    z-index: ${layer.bottomAbove1};
  }

  @media ${bp.md} {
    margin-bottom: 16px;
    width: 100%;
  }
`;

const VideoURLLabel = styled('div')({
  height: 18,
  color: palette.grayMedium4,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
});

const VideoURL = styled('a')({
  display: 'block',
  textDecoration: 'none',
  margin: '10px 0 30px',
  height: 18,
  color: palette.greenPrimary,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '60%',

  '&:hover': {
    textDecoration: 'underline',
  },
});

const SelectedFileWrapper = styled('div')({
  display: 'flex',
});

const Partition = styled('div')({
  margin: `15px 0`,
  width: '100%',
  height: 1,
  background: palette.grayPrimary,
});

// FIXME: extract this component and make it reusable
const UploadThumbnailWrapper = styled('div')`
  .thumbnail {
    &-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &-title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: uppercase;

      height: 19px;
      letter-spacing: 0;
      line-height: 19px;
    }
    &-item {
      height: 95px;
      width: 170px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      border: 2.2px solid ${palette.whitePrimary};
      &.thumbnail {
        background: ${palette.blackPrimary};
      }
      @media ${bp.xmd} {
        width: 156px;
      }
      &:nth-of-type(odd) {
        margin-right: 20px;
      }
      &.active {
        border-color: ${palette.darkMedium2};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.not-ready {
          object-fit: cover;
        }
      }
      .thumbnail-replace {
        position: absolute;
        top: 5px;
        right: 5px;

        // Might not need to specify z-index
        z-index: ${layer.bottomAbove1};
        width: 32px;
        height: 32px;
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.dropzone-img {
        padding: 16px 10px;
        svg {
          flex-shrink: 0;
        }
        p {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      @media ${bp.md} {
        height: 84px;
        width: 150px;

        &:nth-of-type(odd) {
          margin-right: 16px;
        }
      }
    }
  }
`;

export default ExternalVideoURLInput;
