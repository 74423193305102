import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import EditIcon from 'assets/images/ic_edit.svg';
import { Button, SVGIcon } from 'views/components/primitive';

type Props = {
  onClick?: () => void;
  className?: string;
};

const EditButton: FC<Props> = ({ onClick, className }) => {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      type="secondary"
      size="md"
      label={
        <>
          <Icon src={EditIcon} />
          {t('common.edit')}
        </>
      }
      onClick={onClick}
    />
  );
};

export default EditButton;

const Icon = styled(SVGIcon)({
  width: 20,
  height: 20,
  marginRight: 10,
});
