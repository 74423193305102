interface BreakPoints {
  xs: string;
  sm: string;
  md: string;
  xmd: string;
  lg: string;
  xsh: string;
  smh: string;
  mdh: string;
  lgh: string;
}

const bp: BreakPoints = {
  xs: 'screen and (max-width: 540px)',
  sm: 'screen and (max-width: 768px)',
  md: 'screen and (max-width: 1024px)',
  xmd: 'screen and (max-width: 1366px)',
  lg: 'screen and (max-width: 1440px)',
  xsh: 'screen and (max-height: 650px)',
  smh: 'screen and (max-height: 700px)',
  mdh: 'screen and (max-height: 768px)',
  lgh: 'screen and (max-height: 800px)',
};

export interface Palette {
  whitePrimary: string;
  blackPrimary: string;
  greenPrimary: string;
  greenSecondary: string;
  bluePrimary: string;
  blueSecondary: string;
  orangePrimary: string;
  redPrimary: string;
  darkMedium1: string;
  darkMedium2: string;
  darkMedium3: string;
  darkMedium4: string;
  darkMedium5: string;
  darkMedium6: string;
  darkMedium7: string;
  darkMedium8: string;
  darkMedium9: string;
  grayPrimary: string;
  grayMedium1: string;
  darkPrimary: string;
  grayMedium2: string;
  grayMedium4: string;
  grayMedium5: string;
  grayMedium6: string;
  grayMedium7: string;
  grayMedium8: string;
  grayMedium9: string;
  redBlur: string;
  redSecondary: string;
  orangeSecondary: string;
  darkSecondary: string;
  redMedium1: string;
  grayBlur: string;
  whiteBlur: string;
}

const palette: Palette = {
  whitePrimary: '#FFFFFF',
  blackPrimary: '#000000',
  greenPrimary: '#1DA772',
  greenSecondary: '#2DCC70',
  bluePrimary: '#5FA9FF',
  blueSecondary: '#3B8FF1',
  orangePrimary: '#FD925E',
  orangeSecondary: '#EE7D46',
  redPrimary: '#FE7877',
  redSecondary: '#FE6665',
  redMedium1: '#FCCDCD',
  darkPrimary: '#383A42',
  darkSecondary: '#5C6986',
  darkMedium1: '#40414B',
  darkMedium2: '#4C566C',
  darkMedium3: '#262626',
  darkMedium4: '#404040',
  darkMedium5: '#666666',
  darkMedium6: '#656565',
  darkMedium7: '#353535',
  darkMedium8: '#1D1D1D',
  darkMedium9: '#404040',
  grayPrimary: '#D8D8D8',
  grayMedium1: '#EAEAEA',
  grayMedium2: '#CACACA',
  grayMedium4: '#989898',
  grayMedium5: '#F9F9F9',
  grayMedium6: '#F1F1F1',
  grayMedium7: '#ABABAB',
  grayMedium8: '#979797',
  grayMedium9: '#F5F5F5',
  redBlur: 'rgba(254,120,119,20%)',
  grayBlur: 'rgba(0, 0, 0, 0.06)',
  whiteBlur: '#F0F0F0',
};

interface Sizes {
  maxSize: string;
  headerHeightLg: string;
  headerHeightSm: string;
  sizebarWidth: string;
  headerHeightMd: string;
}

const sizes: Sizes = {
  maxSize: '1440px',
  headerHeightLg: '80px',
  headerHeightSm: '58px',
  headerHeightMd: '72px',
  sizebarWidth: '260px',
};

type LayerIndex = {
  top: number;
  topUnder1: number;
  topUnder2: number;
  bottomAbove2: number;
  bottomAbove1: number;
  bottom: number;
};

const layer: LayerIndex = {
  top: 100, // For modal, confirm modal
  topUnder1: 90, // For modal that has action involving confirm modal
  topUnder2: 80, // For sidebar, header
  // Add middle layers when neccessary
  bottomAbove2: 20, // For loading frame
  bottomAbove1: 10, // Equitvalent to z-index: 1. For button, dropdown button
  bottom: 0,
};

export { bp, palette, sizes, layer };
