import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingLinkItem as HearingLinkItemType } from 'types/HearingLink';
import { SVGIcon, Switch, Button } from 'views/components/primitive';
import { useHistory } from 'react-router-dom';
import { useStore } from 'hooks';
import { formatTime } from 'utility/helpers';
import DefaultAvatar from 'assets/images/ic_default_avatar.svg';
import { routes } from 'utility/constants';
import { hearingLinkStatus } from 'utility/constants';
import { ConfirmModal } from 'views/components/compound';
import { palette, bp } from 'entity/createTheme';
import styled from '@emotion/styled';
import VideoDraftIcon from 'assets/images/ic_type_video_draft.svg';
import VideoActiveIcon from 'assets/images/ic_type_video_active.svg';
import HearingSetIcon from 'assets/images/ic_type_hearing_set.svg';
import HearingSetDraftIcon from 'assets/images/ic_type_hearing_set_draft.svg';

interface PropTypes {
  data: HearingLinkItemType;
  updateStatusHearingLink(
    id: number,
    status: string,
    isDetail?: boolean,
    isHearingSet?: boolean
  ): void;
  isArchive?: boolean;
  restoreHearingLink?: (id: number) => void;
}

const HearingLinkItem: FC<PropTypes> = ({
  data,
  updateStatusHearingLink,
  isArchive,
  restoreHearingLink,
}) => {
  const History = useHistory();
  const { t } = useTranslation();
  const {
    appStore: { isAdmin },
  } = useStore();
  const { active, inactive, archived } = useMemo(() => hearingLinkStatus, []);
  const [isModal, setIsModal] = useState(false);
  const { hearing_set_id, has_draft_or_archive, off_link } = data;
  const isHearingSet = hearing_set_id !== null;

  const getIcon = () => {
    if (isHearingSet) {
      return off_link ? HearingSetDraftIcon : HearingSetIcon;
    } else {
      return has_draft_or_archive ? VideoDraftIcon : VideoActiveIcon;
    }
  };

  const getLinkDetail = useMemo(() => `${routes.clientStartHearingLink}/${data.hash_token}`, [
    data.hash_token,
  ]);

  function toggleModal() {
    setIsModal(!isModal);
  }

  function handleClickLinkItem() {
    History.push(`${routes.hearingLink}/${data.id}`);
  }

  function handleToggleActive(isChecked: boolean) {
    updateStatusHearingLink(data.id, isChecked ? active : inactive, false, isHearingSet);
  }

  function handleArchive() {
    updateStatusHearingLink(data.id, archived);
  }

  function handleRestoreLink() {
    if (restoreHearingLink) {
      restoreHearingLink(data.id);
    }
  }

  return (
    <LinkWrapper isAdmin={isAdmin} onClick={handleClickLinkItem}>
      <ClientName>
        <Icon src={getIcon()} />
        {data.client?.name ?? t('hearingLink.noSpecifiedAccount')}
      </ClientName>
      <p>{formatTime(data.updated_at, 'yyyy/MM/dd')}</p>
      <LinkTitle
        href={getLinkDetail}
        target="_blank"
        rel="noopener noreferrer"
        title={getLinkDetail}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {`${window.location.host}${getLinkDetail}`}
      </LinkTitle>
      {isAdmin && (
        <Avatar>
          <User
            alt="avatar"
            src={
              data.created_by && data.created_by.profile_image_url
                ? data.created_by.profile_image_url
                : DefaultAvatar
            }
          />
        </Avatar>
      )}
      <Action>
        {!isArchive ? (
          <Status>
            <Switch
              checked={off_link ? false : data.status === active}
              handleChange={handleToggleActive}
            />
          </Status>
        ) : (
          <RestoreBtn
            type="default"
            label={t('admin.common.restore')}
            onClick={handleRestoreLink}
          />
        )}
      </Action>
      <ConfirmModal
        isModal={isModal}
        toggleModal={toggleModal}
        isArchive
        handleConfirmModal={handleArchive}
        item={t('common.actionTarget.links')}
      />
    </LinkWrapper>
  );
};

export default HearingLinkItem;

const LinkWrapper = styled('div')<{ isAdmin: boolean }>(props => ({
  display: 'grid',
  gridGap: 30,
  padding: '10px 20px',
  gridTemplateColumns: props.isAdmin ? '3fr 1fr 3fr 1fr 1fr' : '2fr 1fr 3fr 1fr',
  background: palette.whitePrimary,
  borderRadius: 6,
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',

  '&:hover': {
    background: palette.whiteBlur,
  },

  '& >*': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  [`@media ${bp.md}`]: {
    gridGap: 10,
    gridTemplateColumns: props.isAdmin ? '2fr 1fr 1fr 1fr 1fr' : '2fr 1fr 3fr 1fr',
  },
}));

const ClientName = styled('div')({
  display: 'flex',
});

const Icon = styled(SVGIcon)({
  minWidth: 24,
  marginRight: 10,
});

const LinkTitle = styled('a')({
  color: palette.greenPrimary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Avatar = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const RestoreBtn = styled(Button)({
  width: '70px',
  height: 32,
  textAlign: 'center',
});

const User = styled('img')({
  height: 30,
  width: 30,
  borderRadius: 15,
});

const Status = styled('div')({
  textAlign: 'center',
});

const Action = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});
