import { HearingPlayMeta, CurrentActivePlay } from 'types/HearingSet';

const hearingPlayMeta: HearingPlayMeta = {
  hearingTime: '',
  activeHearingIndex: 0,
  activeContentIndex: 0,
};

const currentActivePlay: CurrentActivePlay = {
  currentHearing: {
    id: 1,
    title: '',
    organization_id: 0,
    data_files: [
      {
        id: 1,
        url: '',
        thumbnail: '',
        upload_status: '',
        order_index: 0,
        created_at: '',
        updated_at: '',
        //title: '',
        bucket_id: 1,
        manifest_id: '',
        last_vimeo_upload_link: 0,
        manifest_url: '',
        type_data: 'video',
        question: {
          id: 1,
          content: 'content',
          is_multiple_choice: false,
          is_required: true,
          has_other: true,
          created_at: 'create at',
          updated_at: 'update at',
          question_answer: {
            answers: [
              {
                id: 1,
                content: 'content',
                feedback: 'feedback',
              },
            ],
            note: 'hihihi',
          },
          answers: [
            {
              id: 1,
              question_id: 1,
              content: '[Question:1]: Dolor dolor.',
              feedback: '[Answer:1]: Laborum quia.',
              created_at: '2020-03-13T01:18:29.000+09:00',
              updated_at: '2020-03-13T01:18:29.000+09:00',
            },
            {
              id: 2,
              question_id: 1,
              content: '[Question:1]: Dolor dolorem sit.',
              feedback: '[Answer:2]: Nostrum eum quo.',
              created_at: '2020-03-13T01:18:29.000+09:00',
              updated_at: '2020-03-13T01:18:29.000+09:00',
            },
            {
              id: 3,
              question_id: 1,
              content: '[Question:1]: Architecto itaque.',
              feedback: '[Answer:3]: Alias assumenda unde.',
              created_at: '2020-03-13T01:18:29.000+09:00',
              updated_at: '2020-03-13T01:18:29.000+09:00',
            },
          ],
        },
      },
    ],
  },
  currentContent: {
    id: 1,
    type_data: 'video',
    url: '',
    thumbnail: '',
    upload_status: '',
    order_index: 0,
    created_at: '',
    updated_at: '',
    bucket_id: 0,
    manifest_id: '',
    last_vimeo_upload_link: 0,
    manifest_url: '',
    question: {
      id: 0,
      content: '',
      is_multiple_choice: true,
      is_required: true,
      has_other: false,
      organization_id: 6,
      created_at: '',
      updated_at: '',
      answers: [
        {
          id: 1,
          content: 'Daytime',
          feedback: '',
          created_at: '',
          updated_at: '',
          question_id: 230,
        },
        {
          id: 2,
          content: 'At night',
          feedback: '',
          created_at: '',
          updated_at: '',
          question_id: 230,
        },
      ],
      question_answer: {
        id: 3,
        answers: [{ id: 6, content: 'abc', feedback: '' }],
        note: 'blah blah',
        question_id: 288,
      },
    },
  },
};

export { hearingPlayMeta, currentActivePlay };
