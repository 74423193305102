import { ListRequestParamsMeta } from 'types/common';
import { ProductItem, ProductDetail } from 'types/Product';
import HttpClient from 'adapter/HTTPClient';

class ProductVideoApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getProductVideos = async (params?: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{
      product_videos: ProductItem[];
      total: number;
    }>({
      method: 'GET',
      url: '/product_videos',
      ...(params && { params }),
    });

    return res;
  };

  public getProductVideoById = async (id: number | string) => {
    const res = await this.httpClient.request<ProductDetail>({
      method: 'GET',
      url: `product_videos/${id}`,
    });

    return res;
  };
}

export default ProductVideoApi;
