import React, { FC, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

interface InputProps {
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  label?: string;
  error?: string;
  value: string;
  type?: string;
  isCustom?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string | '';
  isShowDetail?: boolean;
  maxLength?: number;
  onBlur?: () => void;
  id?: string;
  toolTip?: string;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & label,
  & input,
  p {
    color: ${palette.darkMedium3};
    font-size: 0.875rem;
    line-height: 1rem;
  }

  & label {
    margin-bottom: 8px;
    .icon {
      position: relative;
      top: 4px;
      left: 4px;
      path {
        color: #9b9b9b;
      }
    }
  }

  & input {
    -webkit-text-fill-color: ${palette.darkMedium3};
    -webkit-opacity: 1;
    padding: 12px 10px;
    border: 1px solid ${palette.grayMedium1};
    border-radius: 6px;
    width: 240px;
    background: ${palette.whitePrimary};
    &:focus {
      outline: none;
      border-color: ${palette.greenPrimary};
    }
    &::placeholder {
      color: ${palette.grayMedium2};
      -webkit-text-fill-color: ${palette.grayMedium2};
    }
  }

  & p {
    min-height: 42px;
    padding: 12px 10px;
    border: 1px solid ${palette.grayMedium1};
    border-radius: 6px;
    font-size: 0.875rem;
    word-break: break-all;
  }

  &.error {
    & input {
      border-color: ${palette.redPrimary};
      &::placeholder {
        color: ${palette.redPrimary};
        -webkit-text-fill-color: ${palette.redPrimary};
      }
    }

    span.error_text {
      margin-top: 10px;
      color: ${palette.redPrimary};
      font-size: 13px;
    }
  }

  &.custom {
    & input {
      border: none;
      border-bottom: 1px solid ${palette.whitePrimary};
      border-radius: 0;
      &:focus {
        border-color: ${palette.greenPrimary};
      }

      &:disabled {
        border-color: ${palette.whitePrimary};
      }
    }
    p {
      border-color: ${palette.whitePrimary};
    }
  }
`;

const InputText: FC<InputProps> = ({
  value,
  onChange,
  label,
  error,
  isCustom,
  className,
  isShowDetail,
  maxLength,
  toolTip,
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <InputWrapper
      className={`input-wrapper ${className} ${error ? 'error' : ''} ${isCustom ? 'custom' : ''}`}
    >
      {label && (
        <label>
          {label}
          {toolTip && (
            <span className="icon">
              <Tooltip title={toolTip}>
                <HelpIcon fontSize={'small'} />
              </Tooltip>
            </span>
          )}
        </label>
      )}

      {isShowDetail && <p>{value}</p>}
      {!isShowDetail && (
        <input value={value} onChange={handleChange} {...rest} maxLength={maxLength} />
      )}
      {error && <span className="error_text">{error}</span>}
    </InputWrapper>
  );
};

export default InputText;
