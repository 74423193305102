import React, { FC, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

import useStore from 'hooks/useStore';
import { DropzoneWithFileBrowse } from 'views/components/compound';
import UploadMeta from './UploadMeta';
import { UploadVideoMeta } from 'types/App';
import NoThumbnail from 'assets/images/no-thumbnail.jpg';
import { videoAcceptShow } from 'utility/constants';
import { extractFileExtentionFromMimeType } from 'utility/helpers';

interface PropTypes {
  meta: UploadVideoMeta;
  editable: boolean;
  handleChangeMeta: (data: UploadVideoMeta) => void;
  status: string;
}

const UploadVideo: FC<PropTypes> = ({ editable, meta, handleChangeMeta, status }) => {
  const {
    videoSrcConvert,
    filename,
    fileThumbnail,
    firstFrameVideo,
    file,
    error,
    errorFile,
  } = useMemo(() => meta, [meta]);
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeSrc, setActiveSrc] = useState('');
  const {
    appStore: { handleFlashMessage },
  } = useStore();

  function setActiveThumbnail(data: string) {
    setActiveSrc(data);
  }

  function handlePrintImage() {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (ctx && video.videoHeight && video.videoWidth) {
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const firstFrameVideo = canvas.toDataURL();
        const fileThumbnail = canvas.toDataURL('image/png');
        handleChangeMeta({
          ...meta,
          firstFrameVideo,
          fileThumbnail,
          error: '',
        });
        setActiveSrc(firstFrameVideo);
        video.pause();
      }
    }
  }

  function handlePlayVideo() {
    const video = videoRef.current;
    if (video && video.src) {
      video.play();
    }
  }

  function onChangeFileVideo(newFile: any) {
    const extension = extractFileExtentionFromMimeType(newFile.type);
    if (videoAcceptShow.includes(extension)) {
      handleChangeMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: URL.createObjectURL(newFile),
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        errorFile: '',
      });
    } else {
      handleChangeMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: null,
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: t('admin.common.notLoadThumbnail', { filename: newFile.name }),
        fileThumbnail: '',
        firstFrameVideo: NoThumbnail,
        errorFile: '',
      });
    }
  }

  function handleError() {
    if (file) {
      handleChangeMeta({
        ...meta,
        file: '',
        fileThumbnail: '',
        videoSrcConvert: '',
        filename: '',
        firstFrameVideo: '',
        error: '',
        errorFile: t('admin.common.notFileVideo', { filename: file.name }),
      });
      if (!editable) {
        handleFlashMessage({
          content: t('admin.common.notFileVideo', { filename: file.name }),
          status: 'error',
        });
      }
    }
  }

  return (
    <UploadVideoWrapper>
      <canvas ref={canvasRef} id="img-canvas" />
      <video
        onLoadedMetadata={handlePlayVideo}
        onTimeUpdate={handlePrintImage}
        ref={videoRef}
        controls
        src={videoSrcConvert || undefined}
        muted
        onError={handleError}
      />
      {!firstFrameVideo && editable ? (
        <DropzoneWrapper>
          <DropzoneWithFileBrowse onChange={onChangeFileVideo} name={filename} type="video" />
        </DropzoneWrapper>
      ) : (
        <>
          <Partition />
          <UploadMeta
            meta={meta}
            editable={editable}
            onChangeFileVideo={onChangeFileVideo}
            handleChangeMeta={handleChangeMeta}
            firstFrameVideo={firstFrameVideo}
            setActiveThumbnail={setActiveThumbnail}
            activeSrc={activeSrc}
            status={status}
            hasError={!!error}
            handleFlashMessage={handleFlashMessage}
          />
        </>
      )}
      {!fileThumbnail && error && <Error className="error">{!fileThumbnail && error}</Error>}
      {errorFile !== '' && <Error className="error">{errorFile}</Error>}
    </UploadVideoWrapper>
  );
};

const DropzoneWrapper = styled('div')({
  marginTop: 15,
});

const UploadVideoWrapper = styled('div')({
  'video,canvas': {
    display: 'none',
  },
});

const Partition = styled('div')({
  margin: `15px 0`,
  width: '100%',
  height: 1,
  background: palette.grayPrimary,
});

const Error = styled('p')({
  color: palette.redPrimary,
  marginTop: 20,
  fontSize: 12,
});

export default observer(UploadVideo);
