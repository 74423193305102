import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { palette } from 'entity/createTheme';
import { TabOption } from 'utility/constants';

const style = css`
  display: flex;
  border-bottom: 1px solid ${palette.grayPrimary};
  cursor: pointer;

  .filter-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: 150px;
    position: relative;

    &__label {
      font-size: 1rem;
      margin-right: 10px;
      color: ${palette.grayMedium4};
    }

    &__count {
      font-size: 0.85rem;
      border-radius: 6px;
      padding: 2px 6px;
      font-weight: bold;
      color: ${palette.whitePrimary};
      background-color: ${palette.grayMedium4};
    }

    &.active {
      .filter-item {
        &__footer {
          width: 100%;
          height: 2px;
          position: absolute;
          background-color: ${palette.darkMedium1};
          top: calc(100%);
          left: 0;
        }
        &__label {
          font-weight: bold;
          color: ${palette.darkMedium1};
        }

        &__count {
          background-color: ${palette.darkMedium1};
        }
      }
    }
  }
`;

interface PropTypes {
  tabOptions: TabOption[];
  value: string;
  handleChangeFilter: (val: string) => void;
}

const TabFilter: FC<PropTypes> = ({ value, tabOptions, handleChangeFilter }) => {
  const { t } = useTranslation();

  function formatOptions() {
    return tabOptions.map(it => ({ ...it, label: t(it.label) }));
  }

  return (
    <div css={style} className="filterbar">
      {formatOptions().map(option => (
        <div
          onClick={() => handleChangeFilter(option.value)}
          key={option.value}
          className={`filter-item ${option.value === value ? 'active' : ''}`}
        >
          <div className="filter-item__label">{option.label}</div>
          <div className="filter-item__count">{option.count}</div>
          <div className="filter-item__footer" />
        </div>
      ))}
    </div>
  );
};

export default TabFilter;
