import { useEffect } from 'react';

const usePreventScroll = (isModalOpen: boolean) => {
  useEffect(() => {
    // This effect is to disable background scroll in iOS Safari
    // For Safari, we need to set `overflow: hidden;` and `position: fixed;` to prevent unintentional background scroll
    const unset = () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.height = 'unset';
      document.body.style.width = 'unset';
    };

    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
    } else {
      unset();
    }

    return () => {
      unset();
    };
  }, [isModalOpen]);
};

export default usePreventScroll;
