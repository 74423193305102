import React from 'react';
import { useTranslation } from 'react-i18next';

const list = [
  { label: 'En', value: 'en-US' },
  { label: 'Ja', value: 'ja' },
];

function LanguagesToggle() {
  const { i18n } = useTranslation();

  return (
    <div className="language-toggle">
      {list.map(({ value, label }) => (
        <span
          onClick={() => i18n.changeLanguage(value)}
          key={value}
          className={`item${i18n.language === value ? ' active' : ''}`}
        >
          {label}
        </span>
      ))}
    </div>
  );
}

export default LanguagesToggle;
