import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routes } from 'utility/constants';
import { formatTime, getImgFromDataFile } from 'utility/helpers';
import { Button, Checkbox } from 'views/components/primitive';
import { SelectStatus } from 'views/components/compound';
import { HearingItem as HearingItemType } from 'types/HearingSet';

import { HearingItemWrapper } from './style';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import NoImageIcon from './NoImageIcon';

interface PropTypes {
  changeTargetHearing?: (id: number, status: string) => void;
  isSelectHearing: boolean;
  isSelected: boolean;
  data: HearingItemType;
  isAdmin?: boolean;
  isArchive?: boolean;
  startHearing?: (id?: number) => void;
  toggleSelectHearingItem?: (val: number) => void;
  restoreArchive?: (id: number) => void;
  changeStatusHearing?: (status: string, id: number) => void;
  viewDetailHearing?: (id: number) => void;
  handleSetIdArchive?: (id: number) => void;
  clickable?: boolean;
}

const HearingItem: FC<PropTypes> = ({
  changeTargetHearing,
  isSelectHearing,
  data,
  isSelected,
  toggleSelectHearingItem,
  startHearing,
  isAdmin,
  isArchive,
  restoreArchive,
  viewDetailHearing,
  changeStatusHearing,
  clickable,
}) => {
  const { t } = useTranslation();
  const {
    appStore: { handleFlashMessage },
  } = useStore();
  const { pathname } = useLocation();

  const videos: { manifestId: string; manifestUrl: string }[] = [];
  data.data_files.forEach(dataFile => {
    if (dataFile.type_data === 'video' && dataFile.manifest_id && dataFile.manifest_url) {
      videos.push({ manifestId: dataFile.manifest_id, manifestUrl: dataFile.manifest_url });
    }
  });

  function handleSelect(): void {
    if (toggleSelectHearingItem) {
      toggleSelectHearingItem(data.id);
    }
  }

  function handleStartHearing() {
    if (startHearing) {
      startHearing(data.id);
    }
  }

  function handleRestore() {
    if (restoreArchive) {
      restoreArchive(data.id);
    }
  }

  function handleChangeStatus(status: string) {
    if (changeStatusHearing && status !== data.status) {
      changeStatusHearing(status, data.id);
    }
    changeTargetHearing && changeTargetHearing(data.id, status);
  }

  function handleViewDetail() {
    if (viewDetailHearing) {
      viewDetailHearing(data.id);
    }
    if (isArchive) {
      handleFlashMessage({ content: t('admin.common.notViewDetail'), status: 'warning' });
    }
  }

  const thumbnail = getImgFromDataFile(data.data_files[0]);

  return (
    <HearingItemWrapper
      className="hearing_item"
      isSelected={isSelected}
      clickable={clickable}
      {...{ onClick: clickable ? handleViewDetail : undefined }}
    >
      {videos.length > 0 && (
        <BulkDownloader videos={videos} top={'0px'} right={'0px'} whiteBackground={true} />
      )}
      <div className="hearing-info">
        <div className="hearing-info--thumbnail">
          {thumbnail ? (
            <img src={thumbnail} alt="hearing avatar" className="hearing-avatar" />
          ) : (
            <NoImageIcon />
          )}
        </div>

        <div>
          <p
            className={`hearing-info__title ${pathname === routes.hearings && 'disabled'}`}
            onClick={handleViewDetail}
          >
            {data.title || t('admin.hearingSet.noTitle')}
          </p>
          <p className="hearing-info__counts">
            {t('hearingSet.hearingItem.numOfVideo', { count: data.data_files.length })}
          </p>
          <p className="hearing-info__updated">
            {t('hearingSet.hearingItem.lastUpdated')}:{' '}
            {formatTime(data.updated_at || '', 'yyyy/MM/dd')}
          </p>
        </div>
      </div>
      <div className="hearing-action">
        {isSelectHearing && <Checkbox checked={isSelected} onChange={handleSelect} />}
        {!isSelectHearing && !isAdmin && (
          <Button
            onClick={handleStartHearing}
            size="sm"
            type="primary"
            label={t('common.startHearing')}
          />
        )}
        {isAdmin && !isSelectHearing && !isArchive && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <SelectStatus
              size="sm"
              value={data.status || 'draft'}
              onChangeStatus={handleChangeStatus}
            />
          </div>
        )}
        {isAdmin && !isSelectHearing && isArchive && (
          <Button
            type="default"
            size="sm"
            label={t('admin.common.restore')}
            onClick={handleRestore}
          />
        )}
      </div>
    </HearingItemWrapper>
  );
};

export default observer(HearingItem);
