import { AxiosTransformer } from 'axios';
import { Question } from 'types/Question'; //fix import
import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';

class QuestionApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getAllQuestions = async ({ page, sort, order }: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{ questions: Question[]; total: number }>({
      method: 'GET',
      url: 'admin/questions',
      params: { page, sort, order, per_page: 20 },
    });
    return res;
  };

  public getQuestionById = async ({ id }: { id: string }) => {
    //FIX ME: add response type
    const res = await this.httpClient.request({
      method: 'GET',
      url: `admin/questions/${id}`,
    });

    return res;
  };

  public deleteQuestion = async ({ deleteIds }: { deleteIds: number[] }) => {
    await this.httpClient.request({
      method: 'DELETE',
      url: 'admin/questions/delete_questions',
      params: {
        ids: deleteIds.join(','),
      },
    });
  };

  public addQuestion = async ({
    data,
    onUploadProgress,
    transformResponse,
  }: {
    data: FormData;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse?: AxiosTransformer | AxiosTransformer[];
  }) => {
    const res = await this.httpClient.request({
      method: 'POST',
      url: 'admin/questions',
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onUploadProgress,
      ...(transformResponse && { transformResponse }),
    });

    return res;
  };

  public updateQuestion = async ({
    id,
    data,
    onUploadProgress,
    transformResponse,
  }: {
    id: string;
    data: FormData;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse?: AxiosTransformer | AxiosTransformer[];
  }) => {
    const res = await this.httpClient.request({
      method: 'PUT',
      url: `admin/questions/${id}`,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onUploadProgress,
      ...(transformResponse && { transformResponse }),
    });

    return res;
  };
}

export default QuestionApi;
