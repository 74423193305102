import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';

type QuestionWrapperProps = {
  canAddAnswer: boolean;
};

export const QuestionHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  & > span {
    overflow: hidden;
    svg {
      flex-shrink: 0;
    }
  }
  h2 {
    font-size: 24px;
    color: ${palette.darkMedium3};
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media ${bp.md} {
      font-size: 22px;
    }
  }
  .header-actions {
    display: flex;
    align-items: center;
    button:last-of-type {
      margin-left: 10px;
    }
  }
  .btn_delete {
    width: 60px;
  }

  button.btn_delete {
    background: ${palette.redPrimary};
  }
  @media ${bp.md} {
    margin-bottom: 16px;
  }
`;

export const QuestionWrapper = styled('div')<QuestionWrapperProps>`
  .question_group {
    display: flex;
    margin-bottom: 20px;
    & > .input_question {
      flex: 1;
      margin-right: 30px;
    }
  }
  .custom {
    margin-bottom: 0;
    input {
      width: 100%;
      padding: 12px 0;
      &::placeholder {
        color: ${palette.grayMedium2};
        font-weight: normal;
      }
    }
    p {
      padding: 12px 0;
    }
  }
  .bottom_items {
    display: flex;
    justify-content: ${props => (props.canAddAnswer ? 'space-between' : 'flex-end')};
    align-items: center;
  }
  .add_answer {
    display: flex;
    align-items: center;
    span.action_add {
      padding: 12px 0;
      margin-left: 15px;
      font-size: 14px;
      color: ${palette.grayMedium2};
      cursor: pointer;
    }
  }
  .switch_container {
    display: flex;
    align-items: center;

    .switch_label {
      line-height: 14px;
      font-size: 14px;
      color: ${palette.grayMedium2};
      margin-left: 10px;
    }
  }
  span.status {
    width: 24px;
    height: 24px;
    border: 0.8px solid ${palette.grayMedium2};
    &.checkbox {
      border-radius: 6px;
    }
    &.radio {
      border-radius: 50%;
    }
  }
`;

export const AnswerItemWrapper = styled('div')`
  margin-bottom: 20px;
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > .input_answer {
      flex: 1;
      margin: 0 30px 0 15px;
    }
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      & > g > g {
        fill: ${palette.darkMedium3};
      }
    }
  }
  & > .input_feedback {
    margin-left: 39px;
    margin-right: 46px;
  }
`;

export const QuestionContentWrapper = styled('div')`
  padding: 20px;
  background: ${palette.whitePrimary};
  border-radius: 6px;
  h4 {
    margin-bottom: 10px;
    font-size: 16px;
    color: ${palette.darkMedium9};
    &:last-of-type {
      margin-top: 30px;
      @media ${bp.md} {
        margin-top: 10px;
      }
    }
  }
  .thumbnail_content {
    display: flex;
    .thumbnail_video {
      width: 356px;
      height: 200px;
      margin-right: 14px;
    }
    .thumnail_upload {
      width: calc(100% - 370px);
    }
    @media ${bp.md} {
      margin-bottom: 0;
    }
  }
`;

export const UploadQuestionWrapper = styled('div')`
  padding: 30px;
  max-height: auto;
  @media ${bp.md} {
    padding: 16px;
  }
`;
