import { observable, action, toJS } from 'mobx';
import history from 'utility/history';
import { routes } from 'utility/constants';
import shallowEqual from 'utility/shallowEqual';
import { RootStore } from '../index';
import { ProductItem, ProductDetail } from 'types/Product';
import { MessageProps } from 'types/App';
import { ListMeta, BaseListRequestParams } from 'types/common';
import i18n from 'i18n';
import ProductVideoApi from 'services/ProductVideoApi';

export const defaultListMeta: ListMeta = {
  total: undefined,
  page: 1,
  sort: 'desc',
  order: 'updated_at',
  q: '',
};

export const defaultProductDetail: ProductDetail = {
  id: -1,
  title: '',
  description: '',
  url: '',
  thumbnail: '',
  status: '',
  organization_id: 0,
  created_at: '',
  updated_at: '',
  other_videos: [],
  hearing_sets: [],
  manifest_id: '',
  manifest_url: '',
};

class ProductStore {
  private readonly productVideoApi: ProductVideoApi;
  @observable public isLoading = false;
  @observable public isFirstLoad = true;
  @observable public sortType = '';
  @observable public productDetail: ProductDetail = { ...defaultProductDetail };
  @observable public productMeta: ListMeta = { ...defaultListMeta };
  @observable public productList: ProductItem[] = [];
  @observable public allProductList: ProductItem[] = [];
  @observable public rootStore: RootStore;
  @observable public searchKeyword = '';
  @observable public routeChanged = false;
  @observable public lastSearchParams: Partial<BaseListRequestParams> | undefined;

  constructor({
    rootStore,
    productVideoApi,
  }: {
    rootStore: RootStore;
    productVideoApi: ProductVideoApi;
  }) {
    this.rootStore = rootStore;
    this.productVideoApi = productVideoApi;
  }

  @action.bound
  public changeSearchKeyword(val: string) {
    this.searchKeyword = val;
    this.setRouteChanged(false);
    this.setIsFirstLoad(false);
  }

  @action.bound setRouteChanged(val: boolean) {
    this.routeChanged = val;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getProductDetail(id: number | string) {
    try {
      const { data } = await this.productVideoApi.getProductVideoById(id);
      this.productDetail = data;
    } catch (error) {
      this.putFlashMessages({
        content: i18n.t('admin.productVideo.messages.notFoundProduct'),
        status: 'error',
      });
      history.push(routes.productVideo);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async setIsFirstLoad(val: boolean) {
    this.isFirstLoad = val;
  }

  @action.bound
  public async updateProductMeta(obj: Partial<ListMeta>) {
    this.productMeta = { ...this.productMeta, ...obj };
  }

  @action.bound
  public async getProductVideoList({
    page = 1,
    order = 'updated_at',
    sort = 'desc',
    q = '',
  }: Partial<BaseListRequestParams>) {
    const searchParams = {
      page,
      order,
      sort,
      q,
    };
    if (this.lastSearchParams && !!shallowEqual(toJS(this.lastSearchParams), searchParams)) return;

    this.lastSearchParams = searchParams;
    this.isLoading = true;
    this.routeChanged = false;

    this.productMeta = {
      ...this.productMeta,
      page,
      order,
      sort,
    };
    try {
      const { data } = await this.productVideoApi.getProductVideos({
        page,
        order,
        sort,
        per_page: 12,
        q,
      });
      this.productList = data.product_videos;
      this.productMeta = { ...this.productMeta, total: data.total };
    } catch (error) {
      this.putFlashMessages({ content: error.message, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async getAllProductVideoList() {
    try {
      const { data } = await this.productVideoApi.getProductVideos({
        page: 1,
        per_page: 50,
      });
      this.allProductList = data.product_videos;
    } catch (error) {
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  @action.bound
  public resetProductDetail() {
    this.productDetail = { ...defaultProductDetail };
  }

  @action.bound
  public resetProductList() {
    this.isLoading = false;
    this.isFirstLoad = true;
    this.productList = [];
    this.searchKeyword = '';
    this.lastSearchParams = undefined;
    this.productMeta = { ...defaultListMeta };
  }

  public handleStartHearing(id: number) {
    history.push(`${routes.startAppointment}?video_id=${id}`);
  }
}

export default ProductStore;
