import HttpClient from 'adapter/HTTPClient';

class SettingApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public updateOrganizationLogo = async (data: FormData) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'organizations/update_logo',
      data,
    });
  };
}

export default SettingApi;
