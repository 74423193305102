import React from 'react';
import { sizes, palette, bp, layer } from './entity/createTheme';
import { Global, css } from '@emotion/core';

const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: ${palette.darkMedium3};
      }
      html {
        font-size: 16px;
      }
      html,
      body,
      button,
      textarea,
      input {
        font-family: 'Montserrat', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro',
          '游ゴシック', Meiryo, メイリオ, 'ＭＳ ゴシック', sans-serif;
      }

      .grabbing {
        cursor: grabbing !important;

        * {
          cursor: grabbing !important;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
      a,
      input,
      textarea {
        &:focus {
          outline: none;
        }
      }
      #root {
        .app-loading {
          width: 100%;
          height: 100vh;
        }
      }
      .container {
        position: relative;
        display: flex;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        background-color: ${palette.grayMedium5};
        max-width: ${sizes.maxSize};

        .sidebar {
          height: 100%;
          width: ${sizes.sizebarWidth};
          position: relative;

          @media ${bp.sm} {
            position: fixed;
          }
        }

        .main {
          flex: 1;
          height: 100%;
          width: calc(100% - ${sizes.sizebarWidth});

          // Without 'min-width: 0;', the sidebar's width (260px) will be ignored.
          // See bugzilla for more detail https://bugzilla.mozilla.org/show_bug.cgi?id=1242914
          min-width: 0;

          @media ${bp.sm} {
            width: 100%;
          }

          .header {
            width: 100%;
            z-index: ${layer.topUnder2};
          }

          .content {
            height: calc(100% - ${sizes.headerHeightLg});

            &.content_hearing-play {
              height: 100%;
              overflow-y: hidden;
              &.hearing-process {
                overflow-y: auto;
                .hearing-content {
                  overflow: visible;
                }
              }
            }

            @media ${bp.sm} {
              height: calc(100% - ${sizes.headerHeightSm});
            }
          }
        }

        @media ${bp.xs} {
          overflow-x: scroll;
        }
      }

      .result-modal {
        .modal {
          width: 1120px;
          height: 90vh;

          @media ${bp.md} {
            width: 750px;
          }
        }
        &.hearing_link {
          .modal-content {
            padding: 0;
          }
        }
      }

      .add_hearings-modal,
      .add_questions-modal,
      .add_product_video-modal {
        .modal {
          width: 835px;
          height: 643px;

          @media ${bp.md} {
            width: 704px;
          }
        }
      }

      .modal-content {
        .alert_modal {
          p {
            margin-bottom: 30px;
            span {
              font-weight: bold;
            }
          }
          h4 {
            color: ${palette.redPrimary};
          }
        }
      }

      .create_memo-modal {
        .modal {
          width: 600px;
          .modal-header {
            padding: 20px 16px;
          }
        }
      }

      .scroll-bar-thumbnail {
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          background-color: ${palette.whitePrimary};
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: ${palette.grayMedium1};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${palette.grayMedium1};
          border: none;
        }
      }
      .frame_loading {
        position: fixed;
        width: calc(100% - ${sizes.sizebarWidth} + 30px);
        height: calc(100% - ${sizes.headerHeightLg});
        left: calc(${sizes.sizebarWidth} - 30px);
        top: ${sizes.headerHeightLg};
        background: rgba(0, 0, 0, 0.3);
        z-index: ${layer.bottomAbove2};
        @media ${bp.md} {
          width: 100%;
          height: calc(100% - ${sizes.headerHeightMd});
          top: ${sizes.headerHeightMd};
          left: 0;
        }
      }
    `}
  />
);

export default GlobalStyles;
