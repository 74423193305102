import React, { FC, useState, useMemo, useRef } from 'react';
import { useDocumentCLick } from 'hooks';

import { UserInfoWrapper } from './styles';
import { SVGIcon } from 'views/components/primitive';
import DropdownUrl from 'assets/images/ic_dropdown.svg';
import { Organization } from 'types/App';

interface PropTypes {
  value: string;
  organizations: Organization[];
  handleChangeOrganization: (id: string) => void;
}

const Organizations: FC<PropTypes> = ({ value, organizations, handleChangeOrganization }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isSelect = useMemo(() => organizations.length >= 2, [organizations.length]);
  const className = useMemo(() => (isOpen ? 'open' : ''), [isOpen]);
  const refContainer = useRef<HTMLDivElement>(null);

  useDocumentCLick(handleClick);

  function toggleOptions() {
    if (isSelect) {
      setIsOpen(!isOpen);
    }
  }

  function handleClick(e: MouseEvent | any): void {
    if (refContainer.current) {
      if (!refContainer.current.contains(e.target) && isOpen) {
        toggleOptions();
      }
    }
  }

  function getLabelActive() {
    const item = organizations.find(it => it.id === value);
    return item ? item.name : organizations[0].name;
  }

  function onChangeOrganization(id: string) {
    if (id !== value) {
      handleChangeOrganization(id);
      toggleOptions();
    }
  }

  function renderOrganizationOptions() {
    return organizations.map(it => (
      <div key={it.id} onClick={() => onChangeOrganization(it.id)} className="dropdown-menu__item">
        {it.name}
      </div>
    ));
  }

  if (!organizations.length) return null;

  return (
    <UserInfoWrapper ref={refContainer} className={className}>
      <div onClick={toggleOptions} className="organization-name">
        {getLabelActive()}
      </div>
      {isSelect && <SVGIcon src={DropdownUrl} />}
      <div className="dropdown-menu scroll-bar-thumbnail">
        <div className="dropdown-menu__container">{renderOrganizationOptions()}</div>
      </div>
    </UserInfoWrapper>
  );
};

export default Organizations;
