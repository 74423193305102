import { observable, action, computed } from 'mobx';
import { fromJS } from 'immutable';
import { Creator, MessageProps } from 'types/App';
import { otherAnswerFixedId } from 'utility/constants';
import { hearingPlayMeta } from './constants';
import { RootStore } from '../index';
import i18n from 'i18n';
import {
  HearingPlayMeta,
  CurrentActivePlay,
  AnswerResult,
  HearingStatus,
  HearingItem,
  DataFile,
} from 'types/HearingSet';
import { ProductItem } from 'types/Product';
import { ParsedQs } from 'qs';
import HearingLinkApi from 'services/HearingLinkApi';

class InsideSalePlayStore {
  private readonly hearingLinkApi: HearingLinkApi;
  @observable public isProductVideo = false;
  @observable public insideSaleProductVideos: ProductItem[] = [];
  @observable public rootStore: RootStore;
  @observable public loading = false;
  @observable public inputMailModal = false;
  @observable public hasFeedbackScreen = true;
  @observable public hearingPlay: HearingItem[] = [];
  @observable public clientEmail = '';
  @observable public hearingStatus: HearingStatus = 'hearing';
  @observable public hearingFeedback = '';
  @observable public currentHashcode = '';
  @observable public hearingPlayMeta: HearingPlayMeta = { ...hearingPlayMeta };
  @observable public creatorLink: Creator = {
    username: '',
    profile_image_url: '',
  };
  @observable public submitting = false;
  @observable public organizationLogo: string | null = null;
  @observable public ctaRedirect = false;
  @observable public ctaRedirectTo: string | null = null;
  @observable public ctaDescription?: string;
  @observable public ctaAutoRedirect = false;

  constructor({
    rootStore,
    hearingLinkApi,
  }: {
    rootStore: RootStore;
    hearingLinkApi: HearingLinkApi;
  }) {
    this.rootStore = rootStore;
    this.hearingLinkApi = hearingLinkApi;
  }

  @computed get currentActivePlay(): CurrentActivePlay {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    const currentHearing = this.hearingPlay[activeHearingIndex];
    const currentContent = currentHearing
      ? currentHearing.data_files[activeContentIndex]
      : undefined;
    return { currentHearing, currentContent };
  }

  @computed get currentProductVideoPlay(): ProductItem {
    const { activeContentIndex } = this.hearingPlayMeta;
    const currentProductVideo =
      this.insideSaleProductVideos && this.insideSaleProductVideos[activeContentIndex];
    return currentProductVideo;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public setSubmitting(submitting: boolean) {
    this.submitting = submitting;
  }

  @action.bound
  public setHearingStatus(val: HearingStatus) {
    this.hearingStatus = val;
  }

  @action.bound
  public toggleInputMailModal() {
    this.inputMailModal = !this.inputMailModal;
  }

  @action.bound
  public setHearingPlayMeta(name: string, value: string | number) {
    this.hearingPlayMeta = { ...this.hearingPlayMeta, [name]: value };
  }

  @action.bound
  public async handleVerifyLinkToken(token: string | undefined, email?: string) {
    if (token) {
      try {
        const { data } = await this.hearingLinkApi.getHearingLinkByToken(token, email);

        if (email) {
          this.clientEmail = email;
        }

        if (data.hearing_set_id && data.hearing_set_id !== null) {
          this.isProductVideo = false;
        } else {
          this.isProductVideo = true;
        }

        if (!data.needs_feedback) this.hasFeedbackScreen = false;
        this.organizationLogo = data.organization_logo;
        this.insideSaleProductVideos = data.product_videos
          ? data.product_videos.map((v: any) => {
              v.manifest_id = '';
              v.manifest_url = '';
              return v;
            })
          : [];
        this.currentHashcode = token;
        this.ctaRedirect = data.cta_redirect;
        this.ctaRedirectTo = data.cta_redirect_to;
        this.ctaDescription = data.cta_description;
        this.ctaAutoRedirect = !!data.cta_auto_redirect;
        this.hearingPlay = fromJS(this.hearingPlay)
          .setIn([0], {
            title: data.title,
            data_files: data.data_files || [],
          })
          .updateIn([0, 'data_files'], (d: DataFile[]) =>
            d.map((d: DataFile) => {
              d.manifest_id = '';
              d.manifest_url = '';
              return d;
            })
          )
          .toJS();
        this.hearingPlayMeta = {
          ...this.hearingPlayMeta,
          hearingTime: new Date().toISOString(),
          createBy: data.created_by,
        };
      } catch (error) {
        const status = error?.status ?? 0;
        if (status === 403) {
          // require type email to get hearing detail
          this.inputMailModal = true;
          const data = error?.data?.created_by ?? {};
          this.creatorLink = data;
        } else {
          this.putFlashMessages({
            content: i18n.t('hearingLink.messages.getLinkInfomationFailed'),
            status: 'error',
          });
        }
      }
    }
  }

  @action.bound
  public setAnswer(answerId: number) {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    const currentHearingPlayJS = fromJS(this.hearingPlay);
    const currHearing = this.hearingPlay[activeHearingIndex];
    const currQuestion = currHearing.data_files[activeContentIndex].question;
    const currAnswer = currQuestion.clientAnswers || [];
    const currentQuestionAnswer: AnswerResult[] = currQuestion?.question_answer?.answers ?? [];
    const otherAnswer: string = currAnswer.includes(otherAnswerFixedId)
      ? currQuestion?.otherAnswerContent ?? ''
      : '';
    let newAnswer: number[] = [];
    let newQuestionAnswer: AnswerResult[] = [];

    if (currAnswer.includes(answerId)) {
      if (currQuestion.is_multiple_choice) {
        newAnswer = currAnswer.filter(it => it !== answerId);
        newQuestionAnswer = currentQuestionAnswer.filter(it => it.id !== answerId);
      } else {
        newAnswer = [];
        newQuestionAnswer = [];
      }
    } else {
      const getAnswer = currQuestion.answers.find(item => item.id === answerId);
      const getQuestionAnswer: AnswerResult = {
        id: answerId,
        content: getAnswer?.content ?? '',
        feedback: getAnswer?.feedback ?? '',
      };
      if (currQuestion.is_multiple_choice) {
        newAnswer = [...currAnswer, answerId];
        newQuestionAnswer = [...currentQuestionAnswer, getQuestionAnswer];
      } else {
        newAnswer = [answerId];
        newQuestionAnswer = [getQuestionAnswer];
      }
    }
    this.hearingPlay = currentHearingPlayJS
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'clientAnswers'],
        newAnswer
      )
      .setIn(
        [
          activeHearingIndex,
          'data_files',
          activeContentIndex,
          'question',
          'question_answer',
          'answers',
        ],
        newQuestionAnswer
      )
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'otherAnswerContent'],
        otherAnswer
      )
      .toJS();
  }

  @action.bound
  public handleChangeOtherAnswer(val: string): void {
    const { activeHearingIndex, activeContentIndex } = this.hearingPlayMeta;
    this.hearingPlay = fromJS(this.hearingPlay)
      .setIn(
        [activeHearingIndex, 'data_files', activeContentIndex, 'question', 'otherAnswerContent'],
        val
      )
      .toJS();
  }

  @action.bound
  public handleChangeHearingFeedback(val: string): void {
    this.hearingFeedback = val;
  }

  @action.bound
  public async handleEndHearing(ma_params: ParsedQs, ma_link: string) {
    if (this.isProductVideo) {
      await this.handleEndVideoFeedback(ma_params, ma_link);
    } else {
      await this.handleEndHearingFeedback(ma_params, ma_link);
    }
  }

  @action.bound
  public async handleEndHearingFeedback(ma_params: ParsedQs, ma_link: string) {
    try {
      const company_name = this.clientEmail || 'anonymous';
      const memo = this.hearingFeedback.trim();
      const questions = this.hearingPlay[0].data_files.map(data_file => {
        const clientAnswers = data_file?.question?.clientAnswers ?? [].slice();
        const allAnswerIds = data_file.question.answers.map(answer => answer.id);
        // re-order client answer_ids as initial question's answers order.
        const answer_ids: Array<number | string> = allAnswerIds.filter(id =>
          clientAnswers.includes(id)
        );
        if (clientAnswers.includes(otherAnswerFixedId)) {
          answer_ids.push(data_file.question.otherAnswerContent || '');
        }

        return {
          id: data_file.question.id,
          answer_ids,
        };
      });

      const data = { company_name, memo, questions, ma_params, ma_link };
      await this.hearingLinkApi.createHearingLinkResult(
        this.currentHashcode,
        this.clientEmail,
        data
      );
      this.setHearingStatus('done');
    } catch (error) {
      this.putFlashMessages({
        status: 'error',
        content: i18n.t('hearingLink.messages.createAppointmentFailed'),
      });
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async handleEndVideoFeedback(ma_params: ParsedQs, ma_link: string) {
    try {
      const company_name = this.clientEmail || 'anonymous';
      const memo = this.hearingFeedback.trim();
      const data = { company_name, memo, ma_params, ma_link };
      await this.hearingLinkApi.createVideoLinkResult(this.currentHashcode, this.clientEmail, data);
      this.setHearingStatus('done');
    } catch (error) {
      this.putFlashMessages({
        status: 'error',
        content: i18n.t('hearingLink.messages.createAppointmentFailed'),
      });
    } finally {
      this.submitting = false;
    }
  }
}

export default InsideSalePlayStore;
