import { observable, action, computed } from 'mobx';
import i18n from 'i18n';
import history from 'utility/history';
import { routes } from 'utility/constants';
import {
  MessageProps,
  UploadVideoMeta,
  UploadImageMeta,
  ExternalVideoURLMeta,
  UploadPDFMeta,
  NoContentMeta,
  ContentType,
  ContentMeta,
} from 'types/App';
import { RootStore } from '../../index';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import { HearingItem, DataFile } from 'types/HearingSet';
import { Question, Answer } from 'types/Question';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { UploadCompletedProps, ListMeta } from 'types/common';
import { defaulHearingDetail, defaultListMeta } from '../constants';
import QuestionApi from 'services/Admin/QuestionApi';
import { dataURLtoFile, trimSpace, extractFileNameFromPath } from 'utility/helpers';

import {
  defaultQuestionDetail,
  defaultVideoMeta,
  defaultImageMeta,
  defaultExtrenalVideoURLMeta,
  defaultPDFMeta,
  defaultNoContentMeta,
} from './constants';

export const checkPathChange = (hearingId: string) => {
  const originalHearingPath = `${routes.admin.hearingDetail}/${hearingId}`;
  const currentPath = history.location.pathname;
  const samePath = currentPath === originalHearingPath;
  return samePath;
};
class HearingDetailStore {
  private readonly hearingAdminApi: HearingAdminApi;
  private readonly questionApi: QuestionApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public hearingDetail: HearingItem = { ...defaulHearingDetail };
  @observable public originalHearingDetail: HearingItem = { ...defaulHearingDetail };
  @observable public errors = {};
  @observable public keySearchQuestion = '';
  @observable public listIdQuestionDetail: number[] = [];
  @observable public listHearing: HearingItem[] = [];
  @observable public hearingListMeta: ListMeta = { ...defaultListMeta };
  @observable public isQuestionSectionTouched = false;
  @observable public isHearingSectionTouched = false;
  @observable public isSortQuestionMode = false;

  // observables for creating/editing question
  @observable public questionDetail: Question = { ...defaultQuestionDetail };
  @observable public contentType: ContentType = 'video';
  @observable public questionVideoMeta: UploadVideoMeta = { ...defaultVideoMeta };
  @observable public externalVideoURLMeta: ExternalVideoURLMeta = {
    ...defaultExtrenalVideoURLMeta,
  };
  @observable public imageMeta: UploadImageMeta = { ...defaultImageMeta };
  @observable public pdfMeta: UploadPDFMeta = { ...defaultPDFMeta };
  @observable public noContentMeta: NoContentMeta = { ...defaultNoContentMeta };
  @observable public originalContentMeta: ContentMeta = { ...defaultVideoMeta };
  @observable public editQuestionTouched = false;
  @observable public editDataFileTouched = false;
  @observable public percentUploaded: UploadCompletedProps = { filename: '', completed: 0 };
  @observable public answerCanDeleteIds: number[] = [];
  @observable public submitting = false;
  @observable public isCreateQuestionModalOpen = false;
  @observable public isEditQuestionModalOpen = false;

  constructor({
    rootStore,
    hearingAdminApi,
    questionApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
    questionApi: QuestionApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
    this.questionApi = questionApi;
  }

  @computed get originalHearingTitle() {
    return this.originalHearingDetail.title;
  }

  @computed get hearingUpdateDisabled() {
    return !this.isHearingSectionTouched;
  }

  @computed get questionOrderUpdateDisabled() {
    return !this.isQuestionSectionTouched;
  }
  @computed get submitQuestionDisabled() {
    switch (this.contentType) {
      case 'video':
      case 'image':
      case 'pdf':
        return this.fileMeta.file === '';
      case 'link':
        return this.fileMeta.url === '';
      case 'none_data':
        return false;
      default:
        return true;
    }
  }

  @computed get updateQuestionDisabled() {
    // if type is 'none_data', we regard it as touched
    const touched =
      this.editQuestionTouched || this.editDataFileTouched || this.contentType === 'none_data';
    if (this.originalContentMeta.type === this.contentType) return !touched;
    return !touched || this.submitQuestionDisabled;
  }

  @computed get fileMeta() {
    switch (this.contentType) {
      case 'video': {
        const { file, fileThumbnail: thumbnailFile, filename } = this.questionVideoMeta;
        return { file, thumbnailFile, filename };
      }
      case 'link': {
        const { thumbnailFile, url } = this.externalVideoURLMeta;
        return { thumbnailFile, url };
      }
      case 'image': {
        const { file, filename } = this.imageMeta;
        return { file, filename };
      }
      case 'pdf': {
        const { file, thumbnailFile, filename } = this.pdfMeta;
        return { file, thumbnailFile, filename };
      }
      default:
        return {};
    }
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public handleChangeHearingDetail(data: object) {
    this.isHearingSectionTouched = true;
    this.hearingDetail = { ...this.hearingDetail, ...data };
  }

  @action.bound
  public handleSortQuestions = (sort: SortEnd) => {
    const { oldIndex, newIndex } = sort;

    if (newIndex !== oldIndex) {
      const { data_files } = this.hearingDetail;
      this.isQuestionSectionTouched = true;
      this.hearingDetail = {
        ...this.hearingDetail,
        data_files: arrayMove(data_files, oldIndex, newIndex),
      };
    }
  };

  @action.bound
  public handleDeleteQuestion = async (idToDelete: number, hearingId: string) => {
    const { title, data_files } = this.originalHearingDetail;
    const originalQuestionList = data_files.map(dataFile => dataFile.question.id);
    const question_ids = originalQuestionList.filter(questionId => questionId !== idToDelete);
    try {
      await this.updateHearingSet({
        id: hearingId,
        title,
        question_ids,
        remove_question_ids: [idToDelete],
      });
      this.hearingDetail = {
        ...this.hearingDetail,
        data_files: data_files.filter(dataFile => dataFile.question.id !== idToDelete),
      };
      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.deleteQuestionSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.deleteQuestionError'),
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  };

  @action.bound
  public async getHearingDetail(id: string) {
    try {
      this.isLoading = true;
      const { data } = await this.hearingAdminApi.getHearingById({ id });
      this.hearingDetail = data;
      this.originalHearingDetail = data;
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.notFoundHearing'),
        status: 'error',
      });
      history.push(routes.admin.hearingSet);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async changeStatusHearing(status: string, id: number) {
    try {
      const idList = [id];
      await this.hearingAdminApi.changeHearingStatus({ status, idList });
      this.isLoading = true;
      history.push({ pathname: routes.admin.archive, search: `?panel=hearings` });
      this.pushFlashMessages({
        content: i18n.t('admin.common.messages.archiveSuccess', {
          item: i18n.t('common.actionTarget.hearings'),
        }),
        status: 'success',
      });
    } catch (error) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async updateHearingSet(
    payload: {
      id: string;
      status?: string;
      title: string;
      question_ids: number[];
      remove_question_ids?: number[];
    },
    disableSnackbar?: boolean
  ) {
    this.submitting = true;
    const { id, title, status, question_ids, remove_question_ids } = payload;

    try {
      const { data } = await this.hearingAdminApi.updateHearingSet({
        id,
        title,
        status,
        question_ids,
        remove_question_ids,
      });

      const samePath = checkPathChange(id);
      if (samePath) this.originalHearingDetail = data;

      if (!disableSnackbar) {
        this.pushFlashMessages({
          content: i18n.t('admin.hearingSet.messages.updateHearingSuccess'),
          status: 'success',
        });
      }
    } catch (error) {
      throw error;
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async updateHearingSection(id: string) {
    const { status, title } = this.hearingDetail;
    const { data_files } = this.originalHearingDetail;
    const question_ids = data_files.map(dataFile => dataFile.question.id) || [];

    try {
      await this.updateHearingSet({
        id,
        title,
        status,
        question_ids,
      });

      this.isHearingSectionTouched = false;
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
      this.submitting = false;
    }
  }

  @action.bound
  public async updateQuestionSection(id: string) {
    const { status, title } = this.originalHearingDetail;
    const { data_files } = this.hearingDetail;
    const question_ids = data_files.map(dataFile => dataFile.question.id) || [];

    try {
      await this.updateHearingSet({
        id,
        title,
        status,
        question_ids,
      });

      this.isQuestionSectionTouched = false;
      this.isSortQuestionMode = false;
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
      this.submitting = false;
    }
  }

  @action.bound
  public async updateHearingSetAfterModifyingQuestion(payload: {
    id: string;
    status?: string | undefined;
    title: string;
    question_ids: number[];
  }) {
    try {
      await this.updateHearingSet(payload, true);

      this.isHearingSectionTouched = false;
      this.isQuestionSectionTouched = false;
    } catch (error) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
      this.submitting = false;
    }
  }

  @action.bound
  public resetHearingDetail() {
    this.hearingDetail = { ...defaulHearingDetail };
    this.originalHearingDetail = { ...defaulHearingDetail };
    this.isHearingSectionTouched = false;
    this.isQuestionSectionTouched = false;
    this.isSortQuestionMode = false;
    this.resetMetaQuestion();
  }

  // Handle change question detail
  @action.bound
  public handleChangeQuestionDetail(data: object) {
    this.editQuestionTouched = true;
    this.questionDetail = { ...this.questionDetail, ...data };
  }

  // Handle change video question meta
  @action.bound
  public handleChangeVideoMeta(data: object) {
    this.questionVideoMeta = { ...this.questionVideoMeta, ...data };
  }

  @action.bound
  public setSubmitting(submitting: boolean) {
    this.submitting = submitting;
  }

  @action.bound
  public injectInitialValueToFileMeta(data: Question) {
    const { data_file } = data;
    this.contentType = data_file.type_data;
    switch (data_file.type_data) {
      case 'video': {
        const meta = {
          ...this.questionVideoMeta,
          firstFrameVideo: data_file.thumbnail,
        };
        this.handleChangeVideoMeta(meta);
        this.originalContentMeta = meta;
        break;
      }
      case 'link': {
        const meta = {
          ...this.externalVideoURLMeta,
          thumbnailURL: data_file.thumbnail,
          url: data_file.url,
        };
        this.handleChangeExternalVideoURLMeta(meta);
        this.originalContentMeta = meta;
        break;
      }
      case 'image': {
        const meta = {
          ...this.imageMeta,
          src: data_file.url,
          filename: extractFileNameFromPath(data_file.url) || '',
        };
        this.handleChangeImageMeta(meta);
        this.originalContentMeta = meta;
        break;
      }
      case 'pdf': {
        const meta = {
          ...this.pdfMeta,
          thumbnailSrc: data_file.thumbnail,
          filename: extractFileNameFromPath(data_file.url) || '',
        };
        this.handleChangePDFMeta(meta);
        this.originalContentMeta = meta;
        break;
      }
      case 'none_data': {
        this.originalContentMeta = this.noContentMeta;
        break;
      }
      default:
    }
  }

  @action.bound
  public transformQuestionApiResponseToHearingDetailChildrenNodes(res: Question) {
    const { data_file } = res;
    const transformed = {
      ...data_file,
      question: {
        ...res,
      },
    };

    return (transformed as unknown) as DataFile; // FIX ME: avoid as
  }

  @action.bound
  public prepareQuestionRequestData() {
    const data = new FormData();
    data.append('type', this.contentType);

    switch (this.contentType) {
      case 'video': {
        const { file, fileThumbnail } = this.questionVideoMeta;
        const thumbnail =
          typeof fileThumbnail === 'string' ? dataURLtoFile(fileThumbnail) : fileThumbnail;

        if (file) data.append('data_file', file);
        if (fileThumbnail) data.append('thumbnail_file', thumbnail, 'thumbnail.png');
        break;
      }
      case 'link': {
        const { thumbnailFile, thumbnailURL, url } = this.externalVideoURLMeta;

        if (thumbnailFile) {
          data.append('thumbnail_file', thumbnailFile, 'thumbnail.png');
        } else {
          data.append('thumbnail', thumbnailURL);
        }

        data.append('url', url);
        break;
      }

      case 'image': {
        const { file } = this.imageMeta;
        if (file) data.append('data_file', file);
        break;
      }
      case 'pdf': {
        const { file, thumbnailSrc } = this.pdfMeta;
        if (file) {
          const thumbnail = dataURLtoFile(thumbnailSrc);
          data.append('thumbnail_file', thumbnail, 'thumbnail.png');
          data.append('data_file', file);
        }

        break;
      }
      default:
        break;
    }

    return data;
  }

  @action.bound
  public async submitAddQuestion(hearingId: string) {
    const { title, status, data_files } = this.originalHearingDetail;
    this.revertHearingDetail();
    const { completed } = this.percentUploaded;
    if (completed > 0) return;
    try {
      const { filename } = this.fileMeta;
      const { content, answers, is_multiple_choice, has_other } = this.questionDetail;
      const data = this.prepareQuestionRequestData();

      data.append('question[content]', trimSpace(content));
      data.append('question[is_multiple_choice]', is_multiple_choice ? 'true' : 'false');
      data.append('question[has_other]', has_other ? 'true' : 'false');
      data.append('question[is_required]', 'true');

      if (answers && answers.length) {
        for (let i = 0; i < answers.length; i++) {
          data.append('answers[][content]', trimSpace(answers[i].content));
          data.append('answers[][feedback]', trimSpace(answers[i].feedback || ''));
        }
      }

      const onUploadProgress = (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        this.percentUploaded = {
          filename: filename || '',
          completed: percent,
        };
      };

      this.resetMetaQuestion();
      const { data: responseData } = await this.questionApi.addQuestion({ data, onUploadProgress });
      const transformed = this.transformQuestionApiResponseToHearingDetailChildrenNodes(
        responseData
      );

      const samePath = checkPathChange(hearingId);
      if (samePath) {
        this.hearingDetail = {
          ...this.hearingDetail,
          data_files: [...data_files, transformed],
        };
      }

      const newData = [...data_files, transformed];
      const question_ids = newData.map(dataFile => dataFile.question.id) || [];

      this.updateHearingSetAfterModifyingQuestion({ title, status, id: hearingId, question_ids });

      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.createQuestionSuccess'),
        status: 'success',
      });

      this.percentUploaded = { filename: '', completed: 0 };
    } catch (error) {
      const errors = error?.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.questions.messages.createQuestionError')} ${errors}`,
        status: 'error',
      });

      this.percentUploaded = { filename: '', completed: 0 };
      this.submitting = false;
    }
  }

  @action.bound
  public async submitUpdatedQuestion(questionId: string, hearingId: string) {
    const { title, status, data_files } = this.originalHearingDetail;
    this.revertHearingDetail();
    const { completed } = this.percentUploaded;
    if (completed > 0) return;
    try {
      const { filename, file } = this.fileMeta;
      const { content, answers, is_multiple_choice, has_other } = this.questionDetail;
      const data = this.prepareQuestionRequestData();

      data.append('question[content]', trimSpace(content));
      data.append('question[is_multiple_choice]', is_multiple_choice ? 'true' : 'false');
      data.append('question[has_other]', has_other ? 'true' : 'false');
      data.append('question[is_required]', 'true');

      if (answers && answers.length) {
        for (let i = 0; i < answers.length; i++) {
          if (answers[i].id) {
            data.append('answers[][id]', answers[i].id?.toString() ?? '');
          }
          data.append('answers[][content]', trimSpace(answers[i].content));
          data.append('answers[][feedback]', trimSpace(answers[i].feedback || ''));
        }
        const answerIds = answers.map(it => it.id).filter(it => it);
        const deleteAnswerIds = this.answerCanDeleteIds.filter(it => !answerIds.includes(it));
        if (deleteAnswerIds.length) {
          data.append('delete_answer_ids', deleteAnswerIds.join(','));
        }
      }

      const onUploadProgress = (progressEvent: any) => {
        if (file) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.percentUploaded = {
            filename: filename || '',
            completed: percent,
          };
        }
      };

      this.resetMetaQuestion();
      const { data: responseData } = await this.questionApi.updateQuestion({
        id: questionId,
        data,
        onUploadProgress,
      });

      const transformed = this.transformQuestionApiResponseToHearingDetailChildrenNodes(
        responseData
      );

      const newDataFiles = data_files.map(data => {
        if (data.question.id === transformed.question.id) return transformed;

        return data;
      });

      const samePath = checkPathChange(hearingId);
      if (samePath) {
        this.hearingDetail = {
          ...this.hearingDetail,
          data_files: newDataFiles,
        };
      }

      const question_ids = newDataFiles.map(dataFile => dataFile.question.id) || [];
      this.updateHearingSetAfterModifyingQuestion({ title, status, id: hearingId, question_ids });

      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.updateQuestionSuccess'),
        status: 'success',
      });
      this.percentUploaded = { filename: '', completed: 0 };
    } catch (error) {
      const errors = error?.data?.errors?.join('; ') ?? '';

      this.pushFlashMessages({
        content: `${i18n.t('admin.questions.messages.updatedQuestionError')} ${errors}`,
        status: 'error',
      });

      this.percentUploaded = { filename: '', completed: 0 };
      this.submitting = false;
    }
  }

  @action.bound
  public resetMetaQuestion() {
    this.originalContentMeta = { ...defaultNoContentMeta };
    this.questionDetail = { ...defaultQuestionDetail };
    this.contentType = 'video';
    this.editQuestionTouched = false;
    this.editDataFileTouched = false;
    this.resetAllMeta();
  }

  @action.bound
  public resetAllMeta() {
    this.questionVideoMeta = { ...defaultVideoMeta };
    this.externalVideoURLMeta = {
      ...defaultExtrenalVideoURLMeta,
    };
    this.imageMeta = { ...defaultImageMeta };
    this.pdfMeta = { ...defaultPDFMeta };

    // Keep original content value in edit screen
    this.handleChangeContentMeta(this.originalContentMeta, false);
  }

  @action.bound
  public handleChangeContentType(type: ContentType) {
    this.contentType = type;
    this.resetAllMeta();
  }

  @action.bound
  public handleChangeExternalVideoURLMeta(meta: ExternalVideoURLMeta) {
    this.externalVideoURLMeta = { ...this.externalVideoURLMeta, ...meta };
  }

  @action.bound
  public handleChangeImageMeta(meta: UploadImageMeta) {
    this.imageMeta = { ...this.imageMeta, ...meta };
  }

  @action.bound
  public handleChangePDFMeta(meta: UploadPDFMeta) {
    this.pdfMeta = { ...this.pdfMeta, ...meta };
  }

  @action.bound
  public handleChangeContentMeta(meta: ContentMeta, touched = true) {
    switch (meta.type) {
      case 'video':
        this.handleChangeVideoMeta(meta);
        break;
      case 'link':
        this.handleChangeExternalVideoURLMeta(meta);
        break;
      case 'image':
        this.handleChangeImageMeta(meta);
        break;
      case 'pdf':
        this.handleChangePDFMeta(meta);
        break;
      case 'none_data':
        break;
      default:
        throw new Error('wrong content type');
    }

    this.editDataFileTouched = touched;
  }

  @action.bound
  public toggleCreateQuestionModal() {
    this.isCreateQuestionModalOpen = !this.isCreateQuestionModalOpen;
  }

  @action.bound
  public toggleEditQuestionModal() {
    this.isEditQuestionModalOpen = !this.isEditQuestionModalOpen;
  }

  @action.bound
  public injectDataToEditQuestionModal(data: DataFile) {
    const transformed = {
      ...data.question,
      answers: [...data.question.answers],
      data_file: {
        ...data,
      },
    };

    this.questionDetail = transformed;
    this.answerCanDeleteIds = transformed.answers.map((it: Answer) => it.id as number); // FIX ME
    this.editQuestionTouched = false;
    this.editDataFileTouched = false;
    this.injectInitialValueToFileMeta(transformed);
  }

  @action.bound
  public revertHearingDetail() {
    this.hearingDetail = this.originalHearingDetail;
  }

  @action.bound
  public revertQuestionOrder() {
    this.hearingDetail.data_files = this.originalHearingDetail.data_files;
    this.isQuestionSectionTouched = false;
  }

  @action.bound
  public toggleSortQuestionMode() {
    this.isSortQuestionMode = !this.isSortQuestionMode;
  }
}

export default HearingDetailStore;
