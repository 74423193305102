import { isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import i18n from 'i18n';
import { ja, enUS } from 'date-fns/locale';
import { ErrorTypes } from 'types/common';
import ImgWaitToUpload from 'assets/images/simpleImg.jpg';
import ImgError from 'assets/images/ErrorImg.jpg';
import { DataFile } from 'types/Question';
import { DataFile as TDataFile } from 'types/HearingSet';

function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

function formatTime(time: string, formatType: string): string {
  const language = i18n.language;
  const locale = language === 'ja' ? ja : enUS;
  if (!isValid(new Date(time))) return '';
  return format(new Date(time), formatType, { locale });
}

function formatWithJPTimeZone(time: string, formatType: string): string {
  const language = i18n.language;
  const locale = language === 'ja' ? ja : enUS;
  if (!isValid(new Date(time))) return '';
  const date = new Date(time);
  const timeZone = 'Asia/Tokyo';
  const utcTime = utcToZonedTime(date, timeZone);
  return format(utcTime, formatType, { locale, timeZone });
}

function formatError(errorsValidate: ErrorTypes): ErrorTypes {
  const obj: ErrorTypes = {};
  Object.keys(errorsValidate).forEach(key => {
    obj[key] = i18n.t(errorsValidate[key]);
  });
  return obj;
}

function dataURLtoFile(dataurl: string) {
  const pos = dataurl.indexOf(';base64,');
  const type = dataurl.substring(5, pos);
  const b64 = dataurl.substr(pos + 8);

  // decode base64
  const imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type: type });
  return blob;
}

function trimSpace(str: string) {
  return str.trim().replace(/ +(?= )/g, '');
}

function getImgWithStatus(status: string, src: string) {
  if (status === 'not_uploaded') return ImgWaitToUpload;
  if (status === 'upload_failed' || !src) return ImgError;
  if (src) return src;
}

function getImgFromDataFile(dataFile?: DataFile | TDataFile) {
  if (dataFile === undefined) return;
  switch (dataFile.type_data) {
    case 'video':
      const { thumbnail, upload_status } = dataFile;
      const status = upload_status || '';
      return getImgWithStatus(status, thumbnail);
    case 'link':
      return dataFile.thumbnail;
    case 'image':
      return dataFile.url;
    case 'pdf':
      return dataFile.thumbnail;
    case 'none_data':
      return undefined;
    default:
      return undefined;
  }
}

function validateValidEmail(email: string): boolean {
  const mailRex = /^[\w+\-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/i;
  return mailRex.test(email);
}

function extractFileExtentionFromMimeType(type: string) {
  // expecting to receive mime type string, like "image/png" "video/mp4"
  const extention = type.split('/').pop();
  return extention ? extention.toLowerCase() : '';
}

function extractFileNameFromPath(path: string) {
  const withoutQuery = path.split('?').shift();
  if (withoutQuery) return withoutQuery.split('/').pop();
}

export {
  isTouchDevice,
  formatTime,
  formatError,
  dataURLtoFile,
  formatWithJPTimeZone,
  trimSpace,
  getImgWithStatus,
  getImgFromDataFile,
  validateValidEmail,
  extractFileExtentionFromMimeType,
  extractFileNameFromPath,
};
