import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min';

import I18nProvider from './entity/I18nProvider';

import App from './App';
import GlobalStyle from './GlobalStyle';
import store from './stores';
import history from './utility/history';
import { reporttError } from './utility/reportError';

window.onunhandledrejection = (data: any) => {
  reporttError(data.reason);
};

const pdfjsWorkerBlob = new Blob([pdfjsWorker]);
const pdfjsWorkerBlobURL = URL.createObjectURL(pdfjsWorkerBlob);

// initialize pdfjsWorker
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerBlobURL;

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider {...store}>
    <I18nProvider>
      <Router history={history}>
        <App />
        <GlobalStyle />
      </Router>
    </I18nProvider>
  </Provider>,
  rootElement
);
