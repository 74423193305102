const debounce = (callback: (...args: any) => any, delay: number) => {
  let timer: NodeJS.Timeout | null;
  return (...args: any) => {
    const delayAction = () => {
      timer = null;
      callback(...args);
    };
    if (timer) clearTimeout(timer);
    timer = setTimeout(delayAction, delay);
  };
};

export default debounce;
