import { useRef, useEffect } from 'react';

const useCancelableTimer = (
  time: number,
  callback: () => void,
  shouldCancel: boolean,
  shouldInvoke = true
) => {
  const timer = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    // start timer if shouldInvoke is true
    if (timer.current === null && shouldInvoke) timer.current = setTimeout(callback, time);
  }, [callback, time, shouldInvoke]);

  useEffect(() => {
    // cancel timer if shouldCancel is true
    if (shouldCancel && timer.current) clearTimeout(timer.current);
  }, [shouldCancel, timer]);

  useEffect(() => {
    // clean up
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return { timer: timer.current };
};

export default useCancelableTimer;
