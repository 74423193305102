import React, { FC, useRef, MouseEvent, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentCLick } from 'hooks';
import NavLink from './NavigationLink';
import LanguageToggle from './LanguageToggle';
import { SidebarWrapper } from './styles';
import LogoUrl from 'assets/images/main_logo.svg';
import {
  navLinksSales,
  navLinksAdmin,
  NavLinkProps,
  navLinksSalesWithRoleAdmin,
} from './constants';

interface PropTypes {
  organizationId: string;
  isOpen: boolean;
  isDisable: boolean;
  toggleOpen: () => void;
  isAdmin: boolean;
  currentLogoUrl: string | null;
  previewMode: boolean;
  previewLogoImage: string;
  newLogoSelected: boolean;
  needsRefetchLogo: boolean;
  changeNeedsRefetchLogo: (value: boolean) => void;
  getOrganizationRole: (id: string) => void;
  userChosenLogoImageUrl: string;
  setRouteChanged(val: boolean): void;
}

const Sidebar: FC<PropTypes> = ({
  organizationId,
  isOpen,
  toggleOpen,
  isDisable,
  isAdmin,
  currentLogoUrl,
  previewMode,
  previewLogoImage,
  needsRefetchLogo,
  changeNeedsRefetchLogo,
  getOrganizationRole,
  setRouteChanged,
}) => {
  useEffect(() => {
    if (needsRefetchLogo) {
      getOrganizationRole(organizationId);
      changeNeedsRefetchLogo(false);
    }
  }, [needsRefetchLogo]);

  const { t } = useTranslation();
  const currentLogo = currentLogoUrl || LogoUrl;

  const logoImgSrc = useMemo(() => (previewMode ? previewLogoImage : currentLogo), [
    previewLogoImage,
    currentLogo,
    //remove previewMode from dependencies so that preview because previewMode is false everytime user uploads a new image
  ]);

  const navLinksSalesCheck = useMemo(() => (isAdmin ? navLinksSales : navLinksSalesWithRoleAdmin), [
    isAdmin,
  ]);
  const container = useRef<HTMLDivElement>(null);
  useDocumentCLick(handleClick);

  // click outside to close sidebar at small screen (mobile/tablet)
  function handleClick(event: MouseEvent | any): void {
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpen();
      }
    }
  }

  function handleClickNavLink(): void {
    if (isOpen) {
      toggleOpen();
    }
  }

  function renderNavLinks(navLinksData: NavLinkProps[]) {
    return navLinksData.map(link => (
      <NavLink
        isDisable={isDisable}
        key={link.id}
        activeUrl={link.activeUrl}
        iconUrl={link.iconUrl}
        handleClickNavLink={handleClickNavLink}
        label={t(link.label)}
        setRouteChanged={setRouteChanged}
      />
    ));
  }

  return (
    <SidebarWrapper ref={container} className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar_logo">
        <img src={logoImgSrc} alt="main logo" />
      </div>
      <div className={`sidebar_menubar ${isDisable ? 'disabled' : ''}`}>
        {renderNavLinks(navLinksSalesCheck)}
        {isAdmin && (
          <>
            <h3>{t('sidebar.managementTitle')}</h3>
            {renderNavLinks(navLinksAdmin)}
          </>
        )}
        <LanguageToggle />
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
