import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionHeaderWrapper } from './styles';
import { Button } from 'views/components/primitive';
import { BackButton, DeleteButton, EditButton } from 'views/components/compound';
import { Question } from 'types/Question';

interface PropTypes {
  toggleEdit?: () => void;
  title?: string;
  toggleModal(): void;
  isDelete?: boolean;
  submitCallback: () => void;
  disableSubmit?: boolean;
  disableUpdate?: boolean;
  showDetail?: boolean;
  editable?: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  questionDetail: Question;
  handleSetError: (hasError: boolean) => void;
  resetHearingSectionErrors(): void;
}

const QuestionHeaderInner: FC<PropTypes> = ({
  isDelete,
  toggleEdit,
  toggleModal,
  submitCallback,
  disableSubmit,
  disableUpdate,
  showDetail,
  editable,
  submitting,
  setSubmitting,
  questionDetail,
  handleSetError,
  resetHearingSectionErrors,
}) => {
  const { t } = useTranslation();

  const isEmptyQuestion = !questionDetail.content.trim();
  const isEmptyAnswer =
    !questionDetail.answers || !!questionDetail.answers.find(it => !it.content.trim());
  const isError = isEmptyAnswer || isEmptyQuestion;

  const handleSubmitQuestion = () => {
    if (isError) {
      handleSetError(true);
      return;
    }
    resetHearingSectionErrors();
    handleSetError(false);
    setSubmitting(true);
    submitCallback();
  };

  if (showDetail && !editable)
    return (
      <>
        <EditButton onClick={toggleEdit} />
        {isDelete && <DeleteButton size="md" onClick={toggleModal} />}
      </>
    );

  if (editable)
    return (
      <>
        <Button
          type="default"
          size="md"
          className="btn_cancel"
          label={t('common.cancel')}
          onClick={toggleModal}
        />
        <Button
          type="primary"
          size="md"
          label={submitting ? t('admin.questions.savingUpdateQuestion') : t('common.save')}
          onClick={handleSubmitQuestion}
          disabled={disableUpdate || submitting}
        />
      </>
    );

  return (
    <>
      <Button
        type="default"
        size="md"
        className="btn_cancel"
        label={t('common.cancel')}
        onClick={toggleModal}
      />
      <Button
        type="primary"
        size="md"
        disabled={disableSubmit || submitting}
        label={
          submitting ? t('admin.questions.submittingAddQuestion') : t('admin.questions.addQuestion')
        }
        onClick={handleSubmitQuestion}
      />
    </>
  );
};

const QuestionHeader: FC<PropTypes> = props => {
  const { editable, showDetail, title } = props;
  const { t } = useTranslation();
  const showBackButton = showDetail && !editable;

  return (
    <QuestionHeaderWrapper>
      {showBackButton ? (
        <BackButton title={title || ''} />
      ) : (
        <h2>{showDetail ? title : t('admin.questions.addQuestion')}</h2>
      )}
      <div className="header-actions">
        <QuestionHeaderInner {...props} />
      </div>
    </QuestionHeaderWrapper>
  );
};

export default QuestionHeader;
