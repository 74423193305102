import { palette, layer } from 'entity/createTheme';
import styled from '@emotion/styled';

interface StyleProps {
  isOpen: boolean;
}

export const SortButtonWrapper = styled('div')<StyleProps>`
  height: 42px;
  width: 110px;
  padding: 10px;
  font-size: 0.875rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${palette.grayMedium1};
  ${({ isOpen }) => isOpen && 'border-radius: 6px 6px 0 0;'}
  background-color: ${palette.whitePrimary};
  cursor: pointer;
  position: relative;

  .sort-button-dropdown {
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    width: 110px;
    background-color: ${palette.whitePrimary};
    border: 1px solid ${palette.grayMedium1};
    border-top: none;
    border-radius: 0 0 6px 6px;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    z-index: ${layer.bottomAbove1};
    font-weight: normal;

    .sort-item {
      padding: 12px 10px;

      &.active {
        background: ${palette.grayMedium6};
      }

      &:hover {
        background-color: ${palette.grayMedium5};
      }
    }
  }
`;
