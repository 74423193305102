import React, { FC } from 'react';
import DefaultAvatar from 'assets/images/ic_default_avatar.svg';
import styled from '@emotion/styled';

const WrapperUser = styled('div')`
  display: flex;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  p {
    font-size: 0.875rem;
    font-weight: bold;
  }
`;

interface PropTypes {
  userName: string;
  userAvatar: string;
}

const CreateByCard: FC<PropTypes> = ({ userName, userAvatar }) => {
  return (
    <WrapperUser>
      <img src={userAvatar || DefaultAvatar} alt="img_by_card" />
      <p>{userName}</p>
    </WrapperUser>
  );
};

export default CreateByCard;
