import styled from '@emotion/styled';
import { palette, layer } from 'entity/createTheme';

interface SelectProps {
  isOpen: boolean;
}

export const SelectWrapper = styled('div')<SelectProps>`
  cursor: pointer;
  position: relative;
  font-weight: bold;

  .select-content {
    background-color: ${palette.whitePrimary};
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${palette.grayPrimary};
    ${({ isOpen }) => isOpen && 'border-radius: 6px 6px 0 0;'};
    user-select: none;
  }

  &.sm {
    width: 150px;
    height: 32px;
    font-size: 0.75rem;
  }

  &.md {
    height: 42px;
    width: 170px;
    font-size: 0.875rem;
  }

  span {
    flex: 1;
    text-align: center;
  }

  svg {
    ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};
  }

  .select_options {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${palette.whitePrimary};
    border: 1px solid ${palette.grayPrimary};
    border-top: none;
    border-radius: 0 0 6px 6px;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    z-index: ${layer.bottomAbove1};
    overflow: hidden;

    &-item {
      padding: 12px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        width: 12px;
        transform: rotate(0);
        & > g > g {
          fill: ${palette.greenPrimary};
        }
      }
      &:hover {
        background-color: ${palette.grayMedium5};
      }
    }
  }
`;
