import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadThumbnailWrapper } from './styles';
import { DropzoneImage } from 'views/components/compound';
import InputReplaceFile from 'views/components/compound/InputReplaceFile';
import { acceptThumbnail } from 'utility/constants';
import { getImgWithStatus } from 'utility/helpers';
import { MessageProps } from 'types/App';

interface PropTypes {
  activeSrc: string;
  isDetail: boolean;
  isEdit: boolean;
  isDropzone: boolean;
  handleChangeShowImg: (data: string) => void;
  handleChangeMeta: (data: object) => void;
  setIsDropzone: (data: boolean) => void;
  setActiveThumbnail(data: string): void;
  isNotUploaded: boolean;
  firstFrameVideo: string;
  thumbnailSrcConvert: string;
  status: string;
  hasError: boolean;
  onChangeFileVideo(file: any): void;
  fileVideo: any;
  handleFlashMessage(data: MessageProps): void;
}

const UploadThumbnail: FC<PropTypes> = ({
  activeSrc,
  handleChangeShowImg,
  handleChangeMeta,
  isDetail,
  isEdit,
  isDropzone,
  setIsDropzone,
  setActiveThumbnail,
  isNotUploaded,
  firstFrameVideo,
  thumbnailSrcConvert,
  status,
  hasError,
  fileVideo,
  onChangeFileVideo,
  handleFlashMessage,
}) => {
  const { t } = useTranslation();

  function onChangeFile(file: any, isSet?: boolean) {
    const url = URL.createObjectURL(file);
    setActiveThumbnail(url);
    handleChangeMeta({
      fileThumbnail: file,
      thumbnailSrcConvert: url,
      fileThumbnailConst: file,
    });
    if (!isSet) {
      setIsDropzone(false);
    }
  }

  function handleReplaceFile(file: any) {
    onChangeFile(file, true);
  }

  function handleShowImg(it: string, isNotReady: boolean) {
    if (!isNotReady) {
      handleChangeShowImg(it);
    }
  }

  function handleError() {
    if (isDetail) {
      handleChangeMeta({
        fileThumbnail: '',
        thumbnailSrcConvert: '',
        fileThumbnailConst: '',
      });
    } else {
      onChangeFileVideo(fileVideo);
    }
    handleFlashMessage({ content: t('admin.common.notFileImage'), status: 'error' });
    setIsDropzone(true);
  }

  function renderThumbnail(it: string, isFirst?: boolean) {
    const active = it === activeSrc && 'active';
    const isNotReady = !it || isNotUploaded;
    return (
      <div
        key={it}
        className={`thumbnail-item thumbnail ${active}`}
        onClick={() => handleShowImg(it, isNotReady)}
      >
        <img
          src={!isFirst ? getImgWithStatus(status, it) : it}
          alt="thumbnail-item"
          className={isNotReady || status === 'upload_failed' ? 'not-ready' : ''}
          onError={handleError}
        />
        {(isEdit || !isDetail) && isFirst && (
          <InputReplaceFile
            id="replace_thumbnail"
            className="thumbnail-replace"
            handleChange={handleReplaceFile}
            accept={acceptThumbnail.join(', ')}
          />
        )}
      </div>
    );
  }

  return (
    <UploadThumbnailWrapper>
      <h3 className="thumbnail-title">{t('admin.common.thumbnail')}</h3>
      <div className="thumbnail-content">
        {isDropzone ? (
          <DropzoneImage
            className="thumbnail-item dropzone-img"
            onChange={onChangeFile}
            disabled={
              isNotUploaded || (status === 'upload_failed' && !fileVideo)
                ? true
                : isDetail && !isEdit
            }
          />
        ) : (
          renderThumbnail(thumbnailSrcConvert, true)
        )}
        {!hasError && renderThumbnail(firstFrameVideo)}
      </div>
    </UploadThumbnailWrapper>
  );
};

export default UploadThumbnail;
