import HttpClient from 'adapter/HTTPClient';
import { ParsedQs } from 'qs';
import { Creator } from 'types/App';
import { ListRequestParamsMeta } from 'types/common';
import {
  HearingLinkClient,
  HearingLink,
  HearingLinkItem,
  LinkSettingOption,
} from 'types/HearingLink';
import { ProductItem } from 'types/Product';
import { DataFile } from 'types/Question';

type HearingLinkPlayResponse = {
  title?: string;
  created_by: Creator;
  hearing_set_id: number | null;
  product_videos?: ProductItem[];
  data_files?: DataFile[];
  needs_feedback: boolean;
  organization_logo: string | null;
  cta_redirect: boolean;
  cta_redirect_to: string | null;
  cta_description?: string;
  cta_auto_redirect?: boolean;
};

type LinkListResponse = {
  hearing_links: HearingLinkItem[];
  total?: number;
};

type LinkArchiveListResponse = {
  hearing_links: HearingLinkItem[];
  page: number;
  total: number;
};

class HearingLinkApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getHearingLinks = async ({ page, order, sort, per_page }: ListRequestParamsMeta) => {
    const res = this.httpClient.request<LinkListResponse>({
      method: 'GET',
      url: 'hearing_links',
      params: {
        page,
        order,
        sort,
        per_page,
      },
    });

    return res;
  };

  public getHearingLinkById = async (id: string) => {
    const res = this.httpClient.request<HearingLink>({
      method: 'GET',
      url: `hearing_links/${id}`,
    });

    return res;
  };

  public getHearingLinkByToken = async (token: string, email?: string) => {
    const res = await this.httpClient.request<HearingLinkPlayResponse>({
      url: `hearing_links/link_detail/${token}${email ? `?email=${email}` : ''}`,
    });

    return res;
  };

  public getClients = async () => {
    const res = await this.httpClient.request<HearingLinkClient[]>({
      method: 'GET',
      url: 'hearing_links/list_clients',
    });

    return res;
  };

  public getArchivedHearingLinks = async ({
    page,
    order,
    sort,
    per_page,
  }: ListRequestParamsMeta) => {
    const res = await this.httpClient.request<LinkArchiveListResponse>({
      method: 'GET',
      url: 'hearing_links/archives',
      params: {
        page,
        order,
        sort,
        per_page,
      },
    });

    return res;
  };

  // FIX ME: type data
  public createHearingLink = async (data: any) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'hearing_links',
      data,
    });
  };

  public createHearingLinkResult = async (
    token: string,
    email: string,
    data: {
      company_name: string;
      memo: string;
      ma_params: ParsedQs;
      ma_link: string;
    }
  ) => {
    await this.httpClient.request({
      method: 'POST',
      url: `hearing_links/link_detail/${token}?email=${email}`,
      data,
    });
  };

  public createVideoLinkResult = async (
    token: string,
    email: string,
    data: {
      company_name: string;
      memo: string;
      ma_params: ParsedQs;
      ma_link: string;
    }
  ) => {
    await this.httpClient.request({
      method: 'POST',
      url: `hearing_links/link_detail/product_video/${token}?email=${email}`,
      data,
    });
  };

  public updateHearingLinkById = async ({
    id,
    option,
  }: {
    id: string;
    option: LinkSettingOption;
  }) => {
    await this.httpClient.request({
      method: 'PUT',
      url: `hearing_links/${id}`,
      params: { ...option },
    });
  };

  public updateHearingLinkStatus = async ({ ids, status }: { ids: number[]; status: string }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'hearing_links/update_status',
      params: {
        ids,
        status,
      },
    });
  };

  // FIX ME: type data
  public updateHearingLinkNotes = async ({ id, data }: { id: string; data: any }) => {
    await this.httpClient.request({
      method: 'PUT',
      url: `hearing_links/${id}/update_notes`,
      data,
    });
  };
}

export default HearingLinkApi;
