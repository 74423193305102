import React, { FC } from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utility/helpers';
import { useLocation } from 'react-router-dom';
import { routes } from 'utility/constants';
import { WrapperProductItem } from './styles';
import { Button, Checkbox } from 'views/components/primitive';
import { SelectStatus } from 'views/components/compound';
import { ProductItem as ProductItemTypes } from 'types/Product';
import { getImgWithStatus } from 'utility/helpers';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';

interface PropTypes {
  data: ProductItemTypes;
  isAdmin?: boolean;
  isArchive?: boolean;
  isSelected?: boolean;
  isSelectProduct?: boolean;
  status?: string;
  handleStartHearing?: (id: number) => void;
  handleArchiveProduct?: (id: number) => void;
  onChangeSelected?: (e: number) => void;
  onChangeStatus?: (id: number, val: string, confirm?: boolean) => void;
  viewDetailProduct?: (id: number) => void;
  reStoreArchive?: (id: number) => void;
  changeTargetVideo?: (id: number, val: string) => void;
  clickable?: boolean;
}

const ProductItem: FC<PropTypes> = ({
  isSelectProduct,
  isSelected,
  isAdmin,
  isArchive,
  data,
  status,
  handleStartHearing,
  onChangeSelected,
  onChangeStatus,
  viewDetailProduct,
  reStoreArchive,
  handleArchiveProduct,
  changeTargetVideo,
  clickable,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    appStore: { handleFlashMessage },
  } = useStore();

  const videos: { manifestId: string; manifestUrl: string }[] = [];
  if (data.manifest_id && data.manifest_url) {
    videos.push({ manifestId: data.manifest_id, manifestUrl: data.manifest_url });
  }

  function startHearing() {
    if (handleStartHearing) {
      handleStartHearing(data.id);
    }
  }

  function handleChangeSelected() {
    if (onChangeSelected) {
      onChangeSelected(data.id);
    }
  }

  function handleChangeStatus(val: string) {
    if (changeTargetVideo) changeTargetVideo(data.id, val);
    if (onChangeStatus) {
      onChangeStatus(data.id, val);
    }
  }

  function handleViewDetail() {
    if (viewDetailProduct) {
      viewDetailProduct(data.id);
    }
    if (isArchive) {
      handleFlashMessage({ content: t('admin.common.notViewDetail'), status: 'warning' });
    }
  }

  function handleReStoreArchive() {
    if (reStoreArchive) {
      reStoreArchive(data.id);
    }
  }

  return (
    <WrapperProductItem
      clickable={clickable}
      {...{ onClick: clickable ? handleViewDetail : undefined }}
    >
      {videos.length > 0 && <BulkDownloader videos={videos} />}
      <div className="prod-item_thumbnail">
        <img src={getImgWithStatus(data.upload_status || '', data.thumbnail)} alt="prod-img" />
      </div>
      <div className="prod-item_content">
        <p
          className={`prod-title ${pathname === routes.productVideo && 'disabled'}`}
          onClick={handleViewDetail}
        >
          {data.title}
        </p>
        <p className="prod-time">
          {t('hearingSet.hearingItem.lastUpdated')}: {formatTime(data.updated_at, 'yyyy/MM/dd')}
        </p>
        <div className="prod-action">
          {isSelectProduct && (
            <Checkbox
              className="prod-action_checkbox"
              checked={isSelected}
              onChange={handleChangeSelected}
            />
          )}
          {!isSelectProduct && !isAdmin && (
            <Button
              type="primary"
              size="sm"
              label={t('productVideo.startAppointment')}
              onClick={startHearing}
            />
          )}
          {!isSelectProduct && isAdmin && !isArchive && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <SelectStatus size="sm" value={status || ''} onChangeStatus={handleChangeStatus} />
            </div>
          )}
          {!isSelectProduct && isAdmin && isArchive && (
            <Button
              type="default"
              size="sm"
              label={t('admin.common.restore')}
              onClick={handleReStoreArchive}
            />
          )}
        </div>
      </div>
    </WrapperProductItem>
  );
};

export default observer(ProductItem);
