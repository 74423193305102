import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { palette, bp } from 'entity/createTheme';
import LoadingIndicator from '../LoadingIndicator';
import CacheablePlayer from '../CacheablePlayer';
import { isTouchDevice } from 'utility/helpers';
import styled from '@emotion/styled';
import { SVGIcon } from 'views/components/primitive';
import ReloadIcon from 'assets/images/ic_reload_player.svg';

const style = css`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${palette.blackPrimary};

  .loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

interface PropTypes {
  width: string;
  height: string;
  url: string;
  status: string;
  manifestId: string;
  manifestUrl: string;
  isVimeo?: boolean;
}

const VideoPlayer: FC<PropTypes> = ({
  width,
  height,
  url,
  status,
  manifestId,
  manifestUrl,
  isVimeo = true,
}) => {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [muted, setMuted] = useState(false);
  useEffect(() => {
    setReady(false);
    setHasError(false);
  }, [url]);

  useEffect(() => {
    if (isTouchDevice() && !muted) {
      setMuted(true);
    }
  }, []);

  function handleReady() {
    setReady(true);
  }

  function handleError(error: any) {
    const notAllowedError = error.name === 'NotAllowedError';
    if (notAllowedError) return;
    setHasError(true);
  }

  const handleReloadPlayer = () => {
    setHasError(false);
  };

  if ((status === 'not_uploaded' && !url) || hasError || status === 'upload_failed') {
    return (
      <ErrorMessage>
        <p id="video-player__error">
          {status === 'upload_failed'
            ? t('common.videoUploadFailed')
            : t('common.videoNotavailable')}
        </p>
        {status !== 'upload_failed' && (
          <ReloadBtn onClick={handleReloadPlayer} id="video-player__reload">
            <SVGIcon src={ReloadIcon} />
            {t('common.reload')}
          </ReloadBtn>
        )}
      </ErrorMessage>
    );
  }
  return (
    <Root id="video-player-wrapper">
      <div css={style} className="video-player">
        {!ready && <LoadingIndicator />}
        {url && (
          <CacheablePlayer
            muted={muted}
            width={width}
            height={height}
            url={url}
            manifestId={manifestId}
            manifestUrl={manifestUrl}
            onReady={handleReady}
            onError={handleError}
            isVimeo={isVimeo}
          />
        )}
      </div>
    </Root>
  );
};

export default VideoPlayer;

const Root = styled('div')({
  height: 511,
  [`@media ${bp.md}`]: {
    width: '100%',
    marginBottom: 8,
  },

  [`@media ${bp.xmd}`]: {
    height: 480,
  },

  [`@media ${bp.sm}`]: {
    height: 320,
  },

  [`@media ${bp.xs}`]: {
    height: 240,
  },
});

const ErrorMessage = styled('div')({
  width: '100%',
  height: 511,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: palette.blackPrimary,
  p: {
    color: palette.whitePrimary,
  },

  [`@media ${bp.xmd}`]: {
    height: 480,
  },

  [`@media ${bp.sm}`]: {
    height: 320,
  },

  [`@media ${bp.xs}`]: {
    height: 240,
  },
});

const ReloadBtn = styled('button')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: palette.whitePrimary,
  background: 'transparent',
  textDecoration: 'underline',
  border: 'none',
  marginTop: 10,
  fontSize: 14,
  svg: {
    marginRight: 5,
  },
  '&:focus': {
    outline: 'none',
  },
});
