import HttpClient from 'adapter/HTTPClient';
class OrganizationApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getOrganizationById = async (id: string) => {
    const res = await this.httpClient.request<{
      id: string;
      roles: string[];
      organization_logo: string | null;
    }>({
      method: 'GET',
      url: `organizations/${id}`,
    });

    return res;
  };
}

export default OrganizationApi;
