import { useRef, useState, useEffect } from 'react';

const MILLI_SECOND = 1000;

const useSecondsTimer = (count: number) => {
  const timer = useRef<null | NodeJS.Timeout>(null);
  const [seconds, setSeconds] = useState(count);

  const decrement = () => {
    if (seconds <= 0) return;
    setSeconds(val => val - 1);
  };

  useEffect(() => {
    // stop counting if count hits 0
    if (timer.current && seconds <= 0) clearInterval(timer.current);
  }, [seconds]);

  useEffect(() => {
    timer.current = setInterval(decrement, MILLI_SECOND);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, []);

  return seconds;
};

export default useSecondsTimer;
