import { observable, action } from 'mobx';
import { routes, hearingLinkStatus } from 'utility/constants';
import History from 'utility/history';
import i18n from 'i18n';
import { fromJS } from 'immutable';
import { HearingItem } from 'types/HearingSet';
import {
  HearingLink,
  HearingLinkClient,
  HearingLinkItem,
  LinkSettingOption,
  RedirectSettingError,
} from 'types/HearingLink';
import { ProductItem } from 'types/Product';
import { MessageProps } from 'types/App';
import { ListMeta } from 'types/common';
import { RootStore } from '../index';
import { defaultHearingLink, defaultListMeta } from './constants';
import HearingSetApi from 'services/HearingSetApi';
import ProductVideoApi from 'services/ProductVideoApi';
import HearingLinkApi from 'services/HearingLinkApi';

class HearingLinkStore {
  private readonly hearingSetApi: HearingSetApi;
  private readonly productVideoApi: ProductVideoApi;
  private readonly hearingLinkApi: HearingLinkApi;

  @observable public loading = false;
  @observable public isProductVideo = false;
  @observable public modalProductVideoOpen = false;
  @observable public productVideoList: ProductItem[] = [];
  @observable public modalHearingOpen = false;
  @observable public error = {};
  @observable public hearingLinkList: HearingLinkItem[] = [];
  @observable public linkListMeta: ListMeta = { ...defaultListMeta };
  @observable public hearingList: HearingItem[] = [];
  @observable public keySearch = '';
  @observable public hearingListConst: HearingItem[] = [];
  @observable public clientList: HearingLinkClient[] = [];
  @observable public hearingLinkDetail: HearingLink = { ...defaultHearingLink };
  @observable public rootStore: RootStore;
  @observable public hearingLinkArchives: HearingLinkItem[] = [];
  @observable public redirectSettingError: RedirectSettingError = {};

  constructor({
    rootStore,
    hearingSetApi,
    productVideoApi,
    hearingLinkApi,
  }: {
    rootStore: RootStore;
    hearingSetApi: HearingSetApi;
    productVideoApi: ProductVideoApi;
    hearingLinkApi: HearingLinkApi;
  }) {
    this.rootStore = rootStore;
    this.hearingSetApi = hearingSetApi;
    this.productVideoApi = productVideoApi;
    this.hearingLinkApi = hearingLinkApi;
  }

  @action.bound
  public handleChangeNoteResult(resultId: number, questionId: number, val: string) {
    const { results } = this.hearingLinkDetail;
    const resultIndex = results.findIndex(res => res.id === resultId);
    const questionIndex = results[resultIndex].data_files.findIndex(dataFile => {
      if (dataFile.question.question_answer === null) return false;
      return dataFile.question.question_answer.id === questionId;
    });

    const linkDetailJS = fromJS(this.hearingLinkDetail);
    this.hearingLinkDetail = linkDetailJS
      .setIn(
        [
          'results',
          resultIndex,
          'data_files',
          questionIndex,
          'question',
          'question_answer',
          'note',
        ],
        val
      )
      .setIn(['results', resultIndex, 'notDisabledSave'], true)
      .toJS();
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleModalHearing() {
    this.modalHearingOpen = !this.modalHearingOpen;
  }

  @action.bound
  public toggleModalProductVideo() {
    this.modalProductVideoOpen = !this.modalProductVideoOpen;
  }

  @action.bound
  public handleChangeLinkType(value: string) {
    if (value === 'productVideo') {
      this.isProductVideo = true;
      this.handleChangeDetail({ hearing_set: null });
    } else {
      this.isProductVideo = false;
      this.handleChangeDetail({ product_videos: [], product_video_ids: [] });
    }
  }

  @action.bound
  public getSelectedVideos = (ids: number[]) => {
    const selectedVideos = this.productVideoList.filter(video => ids.includes(video.id));
    this.handleChangeDetail({ product_videos: selectedVideos });
  };

  @action.bound
  public async getInitialData() {
    try {
      this.loading = true;
      const result = await Promise.all([
        this.hearingSetApi.getHearingSets(),
        this.hearingLinkApi.getClients(),
        this.productVideoApi.getProductVideos({
          page: 1,
          per_page: 50,
        }),
      ]);
      this.hearingListConst = result[0].data.hearing_sets;
      this.hearingList = result[0].data.hearing_sets;
      this.clientList = result[1].data;
      this.productVideoList = result[2].data.product_videos;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public handleSearchHearing(value: string) {
    this.keySearch = value;
    this.hearingList = this.hearingListConst.filter(it =>
      it.title
        .trim()
        .toLocaleLowerCase()
        .includes(value.trim().toLocaleLowerCase())
    );
  }

  @action.bound
  public handleChangeAccount(value: any, isNew?: boolean) {
    if (value) {
      this.hearingLinkDetail = {
        ...this.hearingLinkDetail,
        client_id: isNew ? null : value,
        client_name: isNew ? value : null,
      };
    } else {
      this.hearingLinkDetail = {
        ...this.hearingLinkDetail,
        client_id: null,
        client_name: null,
      };
    }
  }

  @action.bound
  public handleChangeDetail(obj: any) {
    this.hearingLinkDetail = { ...this.hearingLinkDetail, ...obj };
  }

  @action.bound
  public async handleCreateNewHearingLink() {
    try {
      const {
        client_id,
        client_name,
        is_required_email,
        hearing_set,
        product_video_ids,
        needs_feedback,
        cta_redirect,
        cta_redirect_to,
        cta_description,
        cta_auto_redirect,
      } = this.hearingLinkDetail;

      const data = {
        is_product_video: this.isProductVideo,
        product_video_ids: product_video_ids,
        is_required_email,
        needs_feedback,
        cta_redirect,
        ...(cta_redirect && { cta_redirect_to }),
        ...(cta_redirect && cta_description && { cta_description }),
        ...(cta_redirect && cta_auto_redirect && { cta_auto_redirect }),
        ...{ hearing_set_id: hearing_set ? hearing_set.id : null },
        ...(client_id && { client_id }),
        ...(client_name && { client_name }),
      };

      this.loading = true;
      await this.hearingLinkApi.createHearingLink(data);
      this.hearingLinkDetail = { ...defaultHearingLink };
      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.createHearingLinkSuccess'),
        status: 'success',
      });
      History.push(routes.hearingLink);
    } catch (error) {
      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.createHearingLinkFail'),
        status: 'error',
      });
      this.handleChangeDetail({ client_name: null });
      this.handleChangeDetail({ client_id: null });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getHearingLinkList(page = 1, order = 'updated_at', sort = 'desc') {
    try {
      this.loading = true;
      const { data } = await this.hearingLinkApi.getHearingLinks({
        page,
        order,
        sort,
        per_page: 20,
      });
      this.hearingLinkList = data.hearing_links;
      this.linkListMeta = { ...this.linkListMeta, total: data.total, page, order, sort };
    } catch (error) {
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getHearingLinkDetail(id: string) {
    try {
      this.loading = true;
      const { data } = await this.hearingLinkApi.getHearingLinkById(id);
      this.hearingLinkDetail = data;
      this.getInitialData();
    } catch (error) {
      if (error.status !== 404) {
        this.putFlashMessages({
          content: i18n.t('hearingLink.messages.notFoundHearingLink'),
          status: 'error',
        });
      }
      History.push(routes.hearingLink);
      this.loading = false;
    }
  }

  // FIX ME: separate this function into 2 where so we don't have to pass optional boolean flags:
  //  - isDetail true
  //  - isDetail false

  @action.bound
  public async updateStatusHearingLink(
    id: number,
    status: string,
    isDetail?: boolean,
    isHearingSet?: boolean
  ) {
    try {
      await this.hearingLinkApi.updateHearingLinkStatus({
        ids: [id],
        status,
      });
      if (!isDetail) {
        if (status === hearingLinkStatus.archived) {
          const { page, sort, order } = this.linkListMeta;
          this.getHearingLinkList(page, order, sort);
        } else {
          this.hearingLinkList = this.hearingLinkList.map(it => {
            if (it.id === id) return { ...it, status };
            return it;
          });
        }
      } else {
        this.hearingLinkDetail = { ...this.hearingLinkDetail, status };
      }
      if (status === hearingLinkStatus.archived) {
        this.putFlashMessages({
          content: i18n.t('admin.common.messages.archiveSuccess', {
            item: i18n.t('common.actionTarget.links'),
          }),
          status: 'success',
        });
      } else {
        this.putFlashMessages({
          content: i18n.t('admin.productVideo.messages.changeStatusSuccess'),
          status: 'success',
        });
      }
    } catch (error) {
      if (error.status === 403) {
        this.putFlashMessages({
          content: isHearingSet
            ? i18n.t('hearingLink.noPublicHearing')
            : i18n.t('hearingLink.noPublicProductVideo'),
          status: 'error',
        });
        return;
      }
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  @action.bound
  public async changeLinkOption(id: string, option: LinkSettingOption) {
    try {
      await this.hearingLinkApi.updateHearingLinkById({
        id,
        option,
      });
      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.updateHearingLinkSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.error = error;
      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.updateHearingLinkFail'),
        status: 'error',
      });
    }
  }

  @action.bound
  public resetHearingLink() {
    this.hearingLinkDetail = { ...defaultHearingLink };
  }

  @action.bound
  public async getHearingLinkArchive(page = 1, order = 'updated_at', sort = 'desc') {
    this.loading = true;
    try {
      const { data } = await this.hearingLinkApi.getArchivedHearingLinks({
        page,
        order,
        sort,
        per_page: 20,
      });

      this.hearingLinkArchives = data.hearing_links;
      this.linkListMeta = {
        ...this.linkListMeta,
        page: data.page,
        sort,
        order,
        total: data.total,
      };
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async restoreHearingLink(id?: number, isDetail?: boolean) {
    try {
      const listId = id ? [id] : [];
      await this.hearingLinkApi.updateHearingLinkStatus({
        ids: listId,
        status: 'inactive',
      });

      if (!isDetail) {
        const { page, sort, order } = this.linkListMeta;
        this.getHearingLinkArchive(page, order, sort);
      } else {
        if (id) {
          this.getHearingLinkDetail(id.toString());
        }
      }

      this.putFlashMessages({
        content: i18n.t('admin.common.messages.restoreSuccess', {
          item: i18n.t('common.actionTarget.links'),
        }),
        status: 'success',
      });
    } catch (error) {
      this.error = error;
    }
  }

  @action.bound
  public async submitSaveNote(idResult: number, linkId: string) {
    try {
      const { results } = this.hearingLinkDetail;
      const indexResult = results.findIndex(res => res.id === idResult);
      const { data_files } = results[indexResult];
      const params = data_files
        .map(video => {
          if (video.question.question_answer) {
            const { id, note } = video.question.question_answer;
            return { id, note };
          }
          return { id: null, note: null };
        })
        .filter(ps => ps.note);

      await this.hearingLinkApi.updateHearingLinkNotes({
        id: linkId,
        data: {
          question_answers: params,
        },
      });

      const linkDetailJS = fromJS(this.hearingLinkDetail);
      this.hearingLinkDetail = linkDetailJS
        .setIn(['results', indexResult, 'notDisabledSave'], false)
        .toJS();

      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.updateHearingLinkSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.putFlashMessages({
        content: i18n.t('hearingLink.messages.updateHearingLinkFail'),
        status: 'error',
      });
    }
  }

  @action.bound
  public updateRedirectSettingError(error: RedirectSettingError) {
    this.redirectSettingError = { ...this.redirectSettingError, ...error };
  }

  @action.bound
  public clearRedirectSettingError() {
    this.redirectSettingError = {};
  }
}

export default HearingLinkStore;
