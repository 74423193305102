import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { bp, layer, sizes } from 'entity/createTheme';
import { Question } from 'types/Question';
import {
  UploadVideoMeta,
  UploadImageMeta,
  ExternalVideoURLMeta,
  UploadPDFMeta,
  ContentType,
  ContentMeta,
} from 'types/App';
import {
  QuestionContentModal,
  ConfirmModal as ConfirmCancelModal,
  LoadingIndicator,
} from 'views/components/compound';
import {
  QuestionHeader,
  QuestionContent,
  UploadContent,
} from 'views/components/compound/QuestionForm';

type Props = {
  questionDetail: Question;
  handleChangeDetail(data: object): void;
  resetMetaQuestion(): void;
  submitAddQuestion(): void;
  // content type and payload observables for create question
  contentType: ContentType;
  questionVideoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  disableSubmit: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;

  isCreateQuestionModalOpen: boolean;
  toggleCreateQuestionModal: () => void;
  resetHearingSectionErrors(): void;
};

const CreateQuestionModal: FC<Props> = ({
  questionDetail,
  handleChangeDetail,
  resetMetaQuestion,
  submitAddQuestion,
  contentType,
  questionVideoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
  disableSubmit,
  submitting,
  setSubmitting,

  isCreateQuestionModalOpen,
  toggleCreateQuestionModal,
  resetHearingSectionErrors,
}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { firstFrameVideo, file, error, fileThumbnail } = useMemo(() => questionVideoMeta, [
    questionVideoMeta,
  ]);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);

  useEffect(() => {
    if (!file) {
      setIsLoadingFrame(false);
      return;
    }
    if (error) {
      setIsLoadingFrame(false);
    } else {
      setIsLoadingFrame(true);
    }
  }, [file, error]);

  useEffect(() => {
    if (firstFrameVideo || fileThumbnail) {
      setIsLoadingFrame(false);
    }
  }, [firstFrameVideo, fileThumbnail]);

  useEffect(() => {
    return () => {
      resetMetaQuestion();
    };
  }, []);

  function toggleModal() {
    setIsCancelModalOpen(!isCancelModalOpen);
  }

  function handleCancelUpload() {
    resetMetaQuestion();
    toggleCreateQuestionModal();
  }

  function handleSetError(hasError: boolean) {
    setHasError(hasError);
  }

  function handleAddQuestion() {
    toggleCreateQuestionModal();

    switch (contentType) {
      case 'video':
        if (questionVideoMeta.fileThumbnail) submitAddQuestion();
        break;
      case 'link':
      case 'image':
      case 'pdf':
      case 'none_data':
        submitAddQuestion();
        break;
      default:
    }
  }

  function handleConfirmModal() {
    setHasError(false);
    handleCancelUpload();
  }

  return (
    <QuestionContentModal isOpen={isCreateQuestionModalOpen}>
      {isLoadingFrame && (
        <LoadingFrame>
          <LoadingIndicator />
        </LoadingFrame>
      )}
      <QuestionHeader
        toggleModal={toggleModal}
        submitCallback={handleAddQuestion}
        disableSubmit={disableSubmit}
        submitting={submitting}
        setSubmitting={setSubmitting}
        questionDetail={questionDetail}
        handleSetError={handleSetError}
        resetHearingSectionErrors={resetHearingSectionErrors}
      />
      <UploadContent
        questionDetail={questionDetail}
        contentType={contentType}
        questionVideoMeta={questionVideoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentType={handleChangeContentType}
        handleChangeContentMeta={handleChangeContentMeta}
      />
      <QuestionContent
        questionDetail={questionDetail}
        handleChangeDetail={handleChangeDetail}
        hasError={hasError}
      />
      <ConfirmCancelModal
        isModal={isCancelModalOpen}
        toggleModal={toggleModal}
        handleConfirmModal={handleConfirmModal}
      />
    </QuestionContentModal>
  );
};

const LoadingFrame = styled('div')({
  position: 'absolute',
  width: `calc(100% - ${sizes.sizebarWidth})`,
  height: '100%',
  left: sizes.sizebarWidth,
  top: 0,
  background: `rgba(0, 0, 0, 0.3)`,
  zIndex: layer.bottomAbove2,
  [`@media ${bp.sm}`]: {
    width: '100%',
    left: 0,
  },
});

export default CreateQuestionModal;
