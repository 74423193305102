export const defaultHearingPlayMeta = {
  hearingTime: '',
  activeHearingIndex: 0,
  activeContentIndex: 0,
};

export const defaultHearingMemo = {
  company_name: '',
  company_pic_name: '',
  memo: '',
};
