import { Languages } from './models';

const en: Languages = {
  common: {
    notFound: '404 Not Found',
    sort: 'Sort',
    select: 'Select',
    add: 'Add',
    save: 'Save',
    saving: 'Saving',
    edit: 'Edit',
    cancel: 'Cancel',
    ok: 'OK',
    sortLatest: 'Latest',
    sortOldest: 'Oldest',
    sortASC: 'A - Z',
    sortDESC: 'Z - A',
    createAppointment: 'Create Appointment',
    startHearing: 'Start Hearing',
    infomation: 'Infomation',
    companyName: 'Company Name',
    externalClientId: 'Client ID',
    customerName: 'Customer Name',
    timeTitle: 'Time',
    byTitle: 'By',
    customerTitle: 'Customer',
    memoTitle: 'Memo',
    loginTitle: 'Login With Kaizen ID',
    logoutTitle: 'Logout',
    openAccountConsole: 'Open Account Console',
    leaveConfirmMessage: 'All data will be cleared, are you sure?',
    yesText: 'Yes',
    noText: 'No',
    confirmCancel: 'Are you sure you want to cancel?',
    confirmLeave: 'Are you sure you want to leave?',
    warningUndone: 'This cannot be undone!',
    appointment: 'Appointment',
    emptyMessages: 'Your collection list is empty!',
    emptySearchResult: 'No item matches your search!',
    accountSetting: 'Account Setting',
    link: 'Link',
    send: 'Send',
    videoNotavailable: 'This video will be available for viewing shortly !',
    videoUploadFailed: 'Failed to upload video. Please contact your admin.',
    comment: 'comment',
    csvDownload: 'Download CSV',
    csvDownloadFailed: 'Failed to download CSV',
    csvDownloadOption: {
      utf8: 'UTF-8',
      shiftjis: 'SHIFT-JIS',
    },
    reload: 'Reload',
    actionTarget: {
      hearings: 'hearing set',
      products: 'video',
      links: 'shareable link',
    },
    noOrganizationMessage:
      "Can not access since you don't belong to an organization. Please ask your admin for invitation.",
  },
  sidebar: {
    productVideo: 'Product Video',
    hearings: 'Hearing Set',
    quickHearing: 'Quick Hearing',
    managementTitle: 'Management',
    hearingLink: 'Shareable Link',
    setting: 'Settings',
  },
  hearingSet: {
    searchPlaceholder: 'Find a hearing set, appointment...',
    hearingSetTitle: 'Hearing Set',
    hearingItem: {
      numOfVideo: '{{count}} Questions',
      lastUpdated: 'Last updated',
    },
  },
  quickHearing: {
    quickHearingTitle: 'Quick Hearing',
    addAppointment: 'Add Appointment',
    viewResult: 'View Result',
    notFoundQuickHearings: 'Quick hearings does not exist!',
    clientIdToolTip: '',
  },
  hearingPlay: {
    previousVideo: 'Previous Video',
    nextVideo: 'Next Video',
    previousQuestion: 'Previous Question',
    nextQuestion: 'Next Question',
    endHearing: 'End Hearing',
    endWatching: 'End Watching',
    endAppointment: 'End Appointment',
    end: 'End',
    cancelHearing: 'Cancel Hearing',
    otherAnswerPlaceHolder: 'Your comment',
    result: {
      title: 'Hearing Result',
      watchTitle: 'Watching Result',
      answerLabel: 'Answers',
      feedbackLabel: 'Feedback for answer',
      noteLabel: 'Note',
      addHearingSet: 'Add Hearing Set',
      addProduct: 'Add Product Videos',
      companyLabel: 'Company Name - Optional',
      customerNameLabel: 'Customer Name - Optional',
      createMemo: 'Create Memo',
      memoLabel: 'Memo',
      namePlaceholder: 'Enter name',
      memoPlaceholder: 'Enter memo',
      buttonSave: 'Save and Close',
      searchHearingPlaceholder: 'Find a hearing set',
      endHearing: 'End Hearing',
      otherAnswerLabel: 'Other',
    },
    messages: {
      createQuickHearingSuccess: 'Create Quick Hearing Success!',
    },
    questionTypeLabel: {
      video: 'VIDEO',
      link: 'VIDEO',
      pdf: 'PDF',
      image: 'IMAGE',
      none_data: 'NONE',
    },
    noneDataMessage: 'This question has no attached content.',
  },
  productVideo: {
    title: 'Product Video',
    startAppointment: 'Start Appointment',
    createDate: 'Date Created',
    ortherVideo: 'Other Videos',
    searchVideoPlaceholder: 'Search videos',
    showMoreVideos: 'Show More Videos',
  },
  createAppointment: {
    appointmentListTitle: 'Appointment List',
    messages: {
      createAppointmentSuccess: 'Create Appointment Success!',
    },
    errors: {
      companyName: 'Company Name is required',
    },
  },
  appointmentDetail: {
    title: 'Appointment Details',
    deleteSuccess: 'Appointment deleted',
    updateSuccess: 'Appointment updated',
  },
  hearingLink: {
    creator: 'Creator',
    createHearingTitle: 'Create Link',
    linkInfoTitle: 'Link Information',
    accountLabel: 'Account',
    noSpecifiedAccount: 'No Specified Account',
    settingLabel: 'Settings',
    mailMainLabel: 'Require email to view',
    mainSkipResultsLabel: 'Skip Results Screen',
    subSkipResultsLabel: "Viewers can't view Results Screen",
    mainHideCreatorLabel: 'Hide Creator',
    subHideCreatorLabel: 'Hide Creator on Hearing Screen',
    mainRedirectLabel: 'URL Redirect',
    subRedirectLabel: 'Transfer viewers to new website automatically in Finish Hearing Screen',
    redirectURLSetting: {
      urlSetting: 'URL Setting',
      url: 'URL',
      urlPlaceholder: 'Enter url',
      description: 'Description',
      descriptionPlaceholder:
        'E.g: If you are interested, please arrange your schedule for an appointment',
      autoRedirect: 'Auto Redirect',
      urlNotSet: 'You have not added URL redirect',
      invalidUrl: 'Invalid URL',
      requireUrl: 'Please enter an URL',
      exceedLimitURL: 'URL must not exceed 200 characters',
      exceedLimitDescription: 'Description must not exceed 200 characters',
    },
    mailSubLabel: 'Viewers must enter an email to view your document',
    createNewOption: 'Create new account',
    switchLink: 'ON/OFF Link',
    createdByLabel: 'Created By',
    clientCommentTitle: 'FEEDBACK COMMENT',
    inputEmailTitle: 'PLEASE INPUT YOUR EMAIL',
    clientCommentPlaceHolder:
      "e.g. I'd like to learn more about services and would like to have a meeting.",
    hearingCompleteTitle: 'COMPLETE HEARING',
    watchingCompleteTitle: 'COMPLETE WATCHING',
    hearingCompleteDescription: 'Thanks for your answer. The result has been sent to sales.',
    confirmEndHearingTitle: 'END HEARING',
    confirmEndHearingDescription: 'Are you sure you want to end hearing?',
    confirmEndHearingAgree: 'Yes, End Hearing',
    confirmEndHearingDisagree: 'No, Cancel',
    confirmEndWatchingTitle: 'END WATCHING',
    confirmEndWatchingDescription: 'Are you sure you want to end watching?',
    confirmEndWatchingAgree: 'Yes, End Watching',
    nameLabel: 'Name',
    accessTimeLabel: 'Access time',
    enterEmail: 'Please enter your email to continue.',
    createdUserEmail: 'shared this document with you.',
    continueBtn: 'Continue',
    sendResultBtn: 'End Appointment',
    linkType: 'Link Type',
    messages: {
      createHearingLinkFail: 'Failed to create shareable link!',
      createHearingLinkSuccess: 'Created shareable link successfully!',
      updateHearingLinkSuccess: 'Updated shareable link successfully!',
      updateHearingLinkFail: 'Failed to update shareable link!',
      notFoundHearingLink: 'Shareable link does not exist!',
      checkHearingTokenFailed: 'Get hearings information failed',
      emailNotValid: 'Email is not valid',
      getLinkInfomationFailed: "Couldn't get information!",
      createAppointmentFailed: 'Create appoinment failed!',
    },
    productVideoWatchedTitle: 'PRODUCT VIDEO WATCHED',
    noPublicHearing: 'Hearing is draft or archived. Please change its status to public',
    noPublicProductVideo:
      'All videos are draft or archived. Please change their statuses to public',
    urlParameters: 'URL PARAMS',
    noteRedirection: 'You’re being redirected to an external URL',
    noteRedirectionInFiveSeconds: {
      firstHalf: 'You’re being redirected to an external URL after ',
      latterHalf: ' seconds',
    },
    redirectButton: 'Redirect Now',
  },
  admin: {
    common: {
      filename: 'Filename',
      uploadVideo: 'Upload Video',
      uploadThumbnail: 'Upload Thumbnail',
      draft: 'Draft',
      publish: 'Publish',
      archive: 'Archive',
      setting: 'Settings',
      restore: 'Restore',
      dragAndDrop: 'Drag & Drop File Here',
      browse: 'Browse',
      thumbnail: 'Thumbnail',
      title: 'Title',
      checkbox: 'Checkbox',
      radio: 'Radio',
      delete: 'Delete',
      placeholderVideoInput: 'Enter title of video',
      description: 'Description',
      placeholderVideoTextarea: 'Write something about this video',
      confirmArchive: 'Yes, Archive',
      confirmDelete: 'Yes, Delete',
      messageConfirmArchive: 'Are you sure to archive this {{ item }} ?',
      messageConfirmDelete: 'Are you sure you want to delete ?',
      cancelArchive: 'No, Cancel',
      messages: {
        archiveSuccess: 'Archive Success',
        restoreSuccess: 'Restore Success',
        deleteSuccess: 'Delete Success',
      },
      uploadingProgress: 'File uploading...',
      uploadFailed: 'Upload failed: ',
      notLoadThumbnail:
        '{{filename}} cannot load video thumbnail, please upload an image to set video thumbnail',
      notViewDetail: "File can not be opened because it's in the Trash",
      notFileVideo: '{{filename}}: The file video format is not valid',
      notFileImage: 'The file thumbnail format is not valid',
      isDraftOrArchived: 'Status has been changed to draft',
      itemInUse: 'Item in use',
    },
    productVideo: {
      titleHeader: 'Product Video Management',
      upload: 'Upload',
      emptyProduct: 'Empty Product List',
      titleBlank: 'Title is required',
      descriptionBlank: 'Description is required',
      messages: {
        changeStatusSuccess: 'Change status success!',
        createSuccess: 'Create new product video success!',
        updateProductSuccess: 'Update product video success!',
        notFoundProduct: 'Product video does not exist!',
      },
      videoInUse:
        'This video is being used in a shareable link. Are you sure to change it to draft?',
    },
    hearingSet: {
      titleHeader: 'Hearing Set Management',
      addHearing: 'Add Hearing Set',
      placeholderTitleHearing: 'Enter title of hearing set',
      addQuestions: 'Add questions',
      noTitle: 'No Specified Title',
      messages: {
        createHearingSuccess: 'Create new hearing set success!',
        createHearingError: 'Create hearing set failed!',
        updateHearingSuccess: 'Update hearing set success!',
        updateHearingFail: 'Update hearing set failed!',
        titleBlank: "Hearing's title is required",
        questionBlank: "Hearing's question is required",
        noResults: 'No search results',
        notFoundHearing: 'Hearing set does not exist!',
        statusValidationError: "Hearing set without title or questions can't be published",
      },
      hearingSetInUse:
        'This hearing set is being used in a shareable link. Are you sure to change it to draft?',
      questionIndex: 'No.{{ index }}',
      saveQuestionOrder: 'Save Question Order',
      editQuestionOrder: 'Sort Questions',
      editQuestionOrderHint: 'You can drag & drop each question to rearrange the order',
    },
    questions: {
      addQuestion: 'Add Question',
      submittingAddQuestion: 'Submitting',
      savingUpdateQuestion: 'Saving',
      question: 'Question',
      content: 'Your Content',
      placeholderQuestion: 'Enter Question',
      placeholderFeedback: 'Feedback for answer',
      placeholderAnswer: 'Answer {{index}}',
      addAnswer: 'Add answer',
      countAnswers: '{{count}} Answers',
      createSuccess: 'Create new question success!',
      contentFormLabel: {
        video: 'Upload Video',
        link: 'Video Link',
        image: 'Image',
        pdf: 'PDF',
        none_data: 'None',
      },
      embedURL: 'EMBED URL',
      embedVideoLink: 'Embed Video Link',
      embedModalLabel: 'Paste a URL (Youtube, Vimeo) below to embed video',
      videoURL: 'Video URL',
      changeLink: 'Change Link',
      addOther: 'Add option "Other"',
      errors: {
        invalidVideoURL: 'Please input valid Vimeo/YouTube video URL',
        notFound: "Couldn't find video from inputted URL",
      },
      messages: {
        questionBlank: "Question's title is required",
        answerBlank: "Answer's content is required",
        createQuestionSuccess: 'Create new question success!',
        updateQuestionSuccess: 'Update question success!',
        createQuestionError: 'Create question failed!',
        updatedQuestionError: 'Update question failed!',
        deleteQuestionSuccess: 'Deleted question successfully',
        deleteQuestionError: 'Failed to delete question',
      },
    },
    setting: {
      changeLogo: 'Replace Logo',
      currentLogo: 'Current Logo',
      newLogo: 'New Logo',
      uploadLogoAcceptedFormats: 'Acceptable formats: jpeg, png, gif',
      uploadLogoMaxSize: 'Max file size is 1MB',
      preview: 'Preview',
      messages: {
        changeLogoSuccess: 'Logo updated successfully',
        changeLogoError: 'Failed to update logo',
      },
    },
  },
};

export default en;
