import React, { FC, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

interface PropTypes {
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  label?: string;
  error?: string;
  value: string;
  type?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
  height?: number;
  resize?: boolean;
}

interface StyleProps {
  height?: number;
}

const TextareaWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & label,
  & textarea {
    color: ${palette.darkMedium3};
    font-size: 0.875rem;
    line-height: 1rem;
    resize: none;
  }

  & textarea.resize {
    resize: vertical;
  }

  & label {
    margin-bottom: 8px;
  }

  .wrapper-area {
    border: 1px solid ${palette.grayMedium1};
    border-radius: 6px;
    overflow: hidden;
    ${({ height }) => height && `height: ${height - 20}px`};
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      padding: 12px 10px;
      background: ${palette.whitePrimary};
      -webkit-opacity: 1;
      -webkit-text-fill-color: ${palette.darkMedium3};
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${palette.grayMedium2};
        -webkit-text-fill-color: ${palette.grayMedium2};
      }
    }
  }

  &.error {
    .wrapper-area {
      border-color: ${palette.redPrimary};
    }
    & textarea {
      &::placeholder {
        color: ${palette.redPrimary};
        -webkit-text-fill-color: ${palette.redPrimary};
      }
    }
    span.error_text {
      margin-top: 10px;
      color: ${palette.redPrimary};
      font-size: 13px;
    }
  }
`;

const Textarea: FC<PropTypes> = ({
  value,
  onChange,
  label,
  error,
  maxLength,
  height,
  resize,
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <TextareaWrapper className={`textarea-wrapper ${error ? 'error' : ''}`} height={height}>
      {label && <label>{label}</label>}
      <div className="wrapper-area">
        <textarea
          value={value}
          onChange={handleChange}
          className={`scroll-bar-thumbnail ${resize ? 'resize' : ''}`}
          maxLength={maxLength}
          {...rest}
        />
      </div>
      {error && <span className="error_text">{error}</span>}
    </TextareaWrapper>
  );
};

export default Textarea;
