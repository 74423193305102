import React, { FC, useState, useRef, useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentCLick } from 'hooks';

import SVGIcon from 'views/components/primitive/SVGIcon';
import DropdownIcon from 'assets/images/ic_dropdown.svg';
import { SelectWrapper } from './styles';
import IconCheck from 'assets/images/ic_check.svg';

interface Options {
  value: string;
  label: string;
}

interface PropTypes {
  value: string;
  options: Options[];
  onChange: (val: string) => void;
  size?: 'sm' | 'md';
  className?: string;
}

const Select: FC<PropTypes> = ({ onChange, options, value, size, className }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  useDocumentCLick(handleClick);

  const loadOptions = useMemo(() => options.map(it => ({ ...it, label: t(it.label) })), [
    i18n.language,
  ]);

  function handleClick(event: MouseEvent | any): void {
    event.stopPropagation();
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpen(event);
      }
    }
  }

  function handleChange(val: string) {
    if (val !== value) {
      onChange(val);
    }
  }

  function toggleOpen(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    setIsOpen(!isOpen);
  }

  function getLabel() {
    const item = loadOptions.find(it => value === it.value);
    return item ? item.label : loadOptions[0].label;
  }

  function renderOptions() {
    return loadOptions.map(it => {
      return (
        <div key={it.value} onClick={() => handleChange(it.value)} className="select_options-item">
          {it.label}
          {value === it.value && <SVGIcon src={IconCheck} />}
        </div>
      );
    });
  }

  return (
    <SelectWrapper
      ref={container}
      className={`${className} ${size}`}
      onClick={toggleOpen}
      isOpen={isOpen}
    >
      <div className="select-content">
        <span className="select_value">{getLabel()}</span>
        <SVGIcon src={DropdownIcon} />
      </div>
      <div className="select_options">{renderOptions()}</div>
    </SelectWrapper>
  );
};

export default Select;
