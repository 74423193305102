import axios from 'axios';

const VIMEO_O_EMBED_END_POINT = 'https://vimeo.com/api/oembed.json?url=';

const vimeoRegExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
const youTubeRegExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

const validateVimeoURL = (url: string) => vimeoRegExp.test(url);

const validateYouTubeURL = (url: string) => youTubeRegExp.test(url);

const validateVideoURL = (url: string) => {
  const isVimeo = validateVimeoURL(url);
  const isYouTube = validateYouTubeURL(url);

  return { isVimeo, isYouTube };
};

const getVimeoThumbnail = async (url: string) => {
  const match = url.match(vimeoRegExp);
  if (!match) return;

  try {
    const { data } = await axios.get<{ thumbnail_url: string }>(VIMEO_O_EMBED_END_POINT + url);
    const { thumbnail_url } = data;

    return thumbnail_url as Partial<string>;
  } catch (e) {
    throw new Error(e);
  }
};

const getYouTubeThumbnail = (url: string) => {
  const VIMEO_ID_INDEX = 5;
  const match = url.match(youTubeRegExp);
  if (!match) return;

  const videoId = match[VIMEO_ID_INDEX];
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export {
  validateVimeoURL,
  validateYouTubeURL,
  validateVideoURL,
  getVimeoThumbnail,
  getYouTubeThumbnail,
};
