import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette, bp, layer } from 'entity/createTheme';

import { DropzoneImage } from 'views/components/compound';
import InputReplaceFile from 'views/components/compound/InputReplaceFile';
import { acceptThumbnail } from 'utility/constants';
import { getImgWithStatus } from 'utility/helpers';
import { MessageProps, UploadVideoMeta } from 'types/App';

interface PropTypes {
  activeSrc: string;
  editable?: boolean;
  isDropzone: boolean;
  handleChangeShowImg: (data: string) => void;
  handleChangeMeta: (data: UploadVideoMeta) => void;
  setIsDropzone: (data: boolean) => void;
  setActiveThumbnail(data: string): void;
  isNotUploaded: boolean;
  firstFrameVideo: string;
  thumbnailSrcConvert: string;
  status: string;
  hasError: boolean;
  onChangeFileVideo(file: any): void;
  fileVideo: any;
  handleFlashMessage(data: MessageProps): void;
  meta: UploadVideoMeta;
}

const UploadThumbnail: FC<PropTypes> = ({
  activeSrc,
  handleChangeShowImg,
  handleChangeMeta,
  editable,
  isDropzone,
  setIsDropzone,
  setActiveThumbnail,
  isNotUploaded,
  firstFrameVideo,
  thumbnailSrcConvert,
  status,
  hasError,
  fileVideo,
  onChangeFileVideo,
  handleFlashMessage,
  meta,
}) => {
  const { t } = useTranslation();

  function onChangeFile(file: any, isSet?: boolean) {
    const url = URL.createObjectURL(file);
    setActiveThumbnail(url);
    handleChangeMeta({
      ...meta,
      fileThumbnail: file,
      thumbnailSrcConvert: url,
      fileThumbnailConst: file,
    });
    if (!isSet) {
      setIsDropzone(false);
    }
  }

  function handleReplaceFile(file: any) {
    onChangeFile(file, true);
  }

  function handleShowImg(it: string, isNotReady: boolean) {
    if (!isNotReady) {
      handleChangeShowImg(it);
    }
  }

  function handleError() {
    if (!editable) {
      handleChangeMeta({
        ...meta,
        fileThumbnail: '',
        thumbnailSrcConvert: '',
        fileThumbnailConst: '',
      });
    } else {
      onChangeFileVideo(fileVideo);
    }
    handleFlashMessage({ content: t('admin.common.notFileImage'), status: 'error' });
    setIsDropzone(true);
  }

  function renderThumbnail(it: string, isFirst?: boolean) {
    const active = it === activeSrc && 'active';
    const isNotReady = !it || isNotUploaded;
    return (
      <div
        key={it}
        className={`thumbnail-item thumbnail ${active}`}
        onClick={() => handleShowImg(it, isNotReady)}
      >
        <img
          src={!isFirst ? getImgWithStatus(status, it) : it}
          alt="thumbnail-item"
          className={isNotReady || status === 'upload_failed' ? 'not-ready' : ''}
          onError={handleError}
        />
        {editable && isFirst && (
          <InputReplaceFile
            id="replace_thumbnail"
            className="thumbnail-replace"
            handleChange={handleReplaceFile}
            accept={acceptThumbnail.join(', ')}
          />
        )}
      </div>
    );
  }

  return (
    <UploadThumbnailWrapper>
      <h3 className="thumbnail-title">{t('admin.common.thumbnail')}</h3>
      <div className="thumbnail-content">
        {isDropzone ? (
          <DropzoneImage
            className="thumbnail-item dropzone-img"
            onChange={onChangeFile}
            disabled={
              isNotUploaded || (status === 'upload_failed' && !fileVideo) ? true : !editable
            }
          />
        ) : (
          renderThumbnail(thumbnailSrcConvert, true)
        )}
        {!hasError && renderThumbnail(firstFrameVideo)}
      </div>
    </UploadThumbnailWrapper>
  );
};

// FIXME: extract this component and make it reusable
const UploadThumbnailWrapper = styled('div')`
  .thumbnail {
    &-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &-title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: uppercase;

      height: 19px;
      letter-spacing: 0;
      line-height: 19px;
    }
    &-item {
      height: 95px;
      width: 170px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      border: 2.2px solid ${palette.whitePrimary};
      &.thumbnail {
        background: ${palette.blackPrimary};
      }
      @media ${bp.xmd} {
        width: 156px;
      }
      &:nth-of-type(odd) {
        margin-right: 20px;
      }
      &.active {
        border-color: ${palette.darkMedium2};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.not-ready {
          object-fit: cover;
        }
      }
      .thumbnail-replace {
        position: absolute;
        top: 5px;
        right: 5px;

        // Might not need to specify z-index
        z-index: ${layer.bottomAbove1};
        width: 32px;
        height: 32px;
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.dropzone-img {
        padding: 16px 10px;
        svg {
          flex-shrink: 0;
        }
        p {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      @media ${bp.md} {
        height: 84px;
        width: 150px;

        &:nth-of-type(odd) {
          margin-right: 16px;
        }
      }
    }
  }
`;

export default UploadThumbnail;
