import React, { FC } from 'react';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';

type Props = {
  url: string;
};

const ImageViewer: FC<Props> = ({ url }) => {
  return (
    <Root id="image-viewer">
      <ImageWrap>
        <Img src={url} alt="Image Content" />
      </ImageWrap>
    </Root>
  );
};

export default ImageViewer;

const Root = styled('div')({
  height: 511,
  textAlign: 'center',
  overflow: 'auto',
  background: palette.blackPrimary,
  [`@media ${bp.md}`]: {
    width: '100%',
    marginBottom: 8,
  },

  [`@media ${bp.xmd}`]: {
    height: 480,
  },

  [`@media ${bp.sm}`]: {
    height: 320,
  },

  '&::-webkit-scrollbar-track': {
    background: palette.whitePrimary,
  },

  '&::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.grayMedium2,
    border: 'none',
    borderRadius: 4,
  },
});

const ImageWrap = styled('div')({
  height: '100%',
});

const Img = styled('img')({
  height: '100%',
});
