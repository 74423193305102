import { Appointment, CreateQuickHearingData } from 'types/Appointment';
import { trimSpace } from 'utility/helpers';
import HttpClient from 'adapter/HTTPClient';
import { QuestionAnswer } from 'types/HearingSet';

class AppointmentApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getAppointments = async ({
    currentPage,
    sort,
    order,
  }: {
    currentPage: number;
    sort: string;
    order: string;
  }) => {
    const res = await this.httpClient.request<{
      appointments: Appointment[];
      total: number;
      page: number;
    }>({
      method: 'GET',
      url: '/appointments',
      params: {
        page: currentPage,
        per_page: 20,
        sort,
        order,
      },
    });

    return res;
  };

  public getAppointmentById = async (id: string) => {
    const res = await this.httpClient.request<Appointment>({
      url: `/appointments/${id}`,
      method: 'GET',
    });

    return res;
  };

  public createAppointment = async (data: CreateQuickHearingData) => {
    await this.httpClient.request({
      url: '/appointments',
      method: 'POST',
      data,
    });
  };

  // FIX ME: type data
  public createQuickHearing = async (data: any) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'appointments/create_quick_hearing',
      data,
    });
  };

  public updateAppointment = async ({
    appointment,
    id,
  }: {
    appointment: Appointment;
    id: string;
  }) => {
    const { company_name, company_pic_name, memo, external_client_id } = appointment;
    await this.httpClient.request({
      url: `/appointments/${id}`,
      method: 'PUT',
      data: {
        company_name: trimSpace(company_name),
        company_pic_name: trimSpace(company_pic_name),
        memo: trimSpace(memo),
        external_client_id: trimSpace(external_client_id || ''),
      },
    });
  };

  public deleteAppointments = async (idList: number[]) => {
    await this.httpClient.request({
      method: 'DELETE',
      url: `appointments/delete_appointments?ids=${idList.join(',')}`,
    });
  };

  public updateQuestionAnswers = async (questionAnswers: QuestionAnswer[]) => {
    await this.httpClient.request({
      url: `/update_question_answers`,
      method: 'PUT',
      data: { question_answers: questionAnswers },
    });
  };
}

export default AppointmentApi;
