import { ListRequestParamsMeta } from 'types/common';
import { HearingItem } from 'types/HearingSet';
import { QuickHearingItem } from 'types/QuickHearing';
import HttpClient from 'adapter/HTTPClient';
class HearingSetApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getHearingSets = async (params?: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{
      hearing_sets: HearingItem[];
      total: number;
    }>({
      method: 'GET',
      url: '/hearing_sets',
      ...(params && { params }),
    });

    return res;
  };

  public getOtherHearingSetDetails = async (ids: string[] | number[]) => {
    const res = await this.httpClient.request<HearingItem[]>({
      method: 'GET',
      url: `hearing_sets/other_hearing_sets_detail?ids=${ids.join(',')}`,
    });

    return res;
  };

  public getQuickHearings = async (params?: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{
      quick_hearings: QuickHearingItem[];
      total: number;
    }>({
      method: 'GET',
      url: '/hearing_sets/quick_hearings',
      ...(params && { params }),
    });

    return res;
  };

  public getQuickHearingDetails = async (idList: string[]) => {
    // FIX ME: type response
    const res = await this.httpClient.request({
      method: 'GET',
      url: '/hearing_sets/quick_hearings_detail',
      params: {
        ids: idList.join(','),
      },
    });

    return res;
  };
}

export default HearingSetApi;
