import React, { useState, FC, useMemo, MouseEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'utility/constants';
import { useDocumentCLick } from 'hooks';
import SVGIcon from 'views/components/primitive/SVGIcon';
import DropdownUrl from 'assets/images/ic_dropdown.svg';

import AppointmentIcon from 'assets/images/ic_appointment.svg';
import LogoutIcon from 'assets/images/ic_logout.svg';
import { UserInfoWrapper } from './styles';

interface PropTypes {
  avatarUrl?: string;
  userName?: string;
  handleLogout: () => void;
}

const UserInfo: FC<PropTypes> = ({ avatarUrl, userName, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useDocumentCLick(handleClick);

  function handleClick(event: MouseEvent | any): void {
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpen();
      }
    }
  }

  function toggleOpen(): void {
    setIsOpen(!isOpen);
  }

  const className = useMemo(() => (isOpen ? 'open' : ''), [isOpen]);
  return (
    <UserInfoWrapper ref={container} className={className} onClick={toggleOpen}>
      <img className="user-avatar" src={avatarUrl} alt="avatar" />
      <span className="user-name">{userName}</span>
      <SVGIcon src={DropdownUrl} />
      <div className="dropdown-menu">
        <div className="dropdown-menu__container scroll-bar-thumbnail">
          <Link to={routes.appointmentList} className="dropdown-menu__item">
            <SVGIcon src={AppointmentIcon} />
            {t('common.appointment')}
          </Link>
          <a
            href={process.env.REACT_APP_KAIZEN_URL}
            rel="noopener noreferrer"
            target="_blank"
            className="dropdown-menu__item"
          >
            <SVGIcon src={LogoutIcon} />
            {t('common.accountSetting')}
          </a>
          <div onClick={handleLogout} className="dropdown-menu__item">
            <SVGIcon src={LogoutIcon} />
            {t('common.logoutTitle')}
          </div>
        </div>
      </div>
    </UserInfoWrapper>
  );
};

export default UserInfo;
