import React, { FC, useEffect, useRef, useState } from 'react';
import DownloadButton, { DownloadStatus } from './DownloadButton';
import { ShakaPlayerInner } from './ShakaPlayerInner';

interface PropsTypes {
  videos: { manifestId: string; manifestUrl: string }[];
  top?: string;
  right?: string;
  whiteBackground?: boolean;
}

class BulkDownloaderInner {
  videosCount: number;
  manifestIds: { [key: string]: string };
  cachedIds: string[];
  inner: ShakaPlayerInner;
  setDownloadStatus: (
    value: DownloadStatus | ((prevState: DownloadStatus) => DownloadStatus)
  ) => void;
  setProgress: (value: number | ((prevState: number) => number)) => void;

  constructor(
    videos: { manifestId: string; manifestUrl: string }[],
    videoTag: HTMLVideoElement,
    setDownloadStatus: (
      value: ((prevState: DownloadStatus) => DownloadStatus) | DownloadStatus
    ) => void,
    setProgress: (value: ((prevState: number) => number) | number) => void
  ) {
    const manifestIds: { [key: string]: string } = {};
    videos.forEach((video: { manifestId: string; manifestUrl: string }) => {
      if (video.manifestId && video.manifestUrl) manifestIds[video.manifestId] = video.manifestUrl;
    });
    this.videosCount = Object.keys(manifestIds).length;
    this.manifestIds = manifestIds;
    this.cachedIds = [];
    this.inner = new ShakaPlayerInner({
      videoTag: videoTag,
      onDownloadProgress: (downloading, progress) => {
        this.updateProgress(downloading, progress);
      },
    });
    this.setDownloadStatus = setDownloadStatus;
    this.setProgress = setProgress;
  }

  async checkCachedStatus() {
    await this.inner.eachCachedVideo(Object.keys(this.manifestIds), (manifestId: string) => {
      this.cachedIds.push(manifestId);
    });
    this.updateProgress(false, 0.0);
  }

  async downloadAll() {
    this.updateProgress(true, 0.0);
    for (const manifestId in this.manifestIds) {
      const manifestUrl = this.manifestIds[manifestId];
      const downloaded = await this.inner.downloadVideo(manifestId, manifestUrl, false);
      if (downloaded) {
        this.cachedIds.push(manifestId);
      }
    }
    this.updateProgress(false, 0.0);
  }

  updateProgress(downloading: boolean, progress: number) {
    if (this.cachedIds.length >= this.videosCount) {
      this.setDownloadStatus('downloaded');
    } else {
      this.setDownloadStatus(downloading ? 'downloading' : 'ready');
      this.setProgress((this.cachedIds.length + progress) / this.videosCount);
    }
  }
}

const BulkDownloader: FC<PropsTypes> = ({ videos, top, right, whiteBackground }) => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>('hidden');
  const [progress, setProgress] = useState(0.0);
  const [downloader, setDownloader] = useState<BulkDownloaderInner | null>(null);
  const videoTag = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!downloader && videoTag.current) {
      const _downloader = new BulkDownloaderInner(
        videos,
        videoTag.current,
        setDownloadStatus,
        setProgress
      );
      setDownloader(_downloader);
      const f = async () => {
        await _downloader.checkCachedStatus();
      };
      f();
    }
  }, []);

  async function handleDownloadClick() {
    if (downloader && downloadStatus === 'ready') {
      downloader.downloadAll();
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <DownloadButton
        status={downloadStatus}
        progress={progress}
        onClick={handleDownloadClick}
        top={top}
        right={right}
        whiteBackground={whiteBackground}
      />
      <video ref={videoTag} style={{ display: 'none' }} crossOrigin="anonymous" />
    </div>
  );
};

export default BulkDownloader;
