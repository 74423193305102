import React, { FC, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import useStore from 'hooks/useStore';
import { DropzoneWithFileBrowse } from 'views/components/compound';
import UploadContent from './UploadContent';
import { UploadVideoMeta } from 'types/Product';
import { UploadVideoWrapper } from './styles';
import NoThumbnail from 'assets/images/no-thumbnail.jpg';
import { videoAcceptShow } from 'utility/constants';
import { extractFileExtentionFromMimeType } from 'utility/helpers';

interface PropTypes {
  dataDetail: UploadVideoMeta;
  isDetail: boolean;
  isEdit: boolean;
  handleChangeMeta: (data: object) => void;
  status: string;
  getDetailData?: () => void;
}

const UploadVideo: FC<PropTypes> = ({
  isDetail,
  isEdit,
  dataDetail,
  handleChangeMeta,
  status,
  getDetailData,
}) => {
  const {
    videoSrcConvert,
    filename,
    fileThumbnail,
    firstFrameVideo,
    fileVideo,
    error,
    errorFile,
  } = useMemo(() => dataDetail, [dataDetail]);
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeSrc, setActiveSrc] = useState('');
  const {
    appStore: { handleFlashMessage },
  } = useStore();

  function setActiveThumbnail(data: string) {
    setActiveSrc(data);
  }

  function handlePrintImage() {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (ctx && video.videoHeight && video.videoWidth) {
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const firstFrameVideo = canvas.toDataURL();
        const fileThumbnail = canvas.toDataURL('image/png');
        handleChangeMeta({
          firstFrameVideo,
          fileThumbnail,
          error: '',
        });
        setActiveSrc(firstFrameVideo);
        video.pause();
      }
    }
  }

  function handlePlayVideo() {
    const video = videoRef.current;
    if (video && video.src) {
      video.play();
    }
  }

  function onChangeFileVideo(newFile: any) {
    const extension = extractFileExtentionFromMimeType(newFile.type);
    if (videoAcceptShow.includes(extension)) {
      handleChangeMeta({
        fileVideo: newFile,
        videoSrcConvert: URL.createObjectURL(newFile),
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        errorFile: '',
      });
    } else {
      handleChangeMeta({
        fileVideo: newFile,
        videoSrcConvert: null,
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: t('admin.common.notLoadThumbnail', { filename: newFile.name }),
        fileThumbnail: '',
        firstFrameVideo: NoThumbnail,
        errorFile: '',
      });
    }
  }

  function handleError() {
    if (fileVideo) {
      handleChangeMeta({
        fileVideo: '',
        fileThumbnail: '',
        videoSrcConvert: '',
        filename: '',
        firstFrameVideo: '',
        error: '',
        errorFile: t('admin.common.notFileVideo', { filename: fileVideo.name }),
      });
      if (isDetail) {
        handleFlashMessage({
          content: t('admin.common.notFileVideo', { filename: fileVideo.name }),
          status: 'error',
        });
        if (getDetailData) {
          getDetailData();
        }
      }
    }
  }

  function renderContentUpload() {
    if (!firstFrameVideo && !isDetail) {
      return <DropzoneWithFileBrowse onChange={onChangeFileVideo} name={filename} type="video" />;
    }
    return (
      <UploadContent
        isDetail={isDetail}
        isEdit={isEdit}
        onChangeFileVideo={onChangeFileVideo}
        handleChangeMeta={handleChangeMeta}
        dataDetail={dataDetail}
        firstFrameVideo={firstFrameVideo}
        setActiveThumbnail={setActiveThumbnail}
        activeSrc={activeSrc}
        status={status}
        hasError={!!error}
        handleFlashMessage={handleFlashMessage}
      />
    );
  }

  return (
    <UploadVideoWrapper>
      <canvas ref={canvasRef} id="img-canvas" />
      <video
        onLoadedMetadata={handlePlayVideo}
        onTimeUpdate={handlePrintImage}
        ref={videoRef}
        controls
        src={videoSrcConvert || undefined}
        muted
        onError={handleError}
      />
      {renderContentUpload()}
      <p className="error">{!fileThumbnail && error}</p>
      <p className="error">{errorFile}</p>
    </UploadVideoWrapper>
  );
};

export default observer(UploadVideo);
