import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { useKeydown, usePreventScroll } from 'hooks';
import { keyCodeESC } from 'utility/constants';
import { WrapperModal } from './styles';
import SVGIcon from 'views/components/primitive/SVGIcon';
import CloseIcon from 'assets/images/ic_close.svg';

export interface ModalProps {
  isModal: boolean;
  title: string;
  children: React.ReactNode;
  handleToggle: () => void;
  className?: string;
}

const Modal: FC<ModalProps> = ({ title, children, isModal, handleToggle, className }) => {
  useKeydown(handleKeyDown);
  usePreventScroll(isModal);

  function handleKeyDown(e: React.KeyboardEvent | any) {
    if (e.keyCode === keyCodeESC && isModal) {
      handleToggle();
    }
  }

  return isModal ? (
    <WrapperModal className={`modal-wrapper ${className}`}>
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
          <span className="close_icon" onClick={handleToggle}>
            <SVGIcon src={CloseIcon} />
          </span>
        </div>
        <div className="modal-content scroll-bar-thumbnail">{children}</div>
      </div>
    </WrapperModal>
  ) : null;
};

export default function ModalPortal(props: ModalProps) {
  return ReactDOM.createPortal(<Modal {...props} />, document.body);
}
