import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, SVGIcon } from 'views/components/primitive';
import { palette } from 'entity/createTheme';
import UploadIcon from 'assets/images/ic_upload_disabled.svg';

const NoContent: FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <SVGIcon src={UploadIcon} />
      <Label>{t('admin.common.dragAndDrop')}</Label>
      <Button label={t('admin.common.browse')} size="sm" disabled />
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '35px 0',
  borderRadius: '6px',
  userSelect: 'none',
  marginTop: 15,

  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23EAEAEAFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='17' stroke-linecap='square'/%3e%3c/svg%3e")`,
});

const Label = styled('p')({
  fontSize: 14,
  padding: `10px 0 30px 0`,
  color: palette.grayPrimary,
});

export default NoContent;
