import React, { FC, useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import ShakaPlayer from './ShakaPlayer';
import DownloadButton, { DownloadStatus } from './DownloadButton';
import { ShakaPlayerInner } from './ShakaPlayerInner';
import { restoreSettings, storeSettings } from 'utility/playerSettings';
import { ProgressTracker } from './ProgressTracker';

interface PropsTypes {
  width: string;
  height: string;
  url: string;
  manifestId: string;
  manifestUrl: string;
  muted: boolean;
  onReady: () => void;
  onError: (error: any) => void;
  isVimeo: boolean;
}

enum PlayerType {
  initializing,
  vimeo,
  shaka,
}

const CacheablePlayer: FC<PropsTypes> = ({
  width,
  height,
  url,
  manifestId,
  manifestUrl,
  onReady,
  onError,
  muted,
  isVimeo,
}) => {
  const [mode, setMode] = useState(PlayerType.initializing);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>('hidden');
  const [progress, setProgress] = useState(0.0);
  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const vimeoTracker = useRef<ProgressTracker | null>(null);

  useEffect(() => {
    setMode(PlayerType.initializing);
    setDownloadStatus('hidden');
    setProgress(0.0);
    setDownloadTriggered(false);

    const videoId = manifestId;
    const vimeoId = url ? url.replace(/(^.*\/|\D*$)/g, '') : undefined;
    vimeoTracker.current = new ProgressTracker('vimeo', videoId, vimeoId);
    vimeoTracker.current.track('init', { duration: 0, currentTime: 0 });
  }, [url, manifestId]);

  const playerRef = useRef(null);
  const playerSettings = restoreSettings();
  let vimeoOnly = false;

  function storeVimeoVolume() {
    const p: any = playerRef.current;
    if (p && isVimeo) {
      p.getInternalPlayer()
        .getVolume()
        .then((v: number) => storeSettings({ muted: muted, volume: v }));
    }
  }

  if (!manifestId || !manifestUrl || !ShakaPlayerInner.isSupported()) {
    // fallback to vimeo
    vimeoOnly = true;
    return (
      <ReactPlayer
        ref={playerRef}
        playing
        muted={muted}
        volume={playerSettings.volume}
        controls
        width={width}
        height={height}
        onReady={onVimeoReady}
        onError={onError}
        onPlay={onVimeoPlay}
        onStart={onVimeoStart}
        onEnded={onVimeoEnded}
        onProgress={onVimeoProgress}
        onPause={onVimeoPause}
        url={url}
      />
    );
  }

  function onCacheChecked(cacheAvailable: boolean) {
    if (!cacheAvailable) {
      setMode(PlayerType.vimeo);
      setDownloadStatus('hidden');
    }
  }

  function onShakaReady() {
    setMode(PlayerType.shaka);
    onReady();
  }

  function onDownloadProgress(downloading: boolean, progress: number) {
    setDownloadStatus(downloading ? 'downloading' : 'downloaded');
    setProgress(progress);
  }

  function handleDownloadClick() {
    setDownloadTriggered(true);
  }

  function trackVimeo(
    eventType: 'ready' | 'bufferstart' | 'bufferend' | 'play' | 'ended' | 'progress',
    extra: any = {}
  ) {
    if (vimeoTracker.current && playerRef.current) {
      const progressTracker: ProgressTracker = vimeoTracker.current;
      const player: ReactPlayer = playerRef.current as any;
      if (player) {
        const duration = player.getDuration() || 0;
        const currentTime = player.getCurrentTime() || 0;
        if (!progressTracker.videoPlayerTag) {
          const internalPlayer = player.getInternalPlayer() as any;
          progressTracker.videoPlayerTag = ProgressTracker.findVideoPlayerTag(
            internalPlayer?.element?.parentElement
          );
          if (progressTracker.videoPlayerTag) {
            internalPlayer.on('bufferstart', () => {
              trackVimeo('bufferstart');
            });
            internalPlayer.on('bufferend', () => {
              trackVimeo('bufferend');
            });
          }
        }
        progressTracker.track(eventType, { duration, currentTime }, extra);
      }
    }
  }

  function onVimeoReady() {
    trackVimeo('ready');
    onReady();
  }

  function onVimeoPlay() {
    trackVimeo('play');
    if (!vimeoOnly) setDownloadStatus('hidden');
    storeVimeoVolume();
  }

  function onVimeoStart() {
    if (!vimeoOnly) setDownloadStatus('ready');
  }

  function onVimeoEnded() {
    trackVimeo('ended');
    if (!vimeoOnly) setDownloadStatus('ready');
  }

  function onVimeoPause() {
    if (!vimeoOnly) setDownloadStatus('ready');
    storeVimeoVolume();
  }

  function onVimeoProgress(state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) {
    trackVimeo('progress', { vimeoState: state });
  }

  return (
    <div style={{ width, height, position: 'relative' }}>
      {mode === PlayerType.vimeo && (
        <ReactPlayer
          ref={playerRef}
          playing
          muted={muted}
          volume={playerSettings.volume}
          controls
          width={width}
          height={height}
          onReady={onVimeoReady}
          onError={onError}
          onPlay={onVimeoPlay}
          onStart={onVimeoStart}
          onEnded={onVimeoEnded}
          onProgress={onVimeoProgress}
          onPause={onVimeoPause}
          url={url}
        />
      )}
      {mode === PlayerType.vimeo && (
        <DownloadButton status={downloadStatus} progress={progress} onClick={handleDownloadClick} />
      )}
      <ShakaPlayer
        visible={mode === PlayerType.shaka}
        manifestId={manifestId}
        manifestUrl={manifestUrl}
        onCacheChecked={onCacheChecked}
        onReady={onShakaReady}
        onError={onError}
        onDownloadProgress={onDownloadProgress}
        downloadTriggered={downloadTriggered}
      />
    </div>
  );
};

export default CacheablePlayer;
