import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentType } from 'types/App';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { RadioButton } from 'views/components/primitive';
import { CONTENT_TYPE_LIST } from './constants';

type Props = {
  contentType: ContentType;
  selectContentType: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editable: boolean;
};

const ContentSelector: FC<Props> = ({ contentType, selectContentType, editable }) => {
  const { t } = useTranslation();

  return (
    <Root>
      {CONTENT_TYPE_LIST.map(type => (
        <RadioWrapper key={type}>
          <RadioButton
            value={type}
            checked={contentType === type}
            onChange={selectContentType}
            disabled={!editable}
          />
          <Label>{t(`admin.questions.contentFormLabel.${type}`)}</Label>
        </RadioWrapper>
      ))}
    </Root>
  );
};

const Root = styled('div')({
  maxWidth: 783,
  display: 'flex',
  justifyContent: 'space-between',
});

const RadioWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled('div')({
  marginLeft: 10,
  color: palette.darkMedium3,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
});

export default ContentSelector;
