import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '../stores/index';
import { cookieStorage } from 'utility/cookieStorage';
import { reporttError } from 'utility/reportError';

class HttpClient {
  private axios: AxiosInstance;

  constructor({ baseURL }: { baseURL: string | undefined }) {
    this.axios = axios.create({
      baseURL,
      withCredentials: true,
    });

    this.axios.interceptors.request.use(
      function(config) {
        const { organizationId } = store.appStore; // 現在UI 上選択中のorganization のid
        const organizationIdCookie = cookieStorage.getOrganizationId(); // cookie として保存されているorganization のid
        // 複数タブを開き、複数のorgazanition で操作を行う際に、別のタブで選択中のorganization のid をcookie 経由で送信することを防ぐため、
        // organizationId とorganizationIdCookie の値を比較して、異なっている場合はorganizationId をcookie にset する
        if (organizationIdCookie !== organizationId && organizationId !== '') {
          cookieStorage.setOrganizationId(organizationId);
        }
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        const errorStatus = error?.response?.status ?? null;
        if (errorStatus === 401) {
          store.appStore.clearSession();
        }
        if (!errorStatus || errorStatus >= 500) {
          reporttError(error);
        }
        return Promise.reject(error.response);
      }
    );
  }

  public request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.request<T>(config);
  }
}

export default HttpClient;
