import { observable, action, computed } from 'mobx';
import history from 'utility/history';
import { MessageProps, UserInfo } from 'types/App';
import { defaultUser, defaultFlashMessage } from './constants';
import { cookieStorage } from 'utility/cookieStorage';
import { RootStore } from '../index';
import UserApi from 'services/UserApi';
import OrganizationApi from 'services/OrganizationApi';
import AuthenticationApi from 'services/AuthenticationApi';

class AppStore {
  private readonly userApi: UserApi;
  private readonly organizationApi: OrganizationApi;
  private readonly authenticationApi: AuthenticationApi;
  @observable loading = false;
  @observable mainLoading = false;
  @observable mainLoadingTimeout: any = null;
  @observable public rootStore: RootStore;
  @observable public organizationId = '';
  @observable public flashMessages: MessageProps = { ...defaultFlashMessage };
  @observable public userData: UserInfo = { ...defaultUser };
  @observable public currentLogoUrl: string | null = null;
  @observable public sidebarOpen = false;

  constructor({
    rootStore,
    userApi,
    organizationApi,
    authenticationApi,
  }: {
    rootStore: RootStore;
    userApi: UserApi;
    organizationApi: OrganizationApi;
    authenticationApi: AuthenticationApi;
  }) {
    this.rootStore = rootStore;
    this.userApi = userApi;
    this.organizationApi = organizationApi;
    this.authenticationApi = authenticationApi;
  }

  @computed get isAdmin(): boolean {
    const organization = this.findOrganization();
    return !!organization && !!organization.roles && !!organization.roles.includes('admin');
  }

  @computed get hasLogin(): boolean {
    return Number(this.userData.id) > 0;
  }

  @computed get hasNoOrg(): boolean {
    const orgIdFromCookie = cookieStorage.getOrganizationId();
    const organization = this.findOrganization();
    //orgIdFromCookie === 'undefined' means user has not logged in with organization id before
    return !organization && orgIdFromCookie === 'undefined';
  }

  @action.bound
  public clearSession() {
    this.userData = { ...defaultUser };
  }

  @action.bound
  public handleFlashMessage(data: MessageProps) {
    this.flashMessages = data;
  }

  public setMainLoading(val: boolean): void {
    this.mainLoading = val;
  }

  @action.bound
  public async getCurrentUserAndRole() {
    try {
      this.loading = true;
      const userData = await this.getCurrentUser();

      this.userData = userData;
      await this.handleInitialOrganization(userData);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getCurrentUser() {
    const { data } = await this.userApi.getMe();

    return data;
  }

  @action.bound
  public async getOrganizationRole(id?: string) {
    if (!id) return;
    try {
      const { data } = await this.organizationApi.getOrganizationById(id);
      const organization = this.findOrganization();
      if (organization) organization.roles = data.roles;
      this.currentLogoUrl = data.organization_logo;
    } catch (error) {
      const content = error?.errors ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  @action.bound
  public async handleLogout(): Promise<void> {
    await this.authenticationApi.logout();
    // いらないかも
    history.push('/');
  }

  @action.bound
  public async handleChangeOrganization(id: string) {
    this.organizationId = id;
    cookieStorage.setOrganizationId(id);
    this.mainLoading = true;
    await this.getOrganizationRole(id);
    clearTimeout(this.mainLoadingTimeout);
    this.mainLoadingTimeout = setTimeout(() => {
      this.mainLoading = false;
    }, 1000);
  }

  @action.bound
  public async handleInitialOrganization(data: UserInfo) {
    const { organizations } = data;
    const allIds = organizations.map(item => item.id);
    const currentOrganizationId = cookieStorage.getOrganizationId();
    if (currentOrganizationId && allIds.includes(currentOrganizationId)) {
      this.organizationId = currentOrganizationId;
      await this.getOrganizationRole(currentOrganizationId);
    } else {
      this.handleChangeOrganization(allIds[0]);
    }
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public setSidebarOpen(value: boolean) {
    this.sidebarOpen = value;
  }

  private findOrganization() {
    return this.userData.organizations.find(item => item.id === this.organizationId);
  }
}

export default AppStore;
