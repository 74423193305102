import React, { FC, memo } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
interface HeaderItem {
  id: string;
  title: string;
}

interface PropTypes {
  isArchive?: boolean;
}

const BaseHeader: HeaderItem[] = [
  {
    id: 'account',
    title: 'hearingLink.accountLabel',
  },
  {
    id: 'created_date',
    title: 'productVideo.createDate',
  },
  {
    id: 'link',
    title: 'common.link',
  },
];

const getHeader = (isAdmin: boolean, isArchive?: boolean): HeaderItem[] => {
  const linkListHeader = [...BaseHeader];
  const creator = { id: 'created_by', title: 'hearingLink.createdByLabel' };
  const status = { id: 'status', title: 'hearingLink.switchLink' };
  const archive = { id: 'archive', title: 'admin.common.archive' };
  if (isAdmin) {
    isArchive ? linkListHeader.push(creator, archive) : linkListHeader.push(creator, status);
  }
  if (!isAdmin) {
    isArchive ? linkListHeader.push(archive) : linkListHeader.push(status);
  }
  return linkListHeader;
};

const HeaderLinkList: FC<PropTypes> = ({ isArchive }) => {
  const { t } = useTranslation();
  const {
    appStore: { isAdmin },
  } = useStore();

  const headerItems = getHeader(isAdmin, isArchive);

  return (
    <LinkWrapper isAdmin={isAdmin}>
      {headerItems.map(item => (
        <ColumnTitle isAdmin={isAdmin} key={item.id}>
          {t(item.title).toUpperCase()}
        </ColumnTitle>
      ))}
    </LinkWrapper>
  );
};

export default memo(HeaderLinkList);

const LinkWrapper = styled('div')<{ isAdmin: boolean }>(props => ({
  display: 'grid',
  gridGap: 30,
  gridTemplateColumns: props.isAdmin ? '3fr 1fr 3fr 1fr 1fr' : '2fr 1fr 3fr 1fr',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  padding: '0 20px',
  marginBottom: 10,
  [`@media ${bp.md}`]: {
    gridGap: 10,
    gridTemplateColumns: props.isAdmin ? '2fr 1fr 1fr 1fr 1fr' : '2fr 1fr 3fr 1fr',
  },
}));

const ColumnTitle = styled('p')<{ isAdmin: boolean }>(props => ({
  color: palette.grayMedium4,
  '&:nth-of-type(4)': {
    textAlign: props.isAdmin ? 'center' : 'left',
  },
  '&:last-of-type': {
    textAlign: 'center',
  },
}));
