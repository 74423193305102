import React, { FC } from 'react';
import { SVGIcon } from 'views/components/primitive';
import NoImage from 'assets/images/ic_no_image.svg';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';

const NoImageIcon: FC = () => {
  return (
    <Root>
      <SVGIcon src={NoImage} />
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  background: palette.grayMedium6,
  justifyContent: 'center',
  alignItems: 'center',

  svg: {
    height: 28,
    width: 28,
  },

  [`@media ${bp.md}`]: {
    svg: {
      height: 20,
      width: 20,
    },
  },
});

export default NoImageIcon;
