import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import ContentSelector from './ContentSelector';
import ContentUploader from './ContentUploader';
import { Question } from 'types/Question';
import {
  UploadVideoMeta,
  UploadImageMeta,
  ExternalVideoURLMeta,
  UploadPDFMeta,
  ContentType,
  ContentMeta,
} from 'types/App';

type Props = {
  questionDetail: Question;
  editable?: boolean;

  // content type and payload observables for create question
  contentType: ContentType;
  questionVideoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
};

const UploadContent: FC<Props> = ({
  questionDetail,
  editable = true,
  contentType,
  questionVideoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
}) => {
  const { t } = useTranslation();

  const uploadStatus =
    questionDetail.data_file && questionDetail.data_file.type_data === 'video'
      ? questionDetail.data_file.upload_status
      : '';

  const [videoUploadStatus, setVideoUploadStatus] = useState(uploadStatus);
  useEffect(() => {
    setVideoUploadStatus(uploadStatus);
  }, [uploadStatus]);

  const handleChangeMeta = (data: ContentMeta) => {
    handleChangeContentMeta(data);
    setVideoUploadStatus('');
  };

  const selectContentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeContentType(e.target.value as ContentType);
  };

  return (
    <Root>
      <h4>{t('admin.questions.content')}</h4>
      <ContentSelector
        contentType={contentType}
        selectContentType={selectContentType}
        editable={editable}
      />
      <ContentUploader
        editable={editable}
        contentType={contentType}
        questionVideoMeta={questionVideoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentMeta={handleChangeMeta}
        uploadStatus={videoUploadStatus}
      />
    </Root>
  );
};

const Root = styled('div')({
  marginBottom: 20,
  padding: 20,
  background: palette.whitePrimary,
  borderRadius: 6,

  h4: {
    marginBottom: 10,
    fontSize: 16,
    color: palette.darkMedium9,
  },
});

export default UploadContent;
