import React, { FC, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { palette } from 'entity/createTheme';
import { SVGIcon } from 'views/components/primitive';
import IconUpload from 'assets/images/ic_upload_draft.svg';
import { acceptVideo, acceptImage, acceptPDF } from 'utility/constants';
import { UploadableContentTypes } from 'types/common';

const DropzoneWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='17' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
  user-select: none;
  p {
    font-size: 14px;
    padding: 10px 0 30px 0;
  }
  &:focus {
    outline: none;
  }
  .btn-browse {
    width: 150px;
    height: 32px;
    font-size: 12px;
    background: ${palette.greenPrimary};
    border: none;
    border-radius: 6px;
    color: ${palette.whitePrimary};
    cursor: pointer;
    &:hover {
      background: ${palette.greenSecondary};
    }
    &:focus {
      outline: none;
    }
  }
  .error_upload {
    color: ${palette.redPrimary};
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -15px;
  }
`;

interface PropTypes {
  onChange: (file: any) => void;
  error?: string;
  name: string;
  type: UploadableContentTypes;
}

const getAcceptType = (type: UploadableContentTypes) => {
  switch (type) {
    case 'video':
      return acceptVideo;
    case 'image':
      return acceptImage;
    case 'pdf':
      return acceptPDF;
    default:
      throw new Error(`${type} is not supported`);
  }
};

const DropzoneWithFileBrowse: FC<PropTypes> = ({ error, onChange, name, type }) => {
  const { t } = useTranslation();
  const [errorType, setErrorType] = useState('');

  const accept = getAcceptType(type);

  const onDrop = useCallback(data => {
    const newFile = data[0];
    const extension = newFile.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!accept.includes(`.${extension}`)) {
      setErrorType(`Only Accept Format: ${accept.join(', ')}`);
    } else {
      setErrorType('');
      onChange(newFile);
    }
  }, []);

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  function handleClickWrapper() {
    return null;
  }

  function handleShowError() {
    if (errorType || error) return <span className="error_upload">{errorType || error}</span>;
  }

  return (
    <DropzoneWrapper {...getRootProps({})} onClick={handleClickWrapper}>
      <input {...getInputProps()} accept={accept.join(', ')} multiple={false} />
      <SVGIcon src={IconUpload} />
      <p>{name ? name : t('admin.common.dragAndDrop')}</p>
      {handleShowError()}
      <button className="btn-browse" {...getRootProps()}>
        {t('admin.common.browse')}
      </button>
    </DropzoneWrapper>
  );
};

export default DropzoneWithFileBrowse;
