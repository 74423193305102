import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { palette, bp } from 'entity/createTheme';

interface Props {
  clickable?: boolean;
}

const hoverStyle = css`
  border-radius: 6px;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const WrapperProductItem = styled('div')<Props>`
  display: flex;
  flex-direction: column;
  width: calc((100% - 40px) / 3);
  margin: 0 20px 20px 0;
  border-radius: 6px;
  background: ${palette.whitePrimary};

  /* hover interaction code */
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};
  position: relative;

  &::before {
    ${({ clickable }) => clickable && hoverStyle}
  }

  &:hover {
    background: ${({ clickable }) => (clickable ? palette.grayMedium5 : palette.whitePrimary)};

    &::before {
      background: ${({ clickable }) => (clickable ? palette.grayBlur : 'none')};
    }
  }
  /* -------------------------- */

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media ${bp.md} {
    width: calc(50% - 8px);
    margin: 0 16px 16px 0;
    &:nth-of-type(3n) {
      margin-right: 16px;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  .prod-item {
    &_thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 203px;
      width: 100%;
      border-radius: 6px 6px 0 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        font-size: 0.875rem;
      }
    }
    &_content {
      padding: 10px 20px;
      display: flex;
      flex: 1;
      flex-direction: column;
      .prod-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        cursor: pointer;
        &.disabled {
          cursor: auto;
        }

        @supports not (overflow-wrap: anywhere) {
          word-break: break-word;
        }

        @supports (overflow-wrap: anywhere) {
          overflow-wrap: anywhere;
        }
      }
      .prod-time {
        font-size: 14px;
        line-height: 18px;
        color: ${palette.grayMedium4};
        margin-bottom: 20px;
      }
      .prod-action {
        flex: 1;
        margin-bottom: 13px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .prod-action_checkbox {
          padding: 0;
        }
        @media ${bp.md} {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
