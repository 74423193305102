import React from 'react';
import SVG from 'react-inlinesvg';

interface PropTypes {
  src: string;
  id?: string;
  className?: string;
  onClick?: () => void;
  asImg?: boolean; // For old browsers that don't work well with react-inlinesvg
}

const SVGIcon: React.FC<PropTypes> = ({ src, id, className, asImg = false, ...rest }) => {
  if (asImg) return <img src={src} {...rest} className={className} alt="icon" id={id} />;

  return <SVG src={src} {...rest} className={className} id={id} />;
};

export default SVGIcon;
