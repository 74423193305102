import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionWrapper } from './styles';
import { InputText, Select, Switch } from 'views/components/primitive';
import { optionsStatusQuestion } from 'utility/constants';
import AnswerItem from './AnswerItem';
import { Answer } from 'types/Question';

interface PropTypes {
  disableEdit: boolean;
  question: string;
  answers: Answer[];
  status: string;
  hasError: boolean;
  handleChange: (data: object) => void;
  hasOther: boolean;
}

const Question: FC<PropTypes> = ({
  question,
  answers,
  status,
  handleChange,
  disableEdit,
  hasError,
  hasOther,
}) => {
  const className = useMemo(() => (parseInt(status) ? 'checkbox' : 'radio'), [status]);
  const { t } = useTranslation();

  const canAddAnswer = !disableEdit && answers.length < 10;

  function handleChangeQuestion(val: string) {
    handleChange({ content: val });
  }

  function handleChangeStatus(status: string) {
    handleChange({ is_multiple_choice: !!parseInt(status) });
  }

  function handleAddAnswer() {
    handleChange({ answers: [...answers, { content: '', feedback: '', can_delete: true }] });
  }

  function handleChangeAnswer(name: string, index: number, value: string) {
    const newAnswers = [...answers];
    newAnswers[index] = { ...newAnswers[index], [name]: value };
    handleChange({ answers: newAnswers });
  }

  function handleDeleteAnswerItem(stt: number) {
    handleChange({ answers: answers.filter((it, index) => index !== stt) });
  }

  function handleChangeHasOther() {
    handleChange({
      has_other: !hasOther,
    });
  }

  return (
    <QuestionWrapper canAddAnswer={canAddAnswer}>
      <div className="question_group">
        <InputText
          className="input_question"
          placeholder={t('admin.questions.placeholderQuestion')}
          value={question}
          onChange={handleChangeQuestion}
          isCustom
          error={hasError && !question.trim() ? t('admin.questions.messages.questionBlank') : ''}
          disabled={disableEdit}
          maxLength={80}
        />
        {!disableEdit && (
          <Select
            size="sm"
            value={status}
            options={optionsStatusQuestion}
            onChange={handleChangeStatus}
          />
        )}
      </div>
      {answers.map((answer, index) => {
        const canDeleteAnswer = !!answer.can_delete && answers.length > 1;
        return (
          <AnswerItem
            key={`${answer.id}${index}`}
            index={index}
            disableEdit={disableEdit}
            answer={answer.content}
            status={className}
            hasError={hasError}
            feedback={answer.feedback}
            onChange={handleChangeAnswer}
            handleDeleteAnswerItem={handleDeleteAnswerItem}
            canDeleteAnswer={canDeleteAnswer}
          />
        );
      })}
      <div className="bottom_items">
        {canAddAnswer && (
          <div className="add_answer">
            <span className={`status ${className}`} />
            <span onClick={handleAddAnswer} className="action_add">
              {t('admin.questions.addAnswer')}
            </span>
          </div>
        )}
        <div className="switch_container">
          <Switch checked={hasOther} handleChange={handleChangeHasOther} disabled={disableEdit} />
          <span className="switch_label">{t('admin.questions.addOther')}</span>
        </div>
      </div>
    </QuestionWrapper>
  );
};

export default Question;
