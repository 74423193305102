import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC, useCallback, useEffect, useState } from 'react';
import SearchInput from 'views/components/compound/SearchInput';
import debounce from 'utility/debounce';

type Props = {
  searchKeyword: string;
  setSearchKeyword(value: string): void;
  onSearch: (val: string) => void;
  placeholder?: string;
  className?: string;
  shouldInvoke?: boolean;
  debounceDeps?: any[];
};

const DELAY = 500;

const DebouncedSearchInput: FC<Props> = ({
  searchKeyword,
  setSearchKeyword,
  placeholder,
  onSearch,
  className,
  shouldInvoke = true,
  debounceDeps = [],
}) => {
  const [composing, setComposing] = useState(false);

  const debounced = useCallback(
    debounce(value => onSearch(value), DELAY),
    [...debounceDeps]
  );

  useEffect(() => {
    shouldInvoke && !composing && debounced(searchKeyword);
  }, [shouldInvoke, searchKeyword, composing]);

  const handleCompositionStart = () => {
    setComposing(true);
  };

  const handleCompositionEnd = () => {
    setComposing(false);
  };

  const handleChange = (value: string) => {
    setSearchKeyword(value);
  };

  const handleSearch = () => {
    onSearch(searchKeyword);
  };

  return (
    <Root className={className}>
      <SearchInput
        value={searchKeyword}
        placeholder={placeholder}
        onChange={handleChange}
        onSearch={handleSearch}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
      />
    </Root>
  );
};

export default DebouncedSearchInput;

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  border: `0.8px solid ${palette.grayMedium1}`,
  borderRadius: 6,
  padding: 10,
  background: palette.whitePrimary,
});
