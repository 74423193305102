import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { palette } from 'entity/createTheme';
import { SVGIcon } from 'views/components/primitive';
import IconUpload from 'assets/images/ic_upload_img.svg';
import { acceptThumbnail } from 'utility/constants';

const DropzoneWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23D8D8D8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='17' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
  user-select: none;
  cursor: pointer;
  p {
    font-size: 14px;
    margin-top: 10px;
    color: ${palette.darkMedium6};
    text-align: center;
  }
  svg {
    margin: 0 auto;
  }
  &:focus {
    outline: none;
  }
  }
  .error_upload {
    color: ${palette.redPrimary};
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  &.disabled {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23EAEAEAFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='17' stroke-linecap='square'/%3e%3c/svg%3e");
    p {
      color: ${palette.grayMedium1};
    }
    svg > g > g {
      fill: ${palette.grayMedium1};
    }
  }
`;

interface PropTypes {
  onChange: (file: any) => void;
  error?: string;
  className?: string;
  disabled: boolean;
}

const DropzoneImage: FC<PropTypes> = ({ error, onChange, className, disabled }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const [errorType, setErrorType] = useState('');

  const onDrop = function(data: any) {
    const newFile = data[0];
    const extension = newFile.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!acceptThumbnail.includes(`.${extension}`)) {
      setErrorType(`Only Accept Image Fomat: ${acceptThumbnail.join(', ')}`);
    } else {
      setErrorType('');
      onChange(newFile);
    }
    setFileName(newFile.name);
  };

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  function handleShowError() {
    if (errorType || error) return <span className="error_upload">{errorType || error}</span>;
  }

  return (
    <DropzoneWrapper {...getRootProps({ className: `${className} ${disabled ? 'disabled' : ''}` })}>
      <input
        {...getInputProps()}
        accept={acceptThumbnail.join(', ')}
        multiple={false}
        disabled={disabled}
      />
      <SVGIcon src={IconUpload} />
      <p>{fileName ? fileName : t('admin.common.uploadThumbnail')}</p>
      {handleShowError()}
    </DropzoneWrapper>
  );
};

export default DropzoneImage;
