import React, { FC, useEffect, useRef, useState } from 'react';
import { ShakaPlayerInner } from './ShakaPlayerInner';

interface PropsTypes {
  manifestId: string;
  manifestUrl: string;
  visible: boolean;
  onCacheChecked: (cacheAvailable: boolean) => void;
  onReady: () => void;
  onError: (error: any) => void;
  onDownloadProgress: (downloading: boolean, progress: number) => void;
  downloadTriggered: boolean;
}

const ShakaPlayer: FC<PropsTypes> = ({
  manifestId,
  manifestUrl,
  visible,
  onCacheChecked,
  onReady,
  onError,
  onDownloadProgress,
  downloadTriggered,
}) => {
  const [inner, setInner] = useState<ShakaPlayerInner | null>(null);
  const videoTag = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!inner && videoTag.current) {
      const _inner = new ShakaPlayerInner({
        videoTag: videoTag.current,
        onLoaded: () => {
          onReady();
        },
        onDownloadProgress: (downloading, progress) => {
          onDownloadProgress(downloading, progress);
        },
        onError: error => {
          onError(error);
        },
      });
      setInner(_inner);
      const f = async () => {
        const loaded = await _inner.loadCachedVideo(manifestId);
        onCacheChecked(loaded);
      };
      f();
    }
  }, []);

  useEffect(() => {
    if (inner) {
      if (inner.shakaPlayer) inner.shakaPlayer.unload();
      const f = async () => {
        const loaded = await inner.loadCachedVideo(manifestId);
        onCacheChecked(loaded);
      };
      f();
    }
  }, [manifestId]);

  useEffect(() => {
    if (inner && downloadTriggered) {
      const f = async () => {
        await inner.downloadVideo(manifestId, manifestUrl, true, false);
      };
      f();
    }
  }, [downloadTriggered]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: visible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <video
        crossOrigin="anonymous"
        controls
        autoPlay
        ref={videoTag}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
          margin: 'auto',
          display: 'block',
        }}
      />
    </div>
  );
};

export default ShakaPlayer;
