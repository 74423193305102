import { useEffect } from 'react';
import { isTouchDevice } from 'utility/helpers';

const eventName = isTouchDevice() ? 'touchstart' : 'click';

function useDocumentClick(handleClick: (event: any) => void) {
  useEffect(() => {
    document.addEventListener(eventName, handleClick);
    return () => {
      document.removeEventListener(eventName, handleClick);
    };
  });
  return null;
}

export default useDocumentClick;
